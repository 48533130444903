<ng-template #buttons>
	<!-- Save/Update -->
	<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
		@if (this.createMode) {
			@if ([userPermissions.INSPECTION_WORKFLOW_CREATE] | hasPermissions) {
				<uno-button (click)="this.update();">{{'create' | translate}}</uno-button>
			}
		}
		@if (!this.createMode) {
			@if ([userPermissions.INSPECTION_WORKFLOW_EDIT] | hasPermissions) {
				<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
			}
			@if ([userPermissions.INSPECTION_WORKFLOW_EDIT] | hasPermissions) {
				<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
			}
			@if ([userPermissions.INSPECTION_WORKFLOW_DELETE] | hasPermissions) {
				<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
			}
			@if ([userPermissions.INSPECTION_WORKFLOW_CLONE] | hasPermissions) {
				<uno-button (click)="this.clone();">{{'clone' | translate}}</uno-button>
			}
			@if ([userPermissions.INSPECTION_WORKFLOW_EXPORT] | hasPermissions) {
				<uno-button (click)="this.inspectionWorkflowExport.exportJSON(this.workflow.uuid);">{{'export' | translate}}</uno-button>
			}
		}
	</div>
</ng-template>

<div style="width:100%; height:100%;">
	<uno-tab>
		@if (this.workflow) {
			<!-- Workflow -->
			<uno-tab-section [active]="true" title="{{'flow' | translate}}">
				<uno-form [layout]="this.layoutWorkflow" [object]="this.workflow" #form></uno-form>
				<ng-container [ngTemplateOutlet]="buttons"></ng-container>
			</uno-tab-section>

			<!-- Steps -->
			<uno-tab-section title="{{'steps' | translate}}">
				@if (this.workflow.steps.length > 0) {
					@for (step of this.workflow.steps; track step) {
						<uno-form [layout]="this.layoutStep" [object]="step" [onChange]="this.updateAdjacencyStepOptions()"></uno-form>
						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							<uno-button (click)="this.removeStep(step);" color="error">{{'delete' | translate}}</uno-button>
							@if (step.index < this.workflow.steps.length - 1) { <uno-button (click)="this.moveStepUp(step);">
									<ion-icon name="caret-down-outline"></ion-icon>
								</uno-button>
							}
							@if (step.index > 0) {
								<uno-button (click)="this.moveStepDown(step);">
									<ion-icon name="caret-up-outline"></ion-icon>
								</uno-button>
							}
						</div>
					}
				}
				@if (this.workflow.steps.length === 0) {
					<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
						<ion-label>
							<p class="ion-margin-horizontal">{{'noWorkflowSteps' | translate}}</p>
						</ion-label>
					</ion-item>
				}
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.addStep();">{{'add' | translate}}</uno-button>
				</div>

				<ng-container [ngTemplateOutlet]="buttons"></ng-container>
			</uno-tab-section>

			<!-- Adjacencies -->
			<uno-tab-section title="{{'adjacencies' | translate}}">
				@if (this.workflow.adjacencies.length > 0) {
					@for (adjacency of this.workflow.adjacencies; track adjacency) {
						<uno-form [layout]="this.layoutAdjacency" [object]="adjacency"></uno-form>
						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							<uno-button (click)="this.removeStepAdjacency(adjacency);" color="error">{{'delete' | translate}}</uno-button>
						</div>
					}
				}
				@if (this.workflow.adjacencies.length === 0) {
					<ion-item class="ion-margin-horizontal ion-no-padding" lines="none">
						<ion-label>
							<p class="ion-margin-horizontal">{{'noWorkflowAdjacencies' | translate}}</p>
						</ion-label>
					</ion-item>
				}
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.addStepAdjacency();">{{'add' | translate}}</uno-button>
				</div>

				<ng-container [ngTemplateOutlet]="buttons"></ng-container>
			</uno-tab-section>
		}
	</uno-tab>
</div>
