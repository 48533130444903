import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Repair} from 'src/app/models/repairs/repairs/repair';
import {Inspection} from 'src/app/models/inspections/inspection/inspection';
import {CommonModule} from '@angular/common';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {Modal} from '../../../modal';
import {Session} from '../../../session';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {QRReaderComponent} from '../components/qr-reader/qr-reader.component';
import {AssetService} from '../../asset-portfolio/services/asset.service';
import {RepairService} from '../../repairs/repair-work/services/repair.service';
import {InspectionService} from '../../inspections/services/inspection.service';
import {AssetInfoComponent} from '../components/asset-info/asset-info.component';

/**
 * QR page is used to read QR code tags.
 *
 * When a QR code is read it automatically opens the associated item (asset, repair, etc).
 */
@Component({
	selector: 'qr-page',
	templateUrl: 'qr-page.html',
	styleUrls: ['qr-page.css'],
	standalone: true,
	imports: [
		QRReaderComponent,
		CommonModule
	]
})

export class QRPage extends ScreenComponent implements OnInit, OnDestroy {
	@ViewChild('reader', {static: true})
	public reader: QRReaderComponent = null;

	public permissions = [UserPermissions.REPAIR_QR, UserPermissions.ASSET_PORTFOLIO_QR, UserPermissions.INSPECTION_QR];

	/**
	 * Callback method used to perform a different task other than opening the item view/edit page.
	 */
	public onRead: (qr: string)=> void = null;

	/**
	 * Indicates if a QR value read is currently being process.
	 */
	public processing: boolean = false;

	/**
	 * Whether or not the qr code has been found.
	 */ 
	public found: boolean = false;

	/**
	 * Whether or not the backdrop is active.
	 */ 
	public backdrop: boolean = false;

	public ngOnInit(): void {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (data !== null && data.onRead !== undefined) {
			this.onRead = data.onRead;
		}

		this.processing = false;
		this.reader.enabled = true;
		this.reader.onRead = (qr: string) => {
			this.processQR(qr);
		};

		App.navigator.setTitle('qrReader');
	}

	/**
	 * Process a predefined action for a QR code based on its content.
	 * 
	 * @param qr - QR code read from the device camera.
	 * @returns True if the QR code was processed, false otherwise.
	 */
	public async processAction(qr: string): Promise<{found: boolean, result: any}> {
		if (Session.hasPermissions([UserPermissions.REPAIR_QR])) {
			try {
				const repair: Repair = await RepairService.getByQR(qr, true, false);

				App.navigator.navigate('/menu/repairs/works/edit', {uuid: repair.uuid});
				return {found: true, result: repair};
			} catch (e) {}

			try {
				const repair = await RepairService.get(qr, true, false);

				App.navigator.navigate('/menu/repairs/works/edit', {uuid: repair.uuid});
				return {found: true, result: repair};
			} catch (e) {}
		}

		if (Session.hasPermissions([UserPermissions.INSPECTION_QR])) {
			try {

				const inspection: Inspection = await InspectionService.getByQR(qr, true, false);

				App.navigator.navigate('/menu/workflow/edit', {uuid: inspection.uuid});
				return {found: true, result: inspection};
			} catch (e) {}
			try {
				const inspection: Inspection = await InspectionService.get(qr, true, false);

				App.navigator.navigate('/menu/workflow/edit', {uuid: inspection.uuid});

				return {found: true, result: inspection};
			} catch (e) {}
		}

		if (Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_QR])) {
			let asset = null;
			try {
				asset = await AssetService.getByQR(qr, true, false);
			} catch (e) {}
			try {
				asset = await AssetService.get(qr, true, false);
			} catch (e) {}

			if (asset) {
				return {found: true, result: asset};
			}
		}

		return {found: false, result: null};
	}

	/**
	 * Process a QR code read from the device camera, may process a predefined action for a type of QR marker or a used provided method.
	 *
	 * @param qr - QR code read from the device camera.
	 */
	public async processQR(qr: string): Promise<void> {
		if (this.processing) {
			return;
		}

		this.processing = true;

		if (this.onRead instanceof Function) {
			this.onRead(qr);
		} else {
			const result = await this.processAction(qr);
			if (!result.found) {
				Modal.alert(Locale.get('warning'), Locale.get('qrUnknown'));
			} else {
				if (result.result instanceof APAsset) {
					this.backdrop = true;
					await Modal.component(AssetInfoComponent, {asset: result.result}, false, false);
					this.backdrop = false;
				}
			}
		}

		this.processing = false;
	}

	public ngOnDestroy(): void {
		this.reader.enabled = false;
	}
}
