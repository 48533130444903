import {Component} from '@angular/core';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {App} from 'src/app/app';
import {UnoTooltipDirective} from 'src/app/components/uno/uno-tooltip/uno-tooltip.directive';
import {NgStyle} from '@angular/common';
import {UnoTabComponent} from '../../../../components/uno/uno-tab/uno-tab.component';
import {UnoTabSectionComponent} from '../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';

@Component({
	selector: 'demo-color-palette-page',
	templateUrl: 'demo-color-palette.page.html',
	styleUrls: ['demo-color-palette.page.css'],
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoTooltipDirective, NgStyle]
})
export class DemoColorPalettePage extends ScreenComponent {
	/**
	 * List of colors, organized by category.
	 */
	public colors: any = [
		{
			title: 'Primary',
			colors: [
				'--brand-primary',
				'--brand-primary-hover',
				'--brand-primary-tint',
				'--brand-primary-off',
				'--brand-primary-dark'
			]
		},
		{
			title: 'Secondary',
			colors: [
				'--brand-secondary',
				'--brand-secondary-hover',
				'--brand-secondary-tint',
				'--brand-secondary-off'
			]
		},
		{
			title: 'Tertiary',
			colors: [
				'--brand-tertiary',
				'--brand-tertiary-hover',
				'--brand-tertiary-tint',
				'--brand-tertiary-off'
			]
		},
		{
			title: 'Constrast',
			colors: [
				'--light',
				'--dark',
				'--brand-contrast'
			]
		},
		{
			title: 'Gray',
			colors: [
				'--gray-0',
				'--gray-1',
				'--gray-2',
				'--gray-3',
				'--gray-4',
				'--gray-5',
				'--gray-6',
				'--gray-7',
				'--gray-8',
				'--gray-9',
				'--gray-10',
				'--gray-11',
				'--gray-12',
				'--gray-13',
				'--gray-14'
			]
		},
		{
			title: 'Error',
			colors: [
				'--error-normal',
				'--error-hover',
				'--error-tint',
				'--error-off',
				'--error-background'
			]
		},
		{
			title: 'Warning',
			colors: [
				'--warning-normal',
				'--warning-hover',
				'--warning-tint',
				'--warning-off',
				'--warning-background'
			]
		},
		{
			title: 'Success',
			colors: [
				'--success-normal',
				'--success-hover',
				'--success-tint',
				'--success-off',
				'--success-background'
			]
		},
		{
			title: 'Information',
			colors: [
				'--information-normal',
				'--information-hover',
				'--information-tint',
				'--information-off',
				'--information-background'
			]
		},
		{
			title: 'Processing',
			colors: [
				'--processing-normal',
				'--processing-hover',
				'--processing-tint',
				'--processing-off',
				'--processing-background'
			]
		},
		{
			title: 'Special',
			colors: [
				'--special-green-1',
				'--special-green-2',
				'--special-green-3',
				'--special-green-4',
				'--special-orange-1',
				'--special-orange-2',
				'--special-yellow-1',
				'--special-yellow-2',
				'--special-violet-1',
				'--special-violet-2',
				'--special-purple-1',
				'--special-purple-2',
				'--special-red-1',
				'--special-red-2',
				'--special-blue-1',
				'--special-blue-2',
				'--special-blue-3',
				'--special-pink-1'
			]
		}
	];

	public ngOnInit(): void {
		App.navigator.setTitle('color');
	}
}
