import {CdkOverlayOrigin, CdkConnectedOverlay} from '@angular/cdk/overlay';
import {
	Component,
	ViewEncapsulation,
	Input,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	ViewChild,
	ElementRef
} from '@angular/core';
import {CalendarEvent, collapseAnimation, CalendarCommonModule, CalendarMonthModule} from 'angular-calendar';
import {addDays, addHours, isSameDay} from 'date-fns';
import {Subject} from 'rxjs';
import {Locale} from 'src/app/locale/locale';
import {Session} from 'src/app/session';
import {SortDirection} from 'src/app/utils/sort-direction';
import {CompareUtils} from 'src/app/utils/compare-utils';
import {TranslateModule} from '@ngx-translate/core';
import {CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf} from '@angular/cdk/scrolling';
import {NgClass, NgStyle, UpperCasePipe, KeyValuePipe} from '@angular/common';
import {UnoNoDataComponent} from 'src/app/components/uno/uno-no-data/uno-no-data.component';
import {ArrayUtils} from 'src/app/utils/array-utils';
import {RepairService} from 'src/app/modules/repairs/repair-work/services/repair.service';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Repair} from '../../../../models/repairs/repairs/repair';
import {UUID} from '../../../../models/uuid';
import {CalendarEventSubtypes, CalendareventSubtypeColors} from '../../../../models/asset-planning/calendar-event-actions';
import {CalendarEventStatus, CalendarEventStatusBackground, CalendarEventStatusLabel} from '../../../../models/asset-planning/calendar-event-occurrence-status';
import {CalendarEventOccurrencePriorityIcon} from '../../../../models/asset-planning/calendar-event-occurrence-priority';
import {UnoIconComponent} from '../../../../components/uno/uno-icon/uno-icon.component';

/**
 * Display mode controls how events are presented in the calendar.
 */
export enum UnoCalendarDisplayMode {
	/**
	 * Daily list of event organized by sub-type.
	 */
	DAY = 'day',

	/**
	 * Global list of all events available.
	 */
	LIST = 'list',

	/**
	 * Month calendar view.
	 */
	MONTH = 'month'
}

@Component({
	selector: 'uno-calendar',
	templateUrl: 'uno-calendar.component.html',
	styleUrls: ['uno-calendar.component.css'],
	encapsulation: ViewEncapsulation.None,
	animations: [collapseAnimation],
	standalone: true,
	imports: [
		NgClass,
		NgStyle,
		UnoIconComponent,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		CalendarCommonModule,
		CalendarMonthModule,
		CdkOverlayOrigin,
		CdkConnectedOverlay,
		UpperCasePipe,
		KeyValuePipe,
		TranslateModule,
		UnoNoDataComponent
	]
})

export class UnoCalendarComponent implements OnChanges {

	@ViewChild(CdkVirtualScrollViewport, {static: false})
	public cdkVirtualScrollViewport: CdkVirtualScrollViewport;

	@ViewChild('listContainer', {static: false})
	public listContainer: ElementRef = null;
	
	public locale = Locale;

	public priorityIcon = CalendarEventOccurrencePriorityIcon;

	public calendarEventSubtypes = CalendarEventSubtypes;

	public calendarEventStatus = CalendarEventStatus;

	public calendarEventStatusLabel = CalendarEventStatusLabel;

	public calendarEventStatusBackground = CalendarEventStatusBackground;

	public addHours = addHours;

	public compareDates = CompareUtils.compareDateNoSeconds;

	public theme: string = Session.settings.theme;

	/**
	 * The display mode that the calendar will be shown in, can be toggled between month, day or list.
	 */
	@Input()
	public displayMode: UnoCalendarDisplayMode = UnoCalendarDisplayMode.MONTH;

	/**
	 * The events to be displayed on the calendar.
	 */
	@Input()
	public events: CalendarEvent[] = [];
	
	/**
	 * The current view date.
	 */
	@Input()
	public viewDate: Date;

	/**
	 * The current filter list, used to show and hide each event type
	 */
	@Input()
	public filterList: number[];

	/**
	 * If there are more events to load
	 */
	@Input()
	public hasMore: boolean = true;

	/**
	 * An event emitter to send a message when a new event is to be created.
	 */
	@Output()
	public createEvent = new EventEmitter<any>();

	/**
	 * An event emitter to send a message when an existing event is dragged or resized.
	 */
	@Output()
	public eventChanged = new EventEmitter<any>();

	/**
	 * Emits when the view mode is changed, (month or day changed), or the type of view.
	 */
	@Output()
	public viewChanged = new EventEmitter<Date>();

	/**
	 * An event emitter to send a message when an existing event is dragged or resized.
	 */
	@Output()
	public eventClicked = new EventEmitter<any>();

	/**
	 * An event emitter to send a message when the sort direction is changed.
	 */
	@Output()
	public sortChanged = new EventEmitter<SortDirection>();

	/**
	 * An event emitter to send a message when an event action is clicked.
	 */
	@Output()
	public actionClicked = new EventEmitter<{event: any, action: any}>();

	/**
	 * An event emitter to send a message when an event action is clicked.
	 */
	@Output()
	public loadMore = new EventEmitter<any>();

	/**
	 * Maximum number of events to present in badge for a specific date.
	 *
	 * After the limit the notation "99+" is used.
	 */
	@Input()
	public badgeLimit: number = 99;

	/**
	 * Index of the list applied to list mode.
	 */
	public index: number = 30;

	/**
	 * Page size applied in the list mode
	 */
	public pageSize: number = 30;

	/**
	 * An observable that when emitted on will re-render the current view
	 */
	public refresh = new Subject<void>();

	/**
	 * Whether a certain day is open or not
	 */
	public daySelected: boolean = false;

	/**
	 * Stores how many times each action subtype happens
	 */
	public eventsByDay: number[][] = [];

	/**
	 * Stores Asset information to show on the event list
	 */
	public assetInfo: Map<string, any> = new Map<string, any>();

	/**
	 * Stores Asset information to show on the event list
	 */
	public repairAssetUuid: Map<string, any> = new Map<string, any>();

	/**
	 * If the event list filter is expanded or not
	 */
	public eventListSortIconExpanded: boolean = false;

	/**
	 * The direction in which events are sorted (asc or desc)
	 */
	public sortDirection: string = 'ascending';

	/**
	 * The counter for the event background, used in the day view
	 */
	public eventBackgroundCount: number = 0;

	/**
	 * Whether the event actions are shown on the day view.
	 */
	public eventListDayOptions: boolean[][];

	/**
	 * The colors for the event subtypes
	 */
	public CalendareventSubtypeColors: Map<number, string> = CalendareventSubtypeColors;

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		this.index = this.pageSize;

		if (changes.events && changes.events.currentValue.length !== 0 && !changes.events.firstChange) {
			this.reset();
			
			this.loadEventsByDay();
			await this.loadRepairsAssets();
		}
		this.refresh.next();

		this.cdkVirtualScrollViewport?.checkViewportSize();
	}

	/**
	 * Used in the month view, when clicking on an empty cell gets the date of that cell and the events that happen on that day.
	 * 
	 * If there are no events send a message with the date to create a new one.
	 * 
	 * If there is at least one event then it shows the event list with the event time, title and possible actions. The viewdate is changed to that day and the calendar is refreshed.
	 * 
	 * @param date - The date of the cell that is clicked
	 * @param events - The list of events that occur on that day
	 */
	public dayClicked(date: Date, events: CalendarEvent[]): void {
		if (events.length === 0 && !this.daySelected) {
			this.createEvent.emit(date);
		} else {
			this.daySelected = !(isSameDay(this.viewDate, date) && this.daySelected === true || events.length === 0);
		}

		if (date.getMonth() !== this.viewDate.getMonth() || date.getFullYear() !== this.viewDate.getFullYear() ) {
			this.viewChanged.emit(date);
		}
		
		this.viewDate = date;
		this.viewChanged.emit(date);
	}

	/**
	 * Receives a date and subtype and returns how many of those events are in that date
	 *
	 * @param date - The selected date.
	 * @param subtype - The wanted subtype.
	 * 
	 * @returns Number of events matching the subtype in that date.
	 */
	public getNumber(date: Date, subtype: number): number {
		date = addHours(date, -date.getTimezoneOffset() / 60);

		if (this.filterList.length !== 0) {
			if (!this.filterList.includes(subtype)) {
				return 0;
			}
		}

		if (date.toISOString().split('T')[0] in this.eventsByDay) {
			return this.eventsByDay[date.toISOString().split('T')[0]][subtype - 1];
		}

		return 0;
	}

	/**
	 * Checks if a given date has an odd or even weekday (starting on monday with 0).
	 *
	 * Used to control the style of the calendar.
	 *
	 * @param date - The given date.
	 * @returns String containing "even" or "odd" depending on the weekday (even being monday,wednesday,friday and sunday, odd being tuesday, thursday and saturday).
	 */
	public evenWeekday(date: Date): boolean {
		return date.getDay() % 2 === 0;
	}

	/**
	 * When an occurrence is clicked navigate to its execution.
	 * 
	 * @param event - The occurrence that was clicked
	 */
	public occurrenceClicked(event: any): void {
		if (event.event.meta.actionSubtype !== CalendarEventSubtypes.SCHEDULED_AUDIT && event.event.meta.actionSubtype !== CalendarEventSubtypes.SCHEDULED_PLANNED_STOP_MAINTENANCE) {
			this.eventClicked.emit(event);
		}
	}

	/**
	 * Scrolls the dropdown to the currently selected value.
	 * 
	 * @param element - The element that has its value currently selected
	 */
	public scroll(element: any): void {
		if (element !== null) {
			element.scrollIntoView();
		}
	}

	/**
	 * Toggles the sort icon and dropdown
	 */
	public toggleEventListSort(): void {
		this.eventListSortIconExpanded = !this.eventListSortIconExpanded;

		if (this.eventListSortIconExpanded) {
			setTimeout(() => {
				const parentElement = document.querySelector('[aria-label="event-list-sort"]');
				const dropdownElement = document.querySelector<HTMLElement>('[aria-label="event-list-sort-dropdown"]');

				dropdownElement.style['top'] = window.scrollY + parentElement.getBoundingClientRect().top - 70 + 'px';
				dropdownElement.style['left'] = window.scrollX + parentElement.getBoundingClientRect().left - 180 + 'px';
				dropdownElement.style['display'] = 'flex';

				const input = document.getElementById(this.sortDirection) as HTMLInputElement;
				input.checked = true;

			}, 20);	
		}
	}

	/**
	 * Activates the corresponding input
	 * 
	 * @param value - the input to activate
	 */
	public changeSort(value: string): void {
		if (this.sortDirection !== value) {
			const input = document.getElementById(value) as HTMLInputElement;
			input.checked = true;

			this.sortDirection = value;

			this.sortChanged.emit(value === 'ascending' ? SortDirection.ASC : SortDirection.DESC);
		}
	}

	/**
	 * Used in the day view to change between days, receives any positive or negative number and changes that many days.
	 * 
	 * @param direction - If the calendar is moving forwards or backwards
	 */
	public dayChanged(direction: number): void {
		this.viewDate = addDays(this.viewDate, direction);
		this.viewChanged.emit(this.viewDate);
		this.refresh.next();
	}

	/**
	 * Returns the number of events in a day.
	 * 
	 * @param date - the date to get the events from
	 * 
	 * @returns Number of events in that date.
	 */
	public getEventNumberByDay(date: Date): number {
		if (this.eventsByDay[date.toISOString().split('T')[0]] !== undefined) {
			return this.eventsByDay[date.toISOString().split('T')[0]].reduce((a, b) => {return a + b;}, 0);
		}
		return 0;
	}

	/**
	 * Given a certain date, returns all the events in that day
	 * 
	 * @param date - The date to return the events from
	 * 
	 * @returns List of events in that date
	 */
	public getEventsByDay(date: Date): CalendarEvent[] {
		const eventList = [];

		const viewDate = addHours(date, -date.getTimezoneOffset() / 60).toISOString().split('T')[0];

		for (const event in this.events) {
			let eventDate: any = addHours(new Date(this.events[event].start), -this.events[event].start.getTimezoneOffset() / 60);
			eventDate = eventDate.toISOString().split('T')[0];

			if (eventDate === viewDate) {
				eventList.push(this.events[event]);
			}
		}
		return eventList;
	}

	/**
	 * Given a certain date and subtype, returns all the events that match those.
	 * 
	 * @param date - The date to return the events from.
	 * @param subtype - The subtype to return the events from.
	 * @returns List of events of that date and subtype.
	 */
	public getDayEventsBySubtype(date: Date, subtype: number): CalendarEvent[] {
		 let dayEventList = this.getEventsByDay(date);

		 dayEventList = dayEventList.filter((event: CalendarEvent) => {
			return event.meta.actionSubtype === subtype;
		});

		return dayEventList;
	}

	/**
	 * Returns the background color to be used for the event background in the day view
	 * 
	 * @param reset - If the count is to be reset
	 * 
	 * @returns string containing the background color
	 */
	public getEventBackground(reset = false): string {
		if (reset) {
			this.eventBackgroundCount = 0;
			return ;
		}

		this.eventBackgroundCount += 1;
		if (this.eventBackgroundCount % 2 === 0) {
			return 'var(--gray-13)';
		}

		return 'var(--gray-14)';
	}

	/**
	 * Closes the dropdown and emits the selected action to the asset-planning component
	 * 
	 * @param row - The row of the active dropdown
	 * @param column - The column of the active dropdown
	 * @param event - The event that had the actions
	 * @param action -	What action was chosen
	 */
	public eventActionClick(row: number, column: number, event: any, action: string): void {
		this.eventListDayOptions[row][column] = false;
		this.actionClicked.emit({event: event, action: action});
	}

	/**
	* Toggles the dropdown given which row and column it belongs to
	* 
	* @param row - The row of the active dropdown
	* @param column - The column of the active dropdown
	*/
	public toggleEventDayOptions(row: number, column: number): void {
		this.eventListDayOptions[row][column] = !this.eventListDayOptions[row][column];
	}

	/**
	 * When the event list is scrolled emit the current index.
	 * @param event - The current index of the topmost shown element.
	 */
	public eventListScrolled(index: number): void {
		if (this.hasMore && (this.events.length - 20 < index || this.events.length === 0)) {
			this.loadMore.emit(index);
		}
	}

	/**
	 * Method to check if an event has an action associated.
	 * 
	 * @param event - The event to check if it has a pointer
	 * @returns True if the occurrence is of subType atex inspection, inspection, dl50 inspection, repair or repair inspection and has a valid uuid.
	 */
	public hasActionAssociated(event: CalendarEvent): boolean {
		if (event.meta.actionSubtype === CalendarEventSubtypes.ASSET_ATEX_INSPECTION ||
			event.meta.actionSubtype === CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION ||
			event.meta.actionSubtype === CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR ||
			event.meta.actionSubtype === CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION ||
			event.meta.actionSubtype === CalendarEventSubtypes.ASSET_DL50_INSPECTION) {
			return true;
		}
		return false;
	}

	public loadEventsByDay(): void {
		// If the event list is sorted do not close the open day.
		if (this.getEventsByDay(this.viewDate).length === 0 || !this.daySelected) {
			this.daySelected = false;
		}

		for (const event in this.events) {
			const date = addHours(new Date(this.events[event].start), -this.events[event].start.getTimezoneOffset() / 60);
			const eventDate = date.toISOString().split('T')[0];
			if (!(eventDate in this.eventsByDay)) {
				this.eventsByDay[eventDate] = [0, 0, 0, 0, 0, 0, 0];
			}

			this.eventsByDay[eventDate][this.events[event].meta.actionSubtype - 1] += 1;
		}

		this.eventListDayOptions = Array(7).fill(false).map(() => {
			return Array(this.getEventsByDay(this.viewDate).length).fill(false);
		});
	}

	/**
	 * Adds repairs and assets associated to events to the maps
	 */
	public async loadRepairsAssets(): Promise<void> {
		// Get all asset and repair uuids
		let assetUuids: UUID[] = this.events.map((event) => {return event.meta.assetUuid;});
		let repairUuids: UUID[] = this.events.map((event) => {return event.meta.repairUuid;});
		// Remove null and duplicate uuids
		repairUuids = ArrayUtils.mergeArrays([repairUuids], true);

		for (const uuid of repairUuids) {
			const repair: Repair = await RepairService.get(uuid);
			assetUuids.push(repair.asset);
			this.repairAssetUuid.set(uuid, {asset: repair.asset});
		};

		// Remove nulls and duplicate uuids
		assetUuids = ArrayUtils.mergeArrays([assetUuids], true);

		const assetsRequest = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assetUuids}, Session.session);

		for (const asset of assetsRequest.response.assets) {
			this.assetInfo.set(asset.uuid, {name: asset.name, tag: asset.tag});
		};
	}

	/**
	 * Reset the calendar
	 */
	public reset(): void {

		this.daySelected = false;

		this.assetInfo = new Map<string, any>();

		this.repairAssetUuid = new Map<string, any>();

		this.eventListSortIconExpanded = false;

		this.eventBackgroundCount = 0;

		this.eventsByDay = [];
	}
}
