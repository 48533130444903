import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType, UnoTableComponent} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {ServiceSync} from '../../../../../http/service-sync';
import {UnoListLazyLoadHandler} from '../../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {InspectionWorkflowService} from '../../../services/inspection-workflow.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'inspection-workflow-list-page',
	templateUrl: './inspection-workflow-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		IonicModule,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoTableComponent,
		UnoFilterBarComponent,
		PermissionsPipe
	]
})
export class InspectionWorkflowListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoTableComponent) 
	public table: UnoTableComponent;

	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = InspectionWorkflowListPage;

	public permissions = [UserPermissions.INSPECTION_WORKFLOW];

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 100;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[inspection_workflow].[name]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'medium', sortBy: '[inspection_workflow].[description]'}
	];

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params = {
			from: count,
			count: pageSize,
			search: InspectionWorkflowListPage.filters.search,
			sortDirection: InspectionWorkflowListPage.filters.sortDirection,
			sortField: InspectionWorkflowListPage.filters.sortField
		};

		const list = await InspectionWorkflowService.list(params);

		return {
			elements: list.inspectionWorkflows,
			hasMore: list.hasMore
		};
	};

	/**
	 * Possible database filter to be used for ordering the inspection workflow list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[inspection_workflow].[name]',
			options: [
				{label: 'name', value: '[inspection_workflow].[name]'},
				{label: 'description', value: '[inspection_workflow].[description]'},
				{label: 'updatedAt', value: '[inspection_workflow].[updated_at]'},
				{label: 'createdAt', value: '[inspection_workflow].[created_at]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[inspection_workflow].[name]', '[inspection_workflow].[description]'],
			multiple: true,
			options: [
				{label: 'name', value: '[inspection_workflow].[name]'},
				{label: 'description', value: '[inspection_workflow].[description]'},
				{label: 'uuid', value: '[inspection_workflow].[id]'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: []
	}, InspectionWorkflowListPage.filterOptions);

	public static defaultFilters = structuredClone(InspectionWorkflowListPage.filters);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('flows');

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const data = {
				from: count,
				count: pageSize,
				search: InspectionWorkflowListPage.filters.search,
				sortDirection: InspectionWorkflowListPage.filters.sortDirection,
				sortField: InspectionWorkflowListPage.filters.sortField
			};

			const list = await InspectionWorkflowService.list(data);

			return {
				elements: list.inspectionWorkflows,
				hasMore: list.hasMore
			};
		};
		this.handler.reset();
		this.tableTotalItemsCount = await InspectionWorkflowService.count(InspectionWorkflowListPage.filters);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		if (event.target === undefined) {
			InspectionWorkflowListPage.filters.search = event;
		}

		this.serviceSync.reset();
		this.handler.reset();
		if (this.table) {
			await this.table.reset();
		}
		this.tableTotalItemsCount = await InspectionWorkflowService.count(InspectionWorkflowListPage.filters);
	}

	/**
	 * Update the filters and search term used.
	 *
	 * @param filters - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		InspectionWorkflowListPage.filters = filters;

		this.table.handler.sortDirection = InspectionWorkflowListPage.filters.sortDirection;
		this.table.handler.sortField = InspectionWorkflowListPage.filters.sortField;

		this.serviceSync.reset();
		this.handler.reset();
		if (this.table) {
			await this.table.reset();
		}
		this.tableTotalItemsCount = await InspectionWorkflowService.count(InspectionWorkflowListPage.filters);
	}

	/**
	 * Navigate to the inspection form edit page.
	 *
	 * @param uuid - The UUID of the gap to open in new page.
	 */
	public async navigate(uuid): Promise<void> {
		await App.navigator.navigate('/menu/workflow/flow/edit', {uuid: uuid});
	}

	/**
	 * When the table emits a value to change which column to sort by.
	 * 
	 * @param attribute - The attribute to sort by.
	 */
	public async sortChanged(attribute: string): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (attribute === InspectionWorkflowListPage.filters.sortField) {
			InspectionWorkflowListPage.filters.sortDirection = this.table.handler.sortDirection;
		} else {
			InspectionWorkflowListPage.filters.sortField = attribute;
			InspectionWorkflowListPage.filters.sortDirection = SortDirection.ASC;
		}

		this.handler.reset();
		if (this.table) {
			await this.table.reset();
		}
	}
}
