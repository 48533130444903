import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {InputOptionsMultipleLazyPageRequest, InputOptionsMultipleBatchRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {APAssetType} from 'src/app/models/asset-portfolio/asset-type';
import {AssetSubTypeListNameResponse, AssetSubtypeListParams, AssetSubTypeService} from 'src/app/modules/asset-portfolio/services/asset-subtype.service';
import {AssetTypeListParams, AssetTypeListNameResponse, AssetTypeService} from 'src/app/modules/asset-portfolio/services/asset-type.service';
import {SortDirection} from 'src/app/utils/sort-direction';

export const AssetTypeSelectorField: UnoFormField = {
	required: false,
	label: 'assetType',
	attribute: 'typeUuid',
	type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
	multiple: false,
	identifierAttribute: 'uuid',
	placeholder: 'all',
	showClear: true,
	fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> {
		const data: AssetTypeListParams = {
			from: request.from,
			count: request.count,
			search: request.search,
			searchFields: ['[ap_asset_type].[id]', '[ap_asset_type].[name]'],
			sortField: '[ap_asset_type].[name]',
			sortDirection: SortDirection.ASC
		};

		let req: AssetTypeListNameResponse;
		try {
			req = await AssetTypeService.listName(data, true);
			return {options: req.types, hasMore: req.hasMore, id: req.id};
		} catch {
			return {options: [], hasMore: false, id: req.id};
		}
	},
	fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
		if (request.options.length > 0) {
			try {
				const types: APAssetType[] = await AssetTypeService.getBatch(request.options, true);
				return {options: types};
			} catch {
				return {options: []};
			}
		} else {
			return {options: []};
		}
	},
	getOptionText: (option: any): string => {
		return option.name;
	}
};

export const AssetSubTypeSelectorField: UnoFormField = {
	required: false,
	label: 'assetSubType',
	attribute: 'subTypeUuid',
	type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
	multiple: false,
	identifierAttribute: 'uuid',
	placeholder: 'all',
	showClear: true,
	fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> {
		const data: AssetSubtypeListParams = {
			from: request.from,
			count: request.count,
			search: request.search,
			searchFields: ['[ap_asset_sub_type].[id]', '[ap_asset_sub_type].[name]'],
			sortField: '[ap_asset_sub_type].[name]',
			sortDirection: SortDirection.ASC
		};

		let req: AssetSubTypeListNameResponse;
		try {
			req = await AssetSubTypeService.listName(data, true);
			return {options: req.subTypes, hasMore: req.hasMore, id: req.id};
		} catch {
			return {options: [], hasMore: false, id: req.id};
		}
	},
	fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
		if (request.options.length > 0) {
			try {
				const subTypes: APAssetType[] = await AssetTypeService.getBatch(request.options, true);
				return {options: subTypes};
			} catch {
				return {options: []};
			}
		} else {
			return {options: []};
		}
	},
	getOptionText: (option: any): string => {
		return option.name;
	}
};
