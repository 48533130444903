@if (this.value) {
	<div style="display: flex; gap: 8px; align-items: center; flex-wrap: wrap;">
		<div class="atex-tag-container" [ngClass]="{'atex-tag-container-mobile': app.device.isMobile()}">
			<div style="display:flex; gap: 5px; margin-right: 5px; align-items: center; justify-content: space-between; width: 100%;">
				<div style="display:flex; gap: 5px;">
					<!-- CE -->
					<div style="display:flex; flex-direction: row; gap: 3px; align-items: center;">
						<uno-icon src="./assets/icons/atex/ce-icon.svg" [height]="32" [width]="32"></uno-icon>
						<label class="atex-tag-checkmark-container ">
							<input type="checkbox" (ngModelChange)="this.setAttribute('ce', $event);" [disabled]="this.disabled" [ngModel]="this.value.ce">
							<span class="checkmark"></span>
						</label>
					</div>
					<!-- Cert number -->
					<input class="atex-tag-input" (ngModelChange)="this.setAttribute('certificationNumber', $event);" [disabled]="this.disabled" [ngModel]="this.value.certificationNumber" [ngModelOptions]="{standalone: true}" style="margin-left: 3px; min-width: 48px; height: 36px; max-width: 54px;" type="text" />
				</div>
				@if(app.device.isMobile()) {
					<div>
						@if (!this.disabled) {
							<uno-icon color="primary" src="/assets/icons/uno/bin.svg" [height]="24" [width]="24" (click)="this.removeItem()"></uno-icon>
						}
					</div>
				}
			</div>
			<div style="display:flex; gap: 5px; margin-right: 5px;">
				<!-- EX -->
				<div style="display:flex; flex-direction: row; gap: 3px; align-items: center;">
					<ion-img src="./assets/icons/atex/ex.svg" style="width: 30px;"></ion-img>
					<label class="atex-tag-checkmark-container ">
						<input type="checkbox" (ngModelChange)="this.setAttribute('ex', $event);" [disabled]="this.disabled" [ngModel]="this.value.ex">
						<span class="checkmark"></span>
					</label>
				</div>
				<!-- Group -->
				<div class="atex-tag-options-list">
					<uno-options-list [width]="64" [fullArrow]="true" (valueChange)="this.setAttribute('group', $event);" [disabled]="this.disabled" [value]="this.value.group" [options]="this.atexGroupOptions"></uno-options-list>
				</div>
				<!-- Category -->
				<div class="atex-tag-options-list">
					<uno-options-list [width]="64" [fullArrow]="true" (valueChange)="this.setAttribute('category', $event);" [disabled]="this.disabled" [value]="this.value.category" [options]="this.atexCategoryOptions"></uno-options-list>
				</div>
				<!-- Ambient -->
				<div class="atex-tag-options-list">
					<uno-options-list [width]="70" [fullArrow]="true" (valueChange)="this.setAttribute('ambient', $event);" [disabled]="this.disabled" [value]="this.value.ambient" [options]="this.atexAmbientOptions"></uno-options-list>
				</div>
			</div>
			<div style="display:flex; gap: 5px; margin-right: 5px; align-items: center;">
				<!-- Eex -->
				<div style="display:flex; flex-direction: row; gap: 3px; align-items: center;">
					<ion-text>Ex</ion-text>
					<label class="atex-tag-checkmark-container ">
						<input type="checkbox" (ngModelChange)="this.setAttribute('eex', $event);" [disabled]="this.disabled" [ngModel]="this.value.eex">
						<span class="checkmark"></span>
					</label>
				</div>
				<!-- Protection -->
				<div class="atex-tag-select">
					@if (this.value.ambient === atexAmbient.D) {
						<ion-select fill="outline" (ngModelChange)="this.setAttribute('protections', $event);" [disabled]="this.disabled" [interfaceOptions]="{header: ('equipmentProtection' | translate)}" [ngModel]="this.value.protections" [ngModelOptions]="{standalone: true}" multiple="true" [interfaceOptions]="{cssClass: 'atex-tag-ion-select'}">
							@for (i of atexProtectionsKeysDust; track i) {
								<ion-select-option [value]="atexProtections[i]">{{atexProtectionsLabels.get(atexProtections[i])}}</ion-select-option>
							}
						</ion-select>
					}
					@else if (this.value.ambient !== atexAmbient.D) {
						<ion-select fill="outline" (ngModelChange)="this.setAttribute('protections', $event);" [disabled]="this.disabled" [interfaceOptions]="{header: ('equipmentProtection' | translate)}" [ngModel]="this.value.protections" [ngModelOptions]="{standalone: true}" multiple="true">
							@for (i of atexProtectionsKeysNotDust; track i) {
								<ion-select-option [value]="atexProtections[i]">{{atexProtectionsLabels.get(atexProtections[i])}}</ion-select-option>
							}
						</ion-select>
					}
				</div>
				<!-- Explosion -->
				<div class="atex-tag-options-list">
					<uno-options-list [width]="60" [fullArrow]="true" (valueChange)="this.setAttribute('explosion', $event);" [disabled]="this.disabled" [value]="this.value.explosion" [options]="this.atexExplosionOptions"></uno-options-list>
				</div>
			</div>
		</div>
		<div class="atex-tag-container" [ngClass]="{'atex-tag-container-mobile': app.device.isMobile()}">
			<div style="display:flex; gap: 5px; margin-right: 5px;">
				<!-- Temperature -->
				<div style="display:flex; flex-direction: row; align-items: center;">
					@if (this.value.ambient === atexAmbient.G) {
						<div class="atex-tag-options-list">
							<uno-options-list [width]="72" [fullArrow]="true" (valueChange)="this.setAttribute('temperature', $event);" [disabled]="this.disabled" [value]="this.value.temperature" [options]="this.atexTemperatureOptions"></uno-options-list>
						</div>
					}
					@else if (this.value.ambient !== atexAmbient.G) {
						<span class="uno-p2">Tmax</span>
						<input class="atex-tag-input" (ngModelChange)="this.setAttribute('temperature', $event);" [disabled]="this.disabled" [ngModel]="this.value.temperature" [ngModelOptions]="{standalone: true}" style="margin-left: 3px; min-width: 48px; height: 36px; max-width: 54px;" type="number" />
					}
				</div>
				<!-- EPL -->
				<div class="atex-tag-options-list">
					<uno-options-list [width]="78" [fullArrow]="true" (valueChange)="this.setAttribute('epl', $event);" [disabled]="this.disabled" [value]="this.value.epl" [options]="this.atexEplOptions"></uno-options-list>
				</div>
				<!-- IPX Rating Dust Resistance -->
				<div class="atex-tag-options-list">
					<uno-options-list [width]="80" [fullArrow]="true" (valueChange)="this.setAttribute('ipxDust', $event);" [disabled]="this.disabled" [value]="this.value.ipxDust" [options]="this.ipxDustOptions"></uno-options-list>
				</div>
			</div>

			<div style="display:flex; gap: 5px; margin-right: 5px; align-items: center;">
				<!-- IP Rating Water Resistance -->
				<div class="atex-tag-options-list">
					<uno-options-list [width]="63" [fullArrow]="true" (valueChange)="this.setAttribute('ipxWater', $event);" [disabled]="this.disabled" [value]="this.value.ipxWater" [options]="this.ipxWaterOptions"></uno-options-list>
				</div>

				<!-- Ambient Temperature -->
				<input class="atex-tag-input" (ngModelChange)="this.setAttribute('temperatureMin', $event);" [disabled]="this.disabled" [value]="this.value.temperatureMin" style="margin-left: 3px; width: 40px; height: 36px; text-align: center;" type="text" />
				<span class="uno-p2">≤ Tamb ≤</span>
				<input class="atex-tag-input" (ngModelChange)="this.setAttribute('temperatureMax', $event);" [disabled]="this.disabled" [value]="this.value.temperatureMax" style="margin-left: 3px; width: 40px; height: 36px; text-align: center;" type="text" />
			</div>
		</div>
		<!-- Delete Button -->
		@if(!app.device.isMobile()) {
			<div>
				@if (!this.disabled) {
					<uno-icon color="primary" src="/assets/icons/uno/bin.svg" [height]="24" [width]="24" (click)="this.removeItem()"></uno-icon>
				}
			</div>
		}
	</div>
}
