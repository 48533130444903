import {addHours} from 'date-fns';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {UUID} from '../../../models/uuid';
import {Locale} from '../../../locale/locale';
import {Modal} from '../../../modal';
import {ProgressBar} from '../../../progress-bar';
import {Session} from '../../../session';
import {XlsxUtils} from '../../../utils/xlsx-utils';
import {DateFrequency} from '../../../models/date-frequency';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {APAssetType} from '../../../models/asset-portfolio/asset-type';
import {AssetService} from '../../asset-portfolio/services/asset.service';
import {CalendarEvent} from '../../../models/asset-planning/calendar-event';
import {CalendarEventSubtypesLabel, CalendarEventTypesLabel} from '../../../models/asset-planning/calendar-event-actions';
import {CalendarEventTriggerDirectionLabel} from '../../../models/asset-planning/calendar-event-trigger-direction';
import {CalendarEventAlarmTypeLabel} from '../../../models/asset-planning/calendar-event-alarm-type';
import {CalendarEventOccurrence} from '../../../models/asset-planning/calendar-event-occurrence';

export class CalendarEventExport {

	/**
	 * Export Calendar list with base information as XLSX file.
	 * 
	 * @param subtypes - List of specific subtypes to export
	 */
	public static async exportCalendarXLSX(subtypes: (typeof CalendarEventSubtypesLabel)[keyof typeof CalendarEventSubtypesLabel][] = [], startDate: Date, endDate: Date, search: string): Promise<void> {
		// Map containing the already fetched assets
		const assetsCache: Map<UUID, APAsset> = new Map<UUID, APAsset>();
		// Map containing the already fetched asset types
		const typesCache: Map<UUID, APAssetType> = new Map<UUID, APAssetType>();
		const progress = new ProgressBar();
		progress.show();
		try {

			// Create header
			const header: string[] = ['uuid', 'name', 'description', 'actionType', 'actionSubtype', 'date', 'duration', 'triggerEventUuid', 'triggerEventOffset', 'triggerEventDirection', 'periodic', 'periodicity', 'periodicityRepetition', 'alarmType', 'alarmOffset', 'assetUuid', 'repairUuid', 'workflowProjectUuid', 'userUuids', 'teamUuids', 'assetName', 'assetTag', 'assetType'];

			// Translate the header
			header.forEach(function(title, index) {
				header[index] = Locale.get(title);
			});

			// Add the translated headers to the matrix that will be turned into an xlsx file
			const fileData: any[][] = [header];

			// How many items to get
			let from: number = 0;
			const count: number = 300;

			// Filter the events to get
			const data: any = {
				from: from, 
				count: count,
				actionSubtype: subtypes,
				search: search,
				searchFields: ['[calendar_event].[name]', '[calendar_event].[description]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				startDate: startDate,
				endDate: endDate
			};

			// Count the occurrences that match the filter
			const occurrenceCount: number = (await Service.fetch(ServiceList.assetPlanning.calendarEvent.countOccurrences, null, null, {
				subtypes: subtypes,
				search: search,
				searchFields: ['[calendar_event].[name]', '[calendar_event].[description]'],
				startDate: startDate,
				endDate: endDate
			}, Session.session, true)).response.count;

			// Get all assets
			while (true) {

				// Get all the events that match the filter
				const request = await Service.fetch(ServiceList.assetPlanning.calendarEvent.list, null, null, data, Session.session, true);

				// List with all the events
				const calendarEvents: CalendarEvent[] = request.response.calendarEvents.map((ev) => {return CalendarEvent.parse(ev);});

				// List with all the occurrences that happen between the start and end date
				const calendarOccurrences: CalendarEventOccurrence[] = request.response.occurrences.map((occ) => {return CalendarEventOccurrence.parse(occ);});

				for (let i = 0; i < calendarOccurrences.length; i++) {

					progress.update(Locale.get('loadingData'), (from + i) / occurrenceCount);
					// The parent event
					const linkedEvent: CalendarEvent = CalendarEvent.parse(calendarEvents.find((evt: CalendarEvent) => { return evt.uuid === calendarOccurrences[i].calendarEventUuid;}));

					// The row
					const fileRow: {[key: string]: string} = {};

					const fileRowTemplate: string[] = ['uuid', 'name', 'description', 'actionType', 'actionSubtype', 'date', 'duration', 'triggerEventUuid', 'triggerEventOffset', 'triggerEventDirection', 'periodic', 'periodicity', 'periodicityRepetition', 'alarmType', 'alarmOffset', 'assetUuid', 'repairUuid', 'inspectionProjectUuid', 'userUuids', 'teamUuids', 'assetName', 'assetTag', 'assetType'];

					for (const key of fileRowTemplate) {
						fileRow[key] = linkedEvent[key];
					}

					// Set the row's data
					fileRow.uuid = calendarOccurrences[i].uuid;
					calendarOccurrences[i].date = new Date(calendarOccurrences[i].date);

					fileRow.date = addHours(calendarOccurrences[i].date, -calendarOccurrences[i].date.getTimezoneOffset() / 60).toISOString();

					// Set the translated data
					fileRow.actionType = Locale.get(CalendarEventTypesLabel.get(linkedEvent.actionType));
					fileRow.actionSubtype = Locale.get(CalendarEventSubtypesLabel.get(linkedEvent.actionSubtype));
					fileRow.triggerEventDirection = Locale.get(CalendarEventTriggerDirectionLabel.get(linkedEvent.triggerEventDirection));

					// Turn alarmType into an array of translated labels
					fileRow.alarmType = '[' + linkedEvent.alarmType.map((alarmType: number) => { return Locale.get(CalendarEventAlarmTypeLabel.get(alarmType));}).toString() + ']';
					
					// Turns a dateFrequency atribute into a string 
					const formatDateFrequency = (dateFrequency: DateFrequency): string => {return '[' + dateFrequency.hours + ' ' + Locale.get('hours') + ', ' + dateFrequency.days + ' ' + Locale.get('days') + ', ' + dateFrequency.months + ' ' + Locale.get('months') + ', ' + dateFrequency.years + ' ' + Locale.get('years') + ']';};

					// Set the duration if its not null
					if (linkedEvent.duration) {
						fileRow.duration = formatDateFrequency(linkedEvent.duration);
					}

					// Set the event offset if its not null
					if (linkedEvent.triggerEventOffset) {
						fileRow.triggerEventOffset = formatDateFrequency(linkedEvent.triggerEventOffset);

					}
					// Set the alarm offset if its not null
					if (linkedEvent.alarmOffset) {
						fileRow.alarmOffset = formatDateFrequency(linkedEvent.alarmOffset);

					}
					// Set the periodicity if its not null
					if (linkedEvent.periodicity) {
						fileRow.periodicity = formatDateFrequency(linkedEvent.periodicity);
					}
					// Set the asset data (uuid, tag, name and type) 
					if (linkedEvent.assetUuid) {

						let asset: APAsset = assetsCache.get(linkedEvent.assetUuid);

						if (!asset) {
							asset = await AssetService.get(linkedEvent.assetUuid, true, false);
							assetsCache.set(asset.uuid, asset);
						}
						
						fileRow.assetTag = asset.tag;
						fileRow.assetName = asset.name;

						if (asset.typeUuid) {
							let type: APAssetType = typesCache.get(asset.typeUuid);

							if (!type) {
								type = (await Service.fetch(ServiceList.assetPortfolio.assetType.get, null, null, {uuid: asset.typeUuid}, Session.session, true)).response.type;
								typesCache.set(asset.typeUuid, type);
							}
							fileRow.assetType = type.name;
						}
					}


					// Push the row to the matrix
					fileData.push([fileRow.uuid, fileRow.name, fileRow.description, fileRow.actionType, fileRow.actionSubtype, fileRow.date, fileRow.duration, fileRow.triggerEventUuid, fileRow.triggerEventOffset, fileRow.triggerEventDirection, fileRow.periodic, fileRow.periodicity, fileRow.periodicityRepetition, fileRow.alarmType, fileRow.alarmOffset, fileRow.assetUuid, fileRow.repairUuid, fileRow.inspectionProjectUuid, fileRow.userUuids, fileRow.teamUuids, fileRow.assetName, fileRow.assetTag, fileRow.assetType]);
				}
	
				from += calendarEvents.length;
				if (!request.response.hasMore) {
					break;
				}
			}
			// Write the XLSX file
			XlsxUtils.writeFile(fileData, 'CalendarEvents.xlsx');
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}
		progress.destroy();
	}
}
