import {Component, OnInit, ViewChild} from '@angular/core';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {UnoSearchbarComponent} from 'src/app/components/uno/uno-searchbar/uno-searchbar.component';
import {UnoContentComponent} from 'src/app/components/uno/uno-content/uno-content.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {UUID} from 'src/app/models/uuid';
import {Session} from 'src/app/session';
import {UnoFormModalButton} from 'src/app/components/uno-forms/uno-form-modal/uno-form-modal.component';
import {DL50QuestionsListParams, DL50QuestionsListResponse, DL50QuestionsService} from 'src/app/modules/dl50/services/dl50-questions.service';
import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {DL50Question} from '../../../../../../models/dl50/masterdata/dl50-question';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {DL50QuestionLayout} from '../dl50-question-layout';
import {ArrayUtils} from '../../../../../../utils/array-utils';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';

/**
 * Page used to list dl50 questions.
 */
@Component({
	selector: 'dl50-questions-list-page',
	templateUrl: 'dl50-questions-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		UnoContentComponent,
		UnoResponsiveTableListComponent,
		TranslateModule,
		PermissionsPipe
	]
})
export class DL50QuestionsListPage extends ScreenComponent implements OnInit {
	/**
	 * The permissions to access this screen.
	 */
	public permissions = [UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_CREATE, UserPermissions.DL50_MASTER_DATA_QUESTIONS_EDIT, UserPermissions.DL50_MASTER_DATA_QUESTIONS_DELETE];

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;
	
	public session = Session;

	public app = App;

	public userPermissions = UserPermissions;
	
	public get selfStatic(): any { return DL50QuestionsListPage; }
	
	/**
	 * The total number of items stored on API.
	 */
	public totalItems: number = 0;

	/**
 	 * The number of items to present on table per page.
 	 */
	public tablePageSize: number = 1e4;

 	/**
	 * List of questions.
	 */
	public questions: DL50Question[] = [];

	/**
	 * The layout to use on the table.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'label', visible: true, size: 'small'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small'},
		{header: 'article', type: UnoTableColumnType.TEXT, attribute: 'article', visible: true, size: 'small'},
		{header: 'gaps', type: UnoTableColumnType.NUMBER, attribute: 'gaps', visible: true, size: 'small'},
		{header: 'actions', type: UnoTableColumnType.ICONS, attribute: 'actions', visible: true, size: 'small', iconNumber: 3}
	];
	
	public static filters: any = {
		/**
		 * Text used to filter questions by.
		 * 
		 * The search is perform in any of the textual attributes of the questions object, including gaps list.
		 */
		search: '',
		
		/**
		 * The fields to search on the DL50 questions table
		 */
		searchFields: [
			'[dl50_question].[label]',
			'[dl50_question].[description]',
			'[dl50_question].[article]',
			'[dl50_question].[gaps]'
		]
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.totalItems = await DL50QuestionsService.count({
			search: DL50QuestionsListPage.filters.search,
			searchFields: DL50QuestionsListPage.filters.searchFields
		});

		App.navigator.setTitle('questions');
	}

	/**
	 * Reloads table data.
	 */
	public async resetTable(): Promise<void> {
		this.totalItems = await DL50QuestionsService.count({
			search: DL50QuestionsListPage.filters.search,
			searchFields: DL50QuestionsListPage.filters.searchFields
		});
		
		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Creates/Updates a question and reloads table data.
	 * 
	 * A UUID of the question may be received to edit a specific question. If no UUID provided, a new question will be created.
	 * 
	 * @param uuid - The uuid of the question to edit (optional), if none is provided a new question is created.
	 */
	public async edit(uuid?: UUID): Promise<void> {
		let question: DL50Question;

		if (uuid) {
			const q: DL50Question = this.questions.find((item: DL50Question) => {
				return item.uuid === uuid;
			});

			if (!q) {
				throw new Error('Question was not found.');
			}

			question = structuredClone(q);
		} else {
			question = new DL50Question();
		}

		const canEdit: boolean = uuid && Session.hasPermissions([UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_EDIT]) || !uuid && Session.hasPermissions([UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_CREATE]);

		const buttons: UnoFormModalButton[] = [{success: false, label: uuid ? 'close' : 'cancel', color: 'primary'}];

		if (canEdit) {
			buttons.push({
				success: true,
				label: uuid ? 'save' : 'create',
				color: 'primary',
				callback: async(object) => {
					// Check required fields
					if (!UnoFormComponent.requiredFilled(DL50QuestionLayout, object)) {
						Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
						throw new Error(Locale.get('requiredFieldsError'));
					}

					// Check for duplicated labels
					const gapLabels = new Set<string>();
					for (const gap of question.possibleGaps) {
						if (gapLabels.has(gap.label)) {
							Modal.alert(Locale.get('error'), Locale.get('duplicatedGapsLabels'));
							throw new Error('Duplicated gaps labels');
						}
						
						gapLabels.add(gap.label);
					}

					if (uuid) {
						await DL50QuestionsService.update(question);
					} else {
						await DL50QuestionsService.create(question);
					}

					Modal.toast(Locale.get('success'), 3000, 'success');

					await this.resetTable();
				}
			});
		}

		await Modal.form(Locale.get('question'), question, DL50QuestionLayout, buttons, canEdit);

		if (!UnoFormComponent.requiredFilled(DL50QuestionLayout, question)) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value.
	 */
	public async onSearch(search: string): Promise<void> {
		if (DL50QuestionsListPage.filters.search !== search) {
			DL50QuestionsListPage.filters.search = search;
		
			await this.resetTable();
		}
	}

	/**
	 * Fetches more items from API to display on table in a paginated way.
	 * 
	 * @param count - The first item 'index' to get from the API.
	 * @param pageSize - How many items to fetch from the first one.
	 * @returns A list of items to display on table and a hasMore boolean telling if there are any more items to fetch from API.
	 */
	public loadMore = async(count: number, pageSize: number): Promise<any> => {
		const params: DL50QuestionsListParams = {
			from: count,
			count: pageSize,
			search: DL50QuestionsListPage.filters.search,
			searchFields: DL50QuestionsListPage.filters.searchFields
		};

		const request: DL50QuestionsListResponse = await DL50QuestionsService.list(params);
		const rows: any[] = [];

		this.questions = request.questions;

		for (const question of this.questions) {
			const row: any = structuredClone(question);

			// Present the count of gaps on list instead of the gaps data
			row.gaps = question.possibleGaps?.length || 0;

			row.actions = [];
			if (Session.hasPermissions([UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_EDIT])) {
				row.actions = row.actions.concat([
					{
						// Do not show down arrow if it is the last element on list
						src: question.index !== this.totalItems - 1 ? './assets/icons/uno/down-specialarrow.svg' : '',
						click: async(): Promise<void> => {
							if (question.index !== this.totalItems - 1) {
								await this.moveItem(question, true);
							}
						}
					},
					{
						// Do not show up arrow if it is the first element on list
						src: question.index > 0 ? './assets/icons/uno/up-specialarrow.svg' : '',
						click: async(): Promise<void> => {
							if (question.index > 0) {
								await this.moveItem(question, false);
							}
						}
					}
				]);
			}

			if (Session.hasPermissions([UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_DELETE])) {
				row.actions = row.actions.concat([{
					src: './assets/icons/uno/bin.svg',
					click: (): void => {
						this.deleteQuestion(question);
					}
				}]);
			}

			rows.push(row);
		}

		return {
			elements: rows,
			hasMore: request.hasMore
		};
	};

	/**
	 * Changes a question index and update the item on API.
	 * 
	 * @param question - Item to be updated with new index.
	 * @param increase - If true the question index will be increased, otherwise it will be decreased.
	 */
	public async moveItem(question: DL50Question, increase: boolean): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_EDIT])) {
			throw new Error('User does not have permissions to edit (sort) the questions.');
		}

		if (question.index === 0 && !increase) {
			throw new Error('Item cannot be decreased');
		}

		if (question.index === this.totalItems - 1 && increase) {
			throw new Error('Item cannot be increased');
		}

		const idx = this.questions.indexOf(question);
		if (idx === -1) {
			throw new Error('Item not found in loadedItems.');
		}

		ArrayUtils.move(this.questions, idx, increase ? idx + 1 : idx - 1);

		for (let i = 0; i < this.questions.length; i++) {
			this.questions[i].index = i;
		}

		try {
			await DL50QuestionsService.updateBatch(this.questions);
			Modal.toast(Locale.get('success'), 3000, 'success');
			await this.resetTable();
		} catch {
			Modal.toast(Locale.get('error'), 3000, 'danger');
		}
	}

	/**
	 * Deletes a question from API.
	 * 
	 * @param question - The question to be deleted.
	 */
	public async deleteQuestion(question: DL50Question): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.DL50_SUPERVISOR, UserPermissions.DL50_MASTER_DATA_QUESTIONS_DELETE])) {
			throw new Error('User does not have permissions to delete questions');
		}

		// Modal to confirm deletion
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			try {
				await DL50QuestionsService.delete(question.uuid);
				Modal.toast(Locale.get('success'), 3000, 'success');
				await this.resetTable();
			} catch {
				Modal.toast(Locale.get('error'), 3000, 'danger');
			}
		}
	}
}
