<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Create button -->
		@if (session.user.isAdmin) {
			<uno-button icon="assets/icons/uno/add.svg" (click)="app.navigator.navigate('/menu/users/edit', {createMode: true})">
				@if (app.device.isDesktop()) {
					{{'create' | translate}}
				}
			</uno-button>
		}

		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="this.onSearchChange($event)"></uno-searchbar>
	</div>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<!-- Uno table -->
<div style="height: calc(100% - 130px); overflow: hidden; padding: 0px 10px;">
	<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="this.tablePageSize" [selectable]="false" [labelShown]="true" (rowClick)="app.navigator.navigate('/menu/users/edit', {uuid: $event.element.uuid});" (sortChange)="this.sortChanged($event.sortBy)">
	</uno-responsive-table-list>
</div>
