import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ServiceList} from '../../../../http/service-list';
import {Service} from '../../../../http/service';
import {Session} from '../../../../session';
import {CSSUtils} from '../../../../utils/css-utils';
import {DoughnutChartData, DoughnutChartComponent} from '../../../../components/charts/doughnut-chart/doughnut-chart.component';
import {UUID} from '../../../../models/uuid';
import {App} from '../../../../app';

@Component({
	selector: 'inspection-steps-graph',
	templateUrl: './inspection-steps-graph.component.html',
	standalone: true,
	imports: [IonicModule, DoughnutChartComponent, NgStyle]
})
export class InspectionStepsGraphComponent implements OnChanges {
	@Input()
	/**
	 * Project UUID, used to fetch data.
	 */
	public project: UUID = null;

	/**
	 * Meta data includes name and description
	 */
	public meta: {
		name: string,
		description: string
	} = null;

	/**
	 * Data to present on chart.
	 */
	public data: DoughnutChartData[] = [];

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.project) {
			await this.loadData();
		}
	}

	public async loadData(): Promise<void> {
		const request = await Service.fetch(ServiceList.inspection.countProject, null, null, {projectUuid: this.project}, Session.session);

		this.meta = request.response;
		const data = [];
		for (const step of request.response.steps) {
			const primary = CSSUtils.parseColor(CSSUtils.getVariable('--ion-color-primary'));
			const color = step.color ? CSSUtils.parseColor(step.color) : primary;

			data.push(new DoughnutChartData(step.name, step.count, color, () => {
				App.navigator.navigate('/menu/workflow/list', {step: step.uuid, project: this.project});
			}));
		}

		this.data = data;
	}

}
