import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {App} from 'src/app/app';
import {Dashboard} from 'src/app/models/dashboards/dashboard';
import {UnoDashboardComponent} from '../../data/components/dashboard/dashboard.component';
import {DashboardService} from '../../services/dashboard.service';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoNoDataComponent} from '../../../../components/uno/uno-no-data/uno-no-data.component';

@Component({
	selector: 'dashboard-edit-page',
	templateUrl: 'dashboard-edit.page.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoDashboardComponent, UnoContentComponent, UnoNoDataComponent]
})

export class DashboardEditPage extends ScreenComponent implements OnInit {

	/**
	 * The dashboard to display.
	 */
	public dashboard: Dashboard = null;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		if (!data.uuid) {
			throw new Error('Missing required data for the screen, uuid is required.');
		}

		this.dashboard = await DashboardService.get(data.uuid);

		App.navigator.setTitle(this.dashboard.name);

	}
}
