import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {Chart, ChartData} from 'chart.js';
import {Color} from 'three';
import {NgStyle} from '@angular/common';
import {FormatDatePipe} from 'src/app/pipes/format-date.pipe';
import {CssNgStyle} from '../../../utils/css-ng-style';
import {CSSUtils} from '../../../utils/css-utils';

export class BarChartData {
	/**
	 * Number of occurrences of the entry.
	 */
	public data: {x: any, y: any}[];

	/**
	 * Color of the bar chart.
	 */
	public color: Color = null;

	public constructor(data: {x: any, y: any}[] = [], color: Color = null) {
		this.data = data;
		this.color = color;
	}
}

@Component({
	selector: 'bar-chart',
	templateUrl: './bar-chart.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle]
})
export class BarChartComponent implements OnChanges, OnInit, OnDestroy {
	@ViewChild('canvas', {static: true})
	public canvas: ElementRef = null;

	/**
	 * Data to display in the bar chart.
	 */
	@Input()
	public data: BarChartData = null;

	/**
	 * Style of the bar chart.
	 */
	@Input()
	public ngStyle: CssNgStyle = null;

	/**
	 * Context of the bar chart.
	 */
	public context: CanvasRenderingContext2D = null;

	/**
	 * Chart of the bar chart.
	 */
	public chart: Chart = null;

	/**
	 * Dataset of the bar chart.
	 */
	public dataset: ChartData = null;

	public ngOnInit(): void {
		this.dataset = {
			labels: [],
			datasets: [{
				data: [],
				borderColor: '',
				borderWidth: 2,
				backgroundColor: '',
				hoverBackgroundColor: ''
			}]
		};

		this.context = this.canvas.nativeElement.getContext('2d');

		this.chart = new Chart(this.context, {
			type: 'bar',
			data: this.dataset,
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {legend: {display: false}},
				interaction: {mode: 'nearest'},
				scales: {
					x: {
						axis: 'x',
						ticks: {display: false},
						grid: {display: false}
					},
					y: {
						axis: 'y',
						ticks: {stepSize: 1},
						grid: {display: false}
					}
				}
			}
		});
	}

	/**
	 * Update the graph data should be called when the data is changed.
	 * 
	 * @param changes - Changes that occurred.
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.data && this.data) {
			this.data.data = this.data.data.map((value: any) => {
				return {
					x: FormatDatePipe.formatDateTime(value.x),
					y: value.y
				};
			}).filter((value: any) => {return value.x && value.y;});

			const color: string = '#' + (this.data.color ? this.data.color : CSSUtils.parseColor(CSSUtils.getVariable('--ion-color-primary'))).getHexString();

			this.dataset.datasets[0].data = this.data.data;
			this.dataset.datasets[0].borderColor = color;
			this.dataset.datasets[0].backgroundColor = color + '33';
			this.dataset.datasets[0].hoverBackgroundColor = color + '77';
			
			if (this.chart) {
				this.chart.update();
			}
		}
	}

	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}
	}
}
