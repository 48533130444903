export const LocalePT = {
	// App
	app: 'UNO Asset Management',
	uno: 'UNO',
	eqs: 'EQS',

	// Locales
	portuguese: 'Português',
	english: 'Inglês',
	spanish: 'Espanhol',

	// Months
	january: 'Janeiro',
	february: 'Fevereiro',
	march: 'Março',
	april: 'Abril',
	may: 'Maio',
	june: 'Junho',
	july: 'Julho',
	august: 'Agosto',
	september: 'Setembro',
	october: 'Outubro',
	november: 'Novembro',
	december: 'Dezembro',

	// Week days
	monday: 'Segunda-Feira',
	tuesday: 'Terça-Feira',
	wednesday: 'Quarta-Feira',
	thursday: 'Quinta-Feira',
	friday: 'Sexta-Feira',
	saturday: 'Sábado',
	sunday: 'Domingo',

	// Forms
	text: 'Texto',
	checkbox: 'Caixa de Seleção',
	document: 'Documento',

	// Login
	welcome: 'Bem-vindo!',
	enterCredentials: 'Insira as suas credenciais para aceder à plataforma.',
	login: 'Entrar',
	logout: 'Sair',
	forgotPassword: 'Recuperar palavra-passe.',
	confirmPassword: 'Confirmar palavra-passe',
	returnLogin: 'Voltar ao login.',
	twoFactorAuth: 'Autenticação de Dois Fatores',
	email: 'E-Mail',
	sms: 'SMS',
	password: 'Palavra-passe',
	reset: 'Restaurar',
	recover: 'Recuperar',
	code: 'Código',
	recoverEmail: 'Foi enviado um email com instruções para recuperar acesso.',
	enterNewPasswordReset: 'Insira nova palavra-passe para recuperar acesso à plataforma.',
	enterEmailToRecover: 'Insira o email da conta para recuperar acesso.',
	passwordsDontMatch: 'Palavras-passe inseridas são diferentes. Verifique os valores introduzidos.',
	passwordResetSuccess: 'Palavra-passe foi alterada com sucesso.',
	passwordResetError: 'Não foi possivel alterar a palavra-passe. Por favor, peça um novo email para recuperar acesso.',
	passwordSizeError: 'A palavra-passe tem que ter, pelo menos, 8 carateres.',
	loginSuccess: 'Entrou na plataforma com sucesso.',
	invalidLogin: 'Credenciais de acesso inválidas. Verifique o seu email e password de acesso.',
	invalidPhoneNumber: 'Número de telefone inválido.',
	invalidEmail: 'Endereço de email inválido.',
	twoFactorCode: 'A autenticação de dois fatores está habilitada para esta conta. Um código foi enviado para o seu e-mail/telefone. Forneça o código para continuar.',

	// Master data
	masterdata: 'Master Data',
	ffpRecommendations: 'Recomendações FFP',
	inspectionChecklists: 'Checklists de Inspeções',
	inspectionFields: 'Campos de Inspeções',
	masterdataConfirm: 'Tem a certeza que pretende continuar? Estas alterações podem causar danos irreparáveis no sistema.',
	errorRepeatedID: 'ID {id} utilizado múltiplas vezes para campos diferentes.',
	errorFieldInspectionMissing: 'Campo {field} da inspeção {inspection} não existe.',
	errorFieldInspectionRemoved: 'Campo {field} utilizado na inspeção {inspection} foi removido.',
	errorAttributeShouldHaveKeyValue: 'Atributo {attribute} deve ter o mesmo valor de chave do objeto.',
	errorOnlyBackofficeInspector: 'Campo de inspeção {field} apenas pode pertencer a backoffice ou inspection.',

	// File formats
	docx: 'DOCX',
	pdf: 'PDF',
	zip: 'ZIP',

	// Import/Export
	global: 'Geral',
	import: 'Importar',
	export: 'Exportar',
	exportJSON: 'Exportar JSON',
	dataGenerators: 'Geradores de dados',
	tools: 'Ferramentas',
	howMany: 'Quantidade',
	generate: 'Gerar',
	restore: 'Restaurar',
	database: 'Base de dados',
	size: 'Dimensão',
	runGapAnalysis: 'Executar analise de gaps',
	runGapAnalysisSub: 'Executa analise de gaps para todos os fluxos de trabalho. Recria gaps eliminadas e restaura gaps arquivadas.',
	generateQRTags: 'Marcadores QR',
	generateQRTagsSub: 'Gerador de marcadores QR com valor aleatório para associação a equipamentos.',
	checkAtexInspectionsEmptyFields: 'Verificar campos em falta nas inspeções ATEX',
	checkAtexInspectionsEmptyFieldsSub: 'Verifica se todas inspeções ATEX finalizadas têm todos os campos preenchidos. Devolve ficheiro XLSX com lista de inspeções com campos vazios.',
	checkAtexInspectionNoForms: 'Verificar seleção de formulários',
	checkAtexInspectionNoFormsSub: 'Verifica se todas as inspeções ATEX finalizadas têm pelo menos um formulário selecionado.',
	defaultAtexInspectionForm: 'Formulário de Inspeção p/ defeito?',
	defaultAtexInspectionFormError: 'Formulário de inspeção indicado não existe. Verificar a secção de masterdata correspondente.',
	updateReports: 'Atualizar Relatórios',
	updateReportsSub: 'Atualizar relatórios para todos projetos de fluxos de trabalho na plataforma.',
	resaveWorkflows: 'Guardar novamente fluxos de trabalho',
	resaveWorkflowsSub: 'Guardar novamente todas os fluxos de trabalho para atualizar as estruturas de dados internas.',
	exportXlsx: 'Exportar XLSX',
	exportGapsXlsx: 'Gaps XLSX',
	exportGapsXlsxSub: 'Exporta um ficheiro XLSX com todas as Gaps e recomendações de Gaps inseridas na platforma.',
	epiJson: 'CSI JSON',
	exportEPIJsonSub: 'Exportar dados de CSI em ficheiro JSON.',
	ffpJson: 'FFP JSON',
	exportFFPJsonSub: 'Exportar dados de FFP em ficheiro JSON.',
	assetsJson: 'Ativos JSON',
	exportAssetsJsonSub: 'Exporta ficheiro JSON com dados de todos os ativos carregados na plataforma.',
	assetsXlsx: 'Ativos XLSX',
	exportAssetsXlsxSub: 'Exporta ficheiro XLSX com lista de todos os ativos carregados na plataforma.',
	exportAssetsDetailedXlsx: 'Ativos Detalhados XLSX',
	exportAssetsDetailedXlsxSub: 'Exporta ficheiro XLSX com lista dos dados detalhados dos ativos carregados na plataforma.',
	keyQuestionCodesXlsx: 'Códigos de preguntas chave XLSX',
	atexInspectionsXlsx: 'Inspeções ATEX XLSX',
	exportAtexInspectionsXlsxSub: 'Exporta ficheiro XLSX com listagem das inspeções ATEX inseridas na plataforma.',
	workflowsXlsx: 'Fluxos de trabalho XLSX',
	exportWorkflowsXlsxSub: 'Exporta ficheiro XLSX com listagem dos fluxo de trabalho de todos os projetos de fluxo de trabalho dinâmicos na plataforma.',
	workflowsAverageXlsx: 'Média de fluxo de trabalho XLSX',
	exportWorkflowsAverageXlsxSub: 'Exporta um arquivo xlsx com os fluxos de trabalho de todos os projetos de fluxo de trabalho dinâmicos inseridos na plataforma, com valores numéricos para as respostas do checklist. Um ficheiro com códigos de perguntas-chave pode ser carregado. Cada folha de projeto tem uma coluna extra com detalhes do erro.',
	workflowsJson: 'Fluxos de trabalho JSON',
	exportWorkflowsJsonSub: 'Exporta todos os flujos de trabajo dinâmicas existentes na plataforma como JSON.',
	importWorkflowFlowJSONSub: 'Importe um fluxo de trabalho de um arquivo JSON. Importa formulários e fluxos para o módulo de fluxos de trabalho.',
	atexInspectionsAuditXlsx: 'Auditoria Inspeções ATEX XLSX',
	exportAtexInspectionsAuditXlsxSub: 'Exporta ficheiro XLSX com a listagem das inspeções Atex, juntamente com o histórico de todas as alterações.',
	atexInspectionsXlsxResults: 'Inspeções ATEX Resultados XLSX',
	exportAtexInspectionsXlsxResultsSub: 'Exporta ficheiro XLSX com os resultados de todas as inspeções ATEX finalizadas.',
	atexInspectionsReports: 'Relatórios de Inspeções ATEX',
	atexInspectionsReportsSub: 'Exporta todos os relatórios de inspeções ATEX inseridas na plataforma em formato Word ou pdf.',
	atexInspectionsJson: 'Inspeções ATEX JSON',
	exportAtexInspectionsJson: 'Exporta ficheiro JSON com dados detalhados de todas as inspeções ATEX inseridas na plataforma.',
	ffpXlsx: 'FFP XLSX',
	exportFFPXlsxSub: 'Exporta ficheiro XLSX com todos os dados e detalhes do FFP.',
	actionPlanXlsx: 'Planos de Ação XLSX',
	exportActionPlanXlsxSub: 'Exporta ficheiro XLSX com dados de todos os planos de ação.',
	atexInspectionsBulkFields: 'Alterar respostas das inspeções ATEX p/ tag de ativo',
	atexInspectionsBulkFieldsSub: 'Altera respostas de campos de inspeção de um tag e conjunto de campos definidos. A lista de tags a serem alterados é importada a partir de ficheiro XLSX.',
	bulkActionPlansFFP: 'Criar planos de ação a partir de FFP p/ Tag de ativo',
	bulkActionPlansFFPSub: 'Preenche dados de FFP para todos os equipamentos de um tag e lista de campos (R1, R2, etc) automaticamente. Cria um plano de ação com esses FFP e coloca-os no estado "Aguardar Cliente" com documento anexado. Entrada deve ser ficheiro de XLSX com coluna "Tag".',
	bulkDeleteFFPUnused: 'Eliminar FFP Inutilizados',
	bulkDeleteFFPUnusedSub: 'Apagar todos of FFP associados a campo de fluxo de trabalho com resposta "Não Aplicavel" ou de inspeção ATEX com campos inutilizados. Utilizar com cuidado. Ação irreversível.',
	repairsXlsx: 'Reparações XLSX',
	exportRepairsXlsxSub: 'Exporta ficheiro XLSX com todos os dados e detalhes das reparações registadas no sistema.',
	importRepairsXlsxSub: 'Importa lista de reparações de ficheiro XLSX. Novas reparações são criadas automaticamente e atualizadas as reparações existentes (por UUID).',
	importcalendarXlsxSub: 'Importa uma lista de eventos de calendário de um ficheiro XLSX',
	calendarXlsx: 'Plano do Ativo XLSX',
	epiXlsx: 'CSI XLSX',
	importEpiXlsx: 'Importa lista dados de CSI para a aplicação a partir de ficheiro XLSX.',
	exportEPIXlsxSub: 'Exporta lista de todos os CSI disponíveis na aplicação em formato XLSX.',
	importRENXlsxDev: 'Ativos REN XLSX (SOMENTE DEV)',
	importRENXlsxSubDev: 'Ferramenta para importar ativos REN e criar hierarquia de portfólio de ativos a partir de um arquivo XLSX.',
	importAssetsXlsx: 'Ativos XLSX',
	importAssetsXlsxSub: 'Ferramenta para importar ativos com informação base, a partir de um arquivo XLSX.',
	exportWorkflowsReportsBulk: 'Relatórios de Fluxo de trablaho',
	exportWorkflowsReportsBulkSub: 'Exporta os relatórios de todos os fluxo de trablaho associadas a ativos contidos numa lista apresentado numa única coluna de um ficheiro XLSx com "ativos" como cabeçalho.',
	exportRepairInspectionsReportsBulk: 'Relatórios de Inspeções de Reparação',
	exportRepairInspectionsReportsBulkSub: 'Exporta os relatórios de todas as inspeções de reparação.',
	exportFormBlocksXlsx: 'Blocos de Formulário (XLSX)',
	exportFormBlocksXlsxSub: 'Exporta blocos de formulário do asset portfólio, assim como todos os seus campos de formulário no formato XLSX. ',
	importFormBlocksXlsx: 'Blocos de Formulário (XLSX)',
	importFormBlocksXlsxSub: 'Importa blocos de formulário e os respetivos campos de fomrulário',
	importFormBlocksXlsxAdditionalInformation: 'Esta ferramenta pode causar perda permanente de dados.\n\nItens com UUID serão atualizados, caso contrário serão criados. Os campos de bloco de formulário devem referênciar a um bloco de formulário na folha de blocos de formulário por UUID ou nome. Campos de bloco de formulário sem indexação serão indexados pela sua ordem no ficheiro. Se forem selecionadas as opções para "eliminar elementos não presentes no documento", estes elementos serão excluídos permanentemente.',
	deleteMissingItemsWarning: 'Selecionou opções para eliminar elementos não presentes no documento de forma permanente.',
	exportHasEnded: 'A exportação terminou.',
	exportAssetPortfolioStructureXlsx: 'Estrutura do Portefólio de Ativos XLSX',
	exportAssetPortfolioStructureXlsxSub: 'Exporta toda a estrutura de tipos, sub-tipos e blocos de formulário do portefólio de ativos no formato XLSX.',
	exportAssetPlanningXlsxSub: 'Exporta os eventos visíveis, aplicando os filtros ativos',
	userXlsx: 'Utilizador Excel',
	exportUserSub: 'Exporta todos os utilizadores no formato XLSX.',
	errorMissingFormOnImport: 'Existem referências a um formulário com o UUID "{formUuid}" mas este está em falta no ficheiro importado.',

	// Inspections
	noFormFields: 'Sem campos de formulário. Clique em "adicionar" para criar campos de formulário.',
	noWorkflowSteps: 'Sem passos de fluxo. Clique em "adicionar" para criar novos.',
	noWorkflowAdjacencies: 'Sem ligações entre os passos de fluxo. Clique em "adicionar" para criar novas adjacências.',
	createAtexInspection: 'Criar Inspeção ATEX',
	atexInspectionReportIEC60079: 'Inspeções efetuadas de acordo com a norma IEC 60079-17 Atmosferas explosivas - Parte 17: Instalações elétricas, inspeções e manutenção.',
	atexInspectionReportFormLabel: 'OK - Aprovado, equipamento cumpre requisito.\nOK EQS - Aprovado. Foram necessárias alterações de modo a cumprir o requisito.\nNOK - Não aprovado, equipamento falha o requisito.\nNão aplicável - Requisito, não se aplica ao equipamento em análise.',
	atexInspectionReportDisclaimer: 'Os resultados do ensaio referem-se exclusivamente aos itens ensaiados.\nEste documento só pode ser reproduzido na íntegra.',
	atexInspectionReport: 'Ficha de Inspeção de Equipamentos Atex.',
	inspectionPhotos: 'Fotos da Inspeção',
	equipmentData: 'Ficha de Equipamento',
	inspectionDetails: 'Detalhes de Inspeção',
	inspectionDate: 'Data da Inspeção',
	documentDate: 'Data do Documento',
	myTeamOnly: 'Apenas Equipa',
	withDocuments: 'Com Documentos',
	waitingDocuments: 'Aguardar Documentos',
	inspectionReport: 'Relatório de Inspeção',
	details: 'Detalhes',
	resultInspection: 'Resultado de Inspeção',
	resultFinal: 'Resultado Final',
	nok: 'Not Ok',
	okRevised: 'Ok EQS',
	todo: 'Para Fazer',
	review: 'Em Revisão',
	approved: 'Aprovado',
	disapproved: 'Reprovado',
	pending: 'Pendente',
	conditionallyApproved: 'Aprovado Condicionalmente',
	completed: 'Finalizado',
	implemented: 'Implementado',
	underSupervision: 'Em Supervisão',
	failed: 'Reprovado',
	inspection: 'Inspeção',
	inspectionNumber: 'Número de Inspeção',
	atexInspection: 'Inspeção ATEX',
	inspectorsCount: 'Número de Inspetores',
	inspectionForms: 'Formulários de Inspeção',
	workflowProject: 'Projeto de Fluxo de Trabalho',
	workflowProjects: 'Projetos de Fluxo de Trabalho',
	workflowProjectUuid: 'UUID do Projeto de Fluxo de Trabalho',
	inspectorForm: 'Formulário Inspector',
	backofficeForm: 'Formulário Backoffice',
	approve: 'Aprovar',
	reject: 'Rejeitar',
	clientFeedback: 'Comentários do cliente',
	messageSupervisor: 'Mensagem para o supervisor.',
	messageRejection: 'Justificação de reprovação.',
	inspectionRejected: 'Inspeção reprovada',
	exportReport: 'Exportar Relatório',
	exportReportPDF: 'Exportar Relatório (PDF)',
	exportReportDOCX: 'Exportar Relatório (Word)',
	editAsset: 'Editar Ativo',
	confirmReject: 'Tem a certeza que quer rejeitar a inspeção?',
	confirmRedirect: 'Se continuar com esta ação, todos os dados não salvos serão perdidos. Confirme para continuar ou cancele esta ação e salve os dados antes de avançar.',
	sendToSupervision: 'Enviar para supervisor',
	returnToTodo: 'Retornar para Refazer',
	returnToRevision: 'Retornar para Revisão',
	returnToBackoffice: 'Retornar para Backoffice',
	successInspectionSave: 'Dados guardados com sucesso.',
	successInspection: 'Dados da inspeção foram submetidos com sucesso.',
	errorInspectionResult: 'Tem que selecionar um resultado para a inspeção.',
	errorSelectForm: 'Tem que selecionar pelo menos um formulário de inspecção.',
	errorInspectionState: 'Estado de inspeção inválido.',
	errorInspectionNotFound: 'Inspeção não foi encontrada na base de dados.',
	warningSelectForm: 'Não selecionou nenhum formulário de inspeção. Tem a certeza que pretende continuar?',
	warningApproveNok: 'O resultado da inspeção é Aprovado mas um ou mais campos foram marcados como Not Ok. Tem a certeza que pretende continuar?',
	exportAllXlsx: 'Exportar todas (XLSX)',
	projectName: 'Nome do Projeto',
	projectCode: 'Código do Projeto',
	createInspection: 'Criar inspeção',
	optionsDisplayMode: 'Modo de apresentação das opções',
	checklistAnswersAvg: 'Média Respostas Checklist',
	checklistWightedAnswersAvg: 'Média Ponderada das Respostas da Checklist',
	projectChecklistWeight: 'Peso da Checklist',
	bypassRequiredCheck: 'Ignorar verificação obrigatória',
	confirmRequiredCheckBypass: 'Alguns campos de formulário são requeridos e estão vazios. Pretende guardar de qualquer modo?',
	lockStepData: 'Bloquear dados dos steps',
	true: 'Verdadeiro',
	false: 'Falso',
	keyQuestionCodesFile: 'Ficheiro de questões chave',
	overallAssessment: 'Avaliação global',
	assetStatus: 'Estado do ativo',
	inspectionQR: 'QR da inspeção',
	createNewInspectionConfirmation: 'Não existem fluxos de trabalho para este evento. Deseja criar uma nova?',
	article: 'Artigo',
	navigateNextStep: 'Deseja navegar para o próximo passo desta inspeção?',
	allowExportReportFromList: 'Permitir exportação de relatórios da lista de inspeções',
	createWorkflow: 'Criar Fluxo de Trabalho',

	// Gap Analysis
	gapAnalysis: 'Gap Analysis',
	gap: 'Falha',
	gaps: 'Falhas',
	gapUuid: 'UUID da Gap',
	analysesGaps: 'Analisa Gaps',
	generatesGaps: 'Gera Gaps',
	generatesGapsOptions: 'Opção que gera gaps',
	generatesGapsMultipleOptions: 'Opções que geram gaps',
	gapName: 'Nome da gap',
	gapDescription: 'Descrição da gap',
	gapDocuments: 'Documentos de gap',
	inspectionDataUuid: 'UUID dados de inspeção',
	inspectionFieldUuid: 'UUID campo de inspeção',
	financialImpact: 'Impacto Financeiro',
	gapRecommendations: 'Recomendações de gaps',
	recommendationUuid: 'UUID da recomendação',
	recommendationDescription: 'Descrição da recomendação',
	recommendationDocuments: 'Documentos de recomendação',

	// Expression Editor
	expressionEditor: 'Editor de Expressões',

	// Observation
	createObservation: 'Criar Observação',
	observationCreated: 'Observação criada com sucesso.',
	observationUpdated: 'Observação foi atualizada com sucesso.',

	// Atex
	unclassified: 'Não Classificado',
	unclassifiedZone: 'Zona Não Classificada',
	zone0: 'Zona 0',
	zone1: 'Zona 1',
	zone2: 'Zona 2',
	zone20: 'Zona 20',
	zone21: 'Zona 21',
	zone22: 'Zona 22',
	mine: 'Mina',
	errorAtexTags: 'Propriedade de ATEX precisa ter pelo menos um rótulo.',
	resultAtexInspection: 'Resultado de Inspeção ATEX',
	resultAtexFinal: 'Resultado Final de Inspeção ATEX',
	liftingEquipmentRequired: 'Necessários Equipamentos Elevatórios',
	confirmAtexCreate: 'Tem a certeza de que deseja criar uma inspeção ATEX?',
	hasFfp: 'Tem FFPs',

	// Project
	inspectionTypeRequired: 'Tipo de Inspeção Obrigatório',

	// Assets
	locations: 'Localizações',
	location: 'Localização',
	plants: 'Fábricas',
	plant: 'Fábrica',
	operationalUnits: 'Unidades Operacionais',
	operationalUnit: 'Unidade Operacional',
	operationalUnitTag: 'Tag Unidade Operacional',
	equipments: 'Equipamentos',
	equipment: 'Equipamento',
	equipmentTag: 'Tag Equipamento',
	equipmentSubtype: 'Subtipo do Equipamento',
	equipmentOwner: 'Proprietário do equipamento',
	components: 'Componentes',
	singlePhase16: 'Single phase 16A',
	triplePhase16: 'Three phase 16A',
	triplePhase63: 'Three phase 63A',
	hsx: 'HS X',
	codeLightStruct: 'Código c/ estrutura do Desenho de Iluminação',
	modelList: 'Listagem Modelos',
	assetAlreadySelected: 'Ativo já selecionado.',

	// QR
	qr: 'QR',
	qrReader: 'Leitor QR',
	qrCode: 'Código QR',
	generateQR: 'Gerar QR',
	attachQR: 'Associar QR',
	errorCameraNotFound: 'Não foi possível aceder à câmara do dispositivo.',
	qrUnknown: 'Código QR não está associado a nenhuma entrada.',
	qrSuccess: 'QR code lido com sucesso.',

	// NFC
	nfc: 'NFC',
	nfcReader: 'Leitor NFC',
	readNFC: 'Aproximar tag NFC',
	attachNFC: 'Associar NFC',
	noNFCReader: 'Dispositivo incompatível com funcionalidade de leitura NFC.',
	nfcUnknown: 'Tag NFC não está associado a nenhum ativo.',
	nfcErrorStarting: 'Erro ao iniciar leitor de NFC. Verifique se NFC está ativo.',
	nfcSuccessStarting: 'Leitor de NFC iniciado com sucesso.',
	nfcSuccess: 'Tag NFC lida com sucesso.',

	// FFP
	ffpShort: 'FFP',
	ffp: 'Fit For Purpose',
	ffpSaved: 'Dados de FFP gravados com sucesso.',
	low: 'Baixa',
	medium: 'Média',
	high: 'Alta',
	cost: 'Orçamento',
	priority: 'Prioridade',
	fieldName: 'Nome do Campo',
	lastFFPSByAsset: 'Últimos FFPs por ativo',

	// EPI
	epiShort: 'CSI',
	epi: 'Circuitos de Segurança Intrínseca',
	zener: 'Zener',
	galvanic: 'Galvânico',
	barrier: 'Barreira',
	barrierId: 'ID Barreira',
	barrierType: 'Tipo Barreira',
	cable: 'Cabo',
	cables: 'Cabos',
	resultingCable: 'Cabo Resultante',
	comments: 'Comentários',
	resistance: 'Resistência',
	resistanceSection: 'Resistência Secção',
	maxOutputVoltage: 'Tensão de Saída Máxima (Vo)',
	maxOutputCurrent: 'Corrente de Saída Máxima (Io)',
	maxOutputPower: 'Potência de Saída Máxima (Po)',
	maxExternalCapacity: 'Capacidade Externa Máxima (Co)',
	maxExternalInductance: 'Indutância Externa Máxima (Lo)',
	groundResistance: 'Resistência à Massa (Rt)',
	maxInputVoltage: 'Tensão de Entrada Máxima (Vi)',
	maxInputCurrent: 'Corrente de Entrada Máxima (Ii)',
	maxInputPower: 'Potência de Entrada Máxima (Pi)',
	maxInternalCapacity: 'Capacidade Interna Máxima (Ci)',
	maxInternalInductance: 'Indutância Interna Máxima (Li)',
	length: 'Comprimento',
	inductance: 'Indutância',
	cableCapacity: 'Capacidade (Cc)',
	capacitySection: 'Capacidade Secção',
	cableInductance: 'Indutância (Lc)',
	inductanceSection: 'Indutância Secção',
	intrinsicSecurityTest: 'Prova de segurança intrínseca',
	acceptedResult: 'Aceite/Não Aceite',
	epiSaved: 'Circuito de segurança intrínseca guardado com sucesso.',
	epiSubmitted: 'Circuito de segurança intrínseca enviado para revisão.',
	epiApproved: 'Circuito de segurança intrínseca aprovado com sucesso.',
	loopEquipments: 'Equipamentos Loop',

	// Digital Twin
	locked: 'Bloqueado',
	fullscreen: 'Ecrã Completo',
	visible: 'Visível',
	top: 'Cima',
	bottom: 'Baixo',
	left: 'Esquerda',
	right: 'Direita',
	front: 'Frente',
	pointcloud: 'Nuvem de Pontos',
	move: 'Mover',
	rotate: 'Rodar',
	scale: 'Dimensionar',
	screenshot: 'Captura de Ecrã',
	navigate: 'Navegar',
	transform: 'Transformar',
	focus: 'Focar',
	selectObjectFirst: 'Para realizar esta operação selecione um objeto.',
	lockedEditError: 'Objetos bloqueados não podem ser editados. Desbloqueie o objeto antes de aplicar as alterações.',

	// Misc
	typography: 'Tipografia',
	selectMultiple: 'Selecionar Vários',
	displayMode: 'Modo de Apresentação',
	sections: 'Secções',
	tabs: 'Tabs',
	inspectionResult: 'Resultado da Inspeção',
	flowMode: 'Modo Fluxo',
	sendNotification: 'Enviar Notificação',
	executed: 'Executado',
	preview: 'Pre-visualização',
	manufacturingYear: 'Ano de Fabrico',
	installationDate: 'Data de Instalação',
	level: 'Nível',
	itemsOf: '{n} de {total} elementos',
	pagesOf: '{n} de {total} páginas',
	alarmConfiguration: 'Configuração de Alarme',
	configuration: 'Configuração',
	inProgress: 'Em Progresso',
	noAction: 'Sem Ação',
	filters: 'Filtros',
	customized: 'Personalizado',
	activities: 'Atividades',
	copy: 'Cópia',
	clone: 'Clonar',
	deepClone: 'Clone Integral',
	cloneSubForms: 'Clonar sub-formulários',
	cloneSuccessfully: 'Clonado com sucesso',
	spacing: 'Espaçamento',
	chemical: 'Químico',
	audit: 'Auditoria',
	stopMaintenance: 'Paragem de Manutenção',
	actionType: 'Tipo Ação',
	actionSubtype: 'Sub-Tipo Ação',
	duration: 'Duração',
	alarmType: 'Tipo de Alarme',
	alarmOffset: 'Notificação prévia',
	fileName: 'Nome do Ficheiro',
	upload: 'Carregar',
	uploadXlsx: 'Carregar XLSX',
	row: 'Linha',
	accept: 'Aceitar',
	deleteNotApplicable: 'Eliminar Não Aplicáveis',
	deleteNonExistent: 'Eliminar Inexistentes',
	deleteEvent: 'Eliminar Evento',
	nonExistentField: 'Campo Inexistente',
	notApplicableField: 'Campo Não Aplicável',
	additionalInformations: 'Informações adicionais',
	user: 'Utilizador',
	formats: 'Formatos',
	signature: 'Assinatura',
	composedField: 'Campo Composto',
	multipleOptions: 'Múltiplas Opções',
	multipleOptionsLegacy: 'Múltiplas Opções Legacy',
	requireAsset: 'Ativo Obrigatório',
	subForm: 'Sub-Formulário',
	multipleForm: 'Multiplos Formulários',
	required: 'Obrigatório',
	sampleFile: 'Ficheiro Exemplo',
	anomalies: 'Irregularidades',
	forecast: 'Previsão',
	fillGaps: 'Preencher Falhas',
	basic: 'Básico',
	confidenceInterval: 'Intervalo de Confiança',
	average: 'Média',
	derivative: 'Derivada',
	derivativeAbsolute: 'Derivada (Absoluto)',
	timeseries: 'Série Temporal',
	last: 'Último',
	first: 'Primeiro',
	areaZoom: 'Selecionar área de zoom',
	restoreZoom: 'Restaurar nível de zoom.',
	saveAsImage: 'Guardar como Imagem',
	forAll: 'Para Todos',
	onlyForNew: 'Apenas para novos',
	format: 'Formato',
	separator: 'Separador',
	showInMenu: 'Mostrar no Menu',
	reports: 'Relatórios',
	manual: 'Manual',
	template: 'Modelo',
	templates: 'Modelos',
	reportTemplate: 'Modelo de Relatório',
	proposalTemplate: 'Modelo de Proposta',
	origin: 'Origem',
	destination: 'Destino',
	label: 'Etiqueta',
	forms: 'Formulários',
	steps: 'Passos',
	step: 'Passo',
	defaultStep: 'Passo Inicial',
	adjacency: 'Adjacência',
	adjacencies: 'Adjacências',
	workflow: 'Fluxo de Trabalho',
	workflows: 'Fluxos de Trabalho',
	workflowUuid: 'UUID do Fluxo de Trabalho',
	projects: 'Projetos',
	open: 'Abrir',
	view: 'Ver',
	nextMonth: 'Próximo Mês',
	previousMonth: 'Mês Anterior',
	startDate: 'Data de Início',
	endDate: 'Data de Fim',
	repetitions: 'Repetições',
	mode: 'Modo',
	effectiveDate: 'Data Efetiva',
	change: 'Alterar',
	register: 'Registar',
	clear: 'Limpar',
	allowClear: 'Permitir Limpar',
	number: 'Número',
	silent: 'Silêncio',
	expression: 'Expressão',
	color: 'Cor',
	virtual: 'Virtual',
	condition: 'Condição',
	inside: 'Dentro',
	outside: 'Fora',
	greater: 'Superior',
	less: 'Inferior',
	inBetween: 'Entre',
	response: 'Resposta',
	noDocuments: 'Sem Documentos',
	overrideResult: 'Alterar Resultado',
	overrideFinalResult: 'Alterar Resultado Final',
	includeArchived: 'Incluir Arquivados',
	includeBackoffice: 'Incluir Campos Backoffice',
	includeInspector: 'Include Campos Inspetor',
	includeCompleted: 'Incluir Finalizados',
	includeWithActionPlan: 'Incluir c/ Plano de Ação',
	includeWithRecommendations: 'Incluir c/ Recomendações',
	skipHasRecommendations: 'Não alterar FFP se tiver Recomendações',
	dontChange: 'Não Alterar',
	equipmentProtection: 'Proteção Equipamento',
	recenter: 'Recentrar',
	permissions: 'Permissões',
	copyDocuments: 'Copiar Documentos',
	copiedSuccessfully: 'Copiado com sucesso',
	archived: 'Arquivado',
	archive: 'Arquivar',
	unarchived: 'Não Arquivado',
	unarchive: 'Desarquivar',
	waiting: 'Em Espera',
	resolved: 'Resolvido',
	direction: 'Direção',
	sortField: 'Campos Ordenação',
	sortBy: 'Ordenar Por',
	date: 'Data',
	time: 'Tempo',
	asset: 'Ativo',
	noAsset: 'Sem Ativo',
	graph: 'Gráfico',
	events: 'Eventos',
	event: 'Evento',
	alarms: 'Alarmes',
	alarm: 'Alarme',
	sensor: 'Sensor',
	sensors: 'Sensores',
	showAll: 'Ver Todos',
	treeView: 'Vista em Árvore',
	tableFields: 'Campos da Tabela',
	searchFields: 'Campos de Pesquisa',
	noRecommendation: 'Sem Recomendação',
	hasRecommendation: 'Com Recomendações',
	withActionPlan: 'Com Plano de Ação',
	withoutActionPlan: 'Sem Plano de Ação',
	list: 'Lista',
	listMode: 'Modo de listagem',
	calendar: 'Calendário',
	file: 'Ficheiro',
	download: 'Descarregar',
	documents: 'Documentos',
	images: 'Imagens',
	minute: 'Minuto',
	hour: 'Hora',
	day: 'Dia',
	week: 'Semana',
	month: 'Mês',
	year: 'Ano',
	minutes: 'Minutos',
	hours: 'Horas',
	days: 'Dias',
	weeks: 'Semanas',
	months: 'Meses',
	years: 'Anos',
	hourly: 'De hora em hora',
	daily: 'Diariamente',
	weekly: 'Semanalmente',
	monthly: 'Mensalmente',
	yearly: 'Anualmente',
	selectionMode: 'Modo de Seleção',
	accepted: 'Aceite',
	run: 'Executar',
	theme: 'Tema',
	development: 'Desenvolvimento',
	related: 'Relacionado',
	latitude: 'Latitude',
	longitude: 'Longitude',
	altitude: 'Altitude',
	asc: 'Ascendente',
	desc: 'Descendente',
	updatedAt: 'Data de atualização',
	update: 'Atualizar',
	updateAll: 'Atualizar Todos',
	createdAt: 'Data Criação',
	created: 'Criado',
	updated: 'Atualizado',
	yes: 'Sim',
	no: 'Não',
	edited: 'Editado',
	nd: 'N/a',
	compliant: 'Conforme',
	nonCompliant: 'Não Conforme',
	nonConformities: 'Inconformidades',
	done: 'Feito',
	message: 'Mensagem',
	unknown: 'Desconhecido',
	error: 'Erro',
	errors: 'Erros',
	errorLogs: 'Logs Erro',
	errorLogsTasks: 'Logs Erro Tarefas',
	errorLogsClient: 'Logs Erro Cliente',
	success: 'Sucesso',
	ok: 'Ok',
	cancel: 'Cancelar',
	confirm: 'Confirmar',
	all: 'Todos',
	create: 'Criar Novo',
	delete: 'Eliminar',
	remove: 'Remover',
	edit: 'Editar',
	add: 'Adicionar',
	back: 'Voltar',
	dismiss: 'Dispensar',
	close: 'Fechar',
	options: 'Opções',
	optionsLegacy: 'Opções Legacy',
	form: 'Formulário',
	select: 'Selecionar',
	map: 'Mapa',
	demo: 'Demonstração',
	next: 'Próximo',
	previous: 'Anterior',
	none: 'Nenhum',
	save: 'Guardar',
	field: 'Campo',
	fields: 'Campos',
	question: 'Questão',
	questions: 'Questões',
	requiredField: 'Campo obrigatório.',
	notApplicable: 'Não aplicável',
	submit: 'Submeter',
	warning: 'Aviso',
	selectFile: 'Carregar',
	preserveUuids: 'Preservar UUIDs',
	notFinished: 'Por Finalizar',
	finished: 'Concluído',
	finish: 'Finalizar',
	selectTime: 'Selecionar Hora',
	selectDate: 'Selecionar Data',
	version: 'Versão',
	timestamp: 'Data',
	commit: 'Commit',
	branch: 'Branch',
	production: 'Produção',
	dateFormat: 'Formato Datas',
	timeFormat: 'Formato Tempo',
	history: 'Histórico',
	actions: 'Ações',
	action: 'Ação',
	unlock: 'Desbloquear',
	triggerEvent: 'Acionado por outro evento',
	triggerEventOffset: 'Desvio entre eventos',
	triggerEventUuid: 'UUID do Evento acionador',
	triggerEventDirection: 'Direção do acionamento',
	offsetDirection: 'Direção do desvio',
	offset: 'Desvio',
	angularOffset: 'Desvio Angular',
	numberTimesRepeat: 'Número de vezes a repetir',
	simultaneous: 'Em simultâneo',
	before: 'Antes',
	after: 'Depois',
	accessLogs: 'Logs de Acesso',
	createNewAsset: 'Criação de Novo Ativo',
	today: 'Hoje',
	percentage: 'Percentagem',
	tab: 'Aba',
	addTab: 'Adicionar Aba',
	card: 'Cartão',
	addCard: 'Adicionar cartão',
	modal: 'Modal',
	popover: 'Popover',
	search: 'Procurar',
	data: 'Dados',
	index: 'Índice',
	indexes: 'Indexação',
	help: 'Ajuda',
	empty: 'Vazio',
	default: 'Defeito',
	defaultOption: 'Opção por defeito',
	createEvent: 'Criar Evento',
	eventCreated: 'Evento criado com sucesso.',
	eventEdited: 'Evento editado com sucesso.',
	eventError: 'Erro ao criar/editar o evento.',
	planning: 'Planeamento',
	scheduledEvents: 'Eventos Agendados',
	assetEvents: 'Eventos de ativos',
	repairEvents: 'Eventos de reparação',
	confirmEditSelection: 'Deseja editar este evento ou todos os eventos?',
	confirmDeleteSelection: 'Deseja eliminar este evento ou todos os eventos?',
	allEvents: 'Todos os eventos',
	thisEvent: 'Este Evento',
	myEvents: 'Os Meus Eventos',
	sorting: 'Ordenação',
	wizard: 'Assistente',
	singleStep: 'Passo único',
	replace: 'Substituir',
	read: 'Ler',
	chooseFile: 'Escolha Ficheiro',
	toUpload: 'para carregar',
	files: '(DOCX, PDF, XLSX e outros)',
	imageFiles: '(PNG, JPG e outros)',
	videoFiles: '(MP4, MKV, MPEG, AVI e outros)',
	audioFiles: '(MP3, WAV, OGG e outros)',
	dragAndDrop: 'Arrastar e largar',
	or: 'ou',
	openCamera: 'abrir camera',
	requiredAssetFields: 'Campos de ativo obrigatórios',
	newAsset: 'Novo Ativo',
	assignments: 'Atribuições',
	dropFile: 'Larga o teu ficheiro para carregar',
	compressPictures: 'Comprimir Fotos',
	showOthersSum: 'Mostrar a soma dos outros ativos',
	pinned: 'Fixado',
	notPinned: 'Não fixado',

	// Components
	repetitiveForm: 'Form Repetitivo',
	assetSelector: 'Seletor de ativos',
	companySelector: 'Seletor de empresa',
	teamSelector: 'Seletor de equipa',
	userSelector: 'Seletor de utilizador',
	textMultiline: 'Texto multi-linha',
	toggle: 'Toggle',
	image: 'Imagem',
	radio: 'Rádio',
	numberSlider: 'Números (slider)',
	numberRange: 'Números (range)',
	atexField: 'Campo ATEX',
	stringArray: 'Array de valores',
	dateFrequency: 'Data de frequência',
	checkboxGroup: 'Grupo de Checkboxes',
	noActionAvailable: 'Nenhuma ação disponível',

	// Roles
	userRoles: 'Cargos de Utilizador',
	userRole: 'Cargo de Utilizador',
	userRoleCreated: 'Novo cargo de utilizador criado com sucesso.',
	userRoleDeleted: 'Cargo de utilizador eliminado',
	userRoleDeleteError: 'Ocorreu um erro ao eliminar cargo de utilizador',
	administrator: 'Administrador',
	inspector: 'Inspetor',
	backoffice: 'Escritório',
	supervisor: 'Supervisor',
	client: 'Cliente',

	// Attributes
	onlyApplicable: 'Apenas Aplicável',
	activationTime: 'Tempo de Ativação',
	min: 'Mínimo',
	max: 'Máximo',
	kpi: 'KPI',
	units: 'Unidades',
	ffpCount: 'Contagem FFP',
	projectUuid: 'UUID Projeto',
	ffpUuid: 'UUID FFP',
	inspectionUuid: 'UUID Inspeção',
	assetUuid: 'UUID Ativo',
	parentUuid: 'UUID Ativo Superior',
	parentName: 'Nome Ativo Superior',
	parentTag: 'Tag Ativo Superior',
	operationalUnitUuid: 'UUID Unidade Operacional',
	actionPlanUuid: 'UUID Plano de Ação',
	actionPlanDescription: 'Descrição do Plano de Ação',
	teamUuid: 'UUID Equipa',
	teamUuids: 'UUID Equipas',
	companyUuid: 'UUID Empresa',
	userUuid: 'UUID Utilizador',
	userUuids: 'UUIDs de utilizador',
	fieldUuid: 'UUID do campo',
	fieldText: 'Texto do campo',
	fieldLabel: 'Nome do campo',
	recommendation: 'Recomendação',
	recommendations: 'Recomendações',
	hasRecommendations: 'Tem Recomendações',
	alertEmail: 'Alerta Email',
	actionPlans: 'Planos de Ação',
	actionPlansProgress: 'Progresso Planos de Ação',
	resolution: 'Resolução',
	workOrder: 'Ordem de Trabalho',
	inspectionFrequency: 'Periodicidade Inspeção',
	company: 'Empresa',
	state: 'Estado',
	limitDate: 'Data Limite',
	address: 'Endereço',
	vat: 'NIF',
	observations: 'Observações',
	observation: 'Observação',
	uuid: 'Identificador Único',
	id: 'Identificador',
	attribute: 'Atributo',
	value: 'Valor',
	name: 'Nome',
	phoneNumber: 'Telefone',
	notes: 'Notas',
	active: 'Ativo',
	inactive: 'Inativo',
	role: 'Função',
	picture: 'Imagem',
	pictures: 'Imagens',
	description: 'Descrição',
	quantity: 'Quantidade',
	tag: 'Tag',
	position: 'Posição',
	manufacturer: 'Fabricante',
	model: 'Modelo',
	modelIdentification: 'Identificação do modelo',
	serialNumber: 'Número de série',
	atex: 'ATEX',
	atexTags: 'ATEX Tags',
	atexInfo: 'Informação ATEX',
	atexCompliant: 'Conformidade ATEX',
	zoneClassification: 'Classificação Zona',
	temperatureClass: 'Classificação de Temperatura',
	explosionGroup: 'Grupo de Explosão',
	category: 'Categoria',
	type: 'Tipo',
	typeUuid: 'Tipo UUID',
	subtype: 'Sub-tipo',
	subtypeUuid: 'Sub-tipo UUID',
	current: 'Corrente',
	voltage: 'Tensão',
	nominalVoltage: 'Tensão Nominal',
	power: 'Potência',
	rpm: 'RPM',
	weight: 'Peso',
	insulationClass: 'Classe Isolamento',
	energeticClass: 'Classe energética',
	output: 'Saída',
	output1: 'Saída 1',
	output2: 'Saída 2',
	output3: 'Saída 3',
	signalRange: 'Gama de Sinal',
	workRange: 'Gama de Trabalho',
	flangeSize: 'Dimensão da Flange',
	photos: 'Fotografias',
	photo: 'Fotografia',
	video: 'Vídeo',
	audio: 'Áudio',
	result: 'Resultado',
	status: 'Estado',
	overall: 'Geral',
	tagPicture: 'Tag Equipamento',
	nameplate: 'Chapa Características',
	justifications: 'Justificações',
	loopDiagram: 'Diagrama Loop',
	diagram: 'Diagrama',
	bearingShaft: 'Refª rolamento lado ataque',
	bearingOppositeShaft: 'Refª rolamento lado contrário ao ataque',
	supplyVoltage: 'Tensão de alimentação',
	designTemperature: 'Temperatura de Projeto',
	workTemperature: 'Temperatura de Trabalho',
	designPressure: 'Pressão de Projeto',
	workPressure: 'Pressão de Trabalho',
	corrosionThickness: 'Sobre-espessura de Corrosão',
	thickness: 'Espessura',
	diameter: 'Diametro',
	material: 'Material',
	summary: 'Sumário',
	scheduled: 'Planeados',
	selectValidAsset: 'Selecione um ativo válido',
	selectValidRepair: 'Selecione uma reparação válida',

	// Equipment subtypes
	base: 'Base',
	motor: 'Motores',
	motorHandSwitch: 'Seletor',
	valve: 'Acionadores de Válvulas',
	jb: 'J.B (Caixas Junção)',
	transmittersPressure: 'Transmissores de Pressão',
	transmittersLevel: 'Transmissores de Nível',
	transmittersFlow: 'Transmissores de Caudal',
	transmittersTemperature: 'Transmissores de Temperatura',
	illumination: 'Iluminação e CX Derivação',
	emergency: 'Emergência',
	socketsDerivationBoxes: 'Tomadas e CX derivação',
	security: 'Detetores, Botoneiras, Paragens de Emergência',
	analysers: 'Analisadores',
	limitSwitch: 'Posicionadores e Fins de Curso',
	transducer: 'Conversores (I/P)',
	pipeline: 'Tubagem',
	safetyEquipment: 'Equipamento de Segurança',

	// Dashboard
	fromDate: 'Desde',
	toDate: 'Até',
	dailyHours: 'Horas diárias',
	dashActionPlanRejected: 'Planos de Ação Rejeitados',
	dashActionPlanRejectedSub: 'Planos de ação rejeitados pelo cliente.',
	dashActionPlanBlocked: 'Planos de Açao Bloqueados',
	dashActionPlanBlockedSub: 'Planos de ação a aguardar ações de desbloqueio.',
	dashActionExecuted: 'Planos de Ação Executados',
	dashActionExecutedSub: 'Taxa de execuçao dos planos de ação propostos.',
	dashActionPlanSub: 'Indica o número de planos de ação existentes em cada fase de execução.',
	dashProgress: 'Progresso das Inspeções',
	dashProgressSub: 'Número de inspeções finalizadas e o progresso ds inspeções a decorrer nas várias fases. Inspeções em Revisão estão em análise no backoffice. Inspeções em Supervisão estão a aguardar análise do supervisor para verificações adicionais.',
	dashResult: 'Resultado das Inspeções',
	dashResultSub: 'Resultado das inspeções, indica a quantidade de equipamentos que necessitam verificações. Inspeções por finalizar, são todas as que ainda não foram totalmente concluídas.',
	dashInspectionUpdates: 'Inspeções Atualizadas',
	dashInspectionUpdatesSub: 'Número de inspeções atualizadas em por dia. Inclui inspeções feitas, supervisões, revisões e correções a inspeções já finalizadas.',
	dashInspectionPerformed: 'Inspeções Feitas',
	dashInspectionPerformedSub: 'Número de inspeções feitas pelos inspetores de campo diariamente.',
	dashInspectionReviewed: 'Inspeções Revistas',
	dashInspectionReviewedSub: 'Número de inspeções revistas pela equipa de backoffice diariamente.',
	dashTimeInspection: 'Tempo por Inspeção',
	dashTimeInspectionSub: 'Tempo médio por inspeção, pode ser calculado diária, semanal ou mensalmente. Valor calculado em minutos por inspeção, ajustado ao número de horas diárias de trabalho e número de inspetores.',

	// Action plan
	createActionPlan: 'Criar Plano de Ação',
	underWork: 'Em construção',
	waitingClient: 'Aguardar Cliente',
	rejected: 'Rejeitado',
	notRejected: 'Não Rejeitado',
	blocked: 'Bloqueado',
	waitingReinspection: 'Aguardar Reinspeção',
	actionPlanActions: 'Ações para Plano de Ação',
	reinspection: 'Reinspeção',
	reinspections: 'Reinspeções',
	viewAsset: 'Ver Ativo',
	viewInspection: 'Ver Inspeção',
	implementation: 'Implementação',

	// Asset Portfolio
	structure: 'Estrutura',
	editable: 'Editável',
	formTab: 'Aba de Formulário',
	formTabUuid: 'UUID da Aba de Formulário',
	formTabs: 'Abas de Formulário',
	formTabCards: 'Cartões de Abas de Formulário',
	formBlock: 'Bloco de Formulário',
	formBlockUuid: 'UUID do Bloco de Formulário',
	formBlocks: 'Blocos de Formulário',
	formBlockFields: 'Campos de Bloco de Formulário',
	noFieldsSet: 'Sem Campos',
	addField: 'Adicionar Campo',
	types: 'Tipos',
	subTypes: 'Sub-Tipos',
	modelsList: 'Lista de Modelos',
	noTabsSet: 'Sem Abas',
	errorCreatingFormBlock: 'Erro ao criar bloco de formulário',
	fieldType: 'Tipo de Campo',
	assetType: 'Tipo de ativo',
	assetTypeFilter: 'Filtro de Tipo de Ativo',
	assetSubTypeFilter: 'Filtro de Sub-tipo de Ativo',
	assetTypeUuid: 'UUID do tipo de ativo',
	assetTypes: 'Tipos de ativos',
	assetSubType: 'Sub-tipo de ativo',
	assetSubTypeUuid: 'UUID do sub-tipo de ativo',
	assetSubTypes: 'Sub-tipos de ativo',
	allowedOnRoot: 'Permitido na raiz',
	allowedParentTypes: 'Tipos de pais de activos permitidos',
	allowTagRepeatedOnSameType: 'Permitir repetição de tag no mesmo tipo',
	allowTagRepeatedOnChild: 'Permitir repetição de tag nos tipos filhos',
	parentSubType: 'Sub-tipo pai',
	parentSubTypeUuid: 'UUID sub-tipo pai',
	itself: 'O próprio',
	errorSelectAssetTypeFirst: 'Selecione um tipo de ativo primeiro.',
	errorCreatingAssetType: 'Erro ao criar tipo de ativo.',
	errorUpdatingAssetType: 'Erro ao atualizar o tipo de ativo.',
	errorCreatingAsset: 'Erro ao criar ativo.',
	errorUpdatingAsset: 'Erro ao atualizar o ativo.',
	errorCreatingSubAssetType: 'Erro ao criar sub-tipo de ativo.',
	errorUpdatingSubAssetType: 'Erro ao atualizar o sub-tipo de ativo.',
	updateWillCauseDataLoss: 'Alterações efetuadas neste atributo irão causar a perda das alterações não guardadas. Para evitar a sua perda, por favor, feche o seletor e guarde as alterações feitas antes de prosseguir.',
	useFormBlock: 'Usar Bloco de Formulário',
	privateBlock: 'Bloco Privado',
	parentAsset: 'Ativo Superior',
	assetInfo: 'Informação do Ativo',
	tagRules: 'Regras de Tag',
	defaultAssetType: 'Tipo de ativo por defeito',
	componentCode: 'Código do Componente',
	componentName: 'Nome do Componente',
	deleteMissingFormBlocks: 'Eliminar blocos de formulário que não estiverem no ficheiro.',
	deleteMissingFormFields: 'Eliminar campos de bloco de formulário que não estiverem no ficheiro.',
	errorMissingDocumentSheets: 'Folhas do documento estão em falta. Verifique o template do documento.',
	errorUpdatingFormBlockFieldsIndexesMixed: 'Erro ao atualizar os indexes dos campos de fomulário. Verifique a indexação dos campos de formulário vazios ou duplicados.',
	errorNameRequired: 'O nome é obrigatório.',
	errorFormBlockNotPresentOnDocument: 'Bloco de formulário não presente no documento.',
	errorCannotUpdateFieldOfNewFormBlock: 'Não é possível importar campos de bloco de formulário com UUID para um bloco de formulário novo.',
	errorInvalidFieldComponent: 'Componente de campo de formulário inválido.',

	// Repairs
	repairReport: 'Relatório de Reparação',
	management: 'Gestão',
	exportProposal: 'Exportar Proposta (Word)',
	exportProposalPDF: 'Exportar Proposta (PDF)',
	createRepair: 'Criar Reparação',
	newRepair: 'Nova Reparação',
	repair: 'Reparação',
	author: 'Autor',
	assetList: 'Lista de Assets',
	criticality: 'Criticidade',
	temporaryRepair: 'Reparação Temporária',
	definitiveRepair: 'Reparação Definitiva',
	repairCreated: 'Reparação Criada.',
	repairSubmitted: 'Reparação Submetida.',
	repairUpdated: 'Reparação Atualizada.',
	technicalDocuments: 'Documentos técnicos',
	waitingTechnicalInfo: 'Aguarda Informação Técnica',
	proposal: 'Proposta',
	proposals: 'Propostas',
	waitingValidation: 'Aguarda Validação',
	qualityAssurance: 'Controlo de Qualidade',
	validated: 'Validados',
	validation: 'Validação',
	finishedRepairs: 'Reparações Terminadas',
	repairProposal: 'Proposta de Reparação',
	proposalDocuments: 'Documentos da proposta',
	longevity: 'Longevidade',
	onGoing: 'A Decorrer',
	repairJob: 'Trabalho de reparação',
	repairOrder: 'Ordem de Trabalhos',
	proposalApproval: 'Aprovação de Proposta',
	waitingProposal: 'A Aguardar Proposta',
	waitingProposalApproval: 'A Aguardar Validação de Proposta',
	waitingRiskAssessment: 'A Aguardar Avaliação de Risco',
	veryLow: 'Muito Baixa',
	normal: 'Normal',
	veryHigh: 'Muito Alta',
	documentCode: 'Código de Documento',
	welder: 'Soldador',
	welding: 'Soldadura',
	weldingJob: 'Trabalho de Soldadura',
	weldings: 'Soldaduras',
	needsWelding: 'Envolve Soldadura',
	weldingCount: 'Número de Soldaduras',
	weldingDiameter: 'Diâmetro de Soldadura',
	weldingSchedule: 'Espessura de Soldadura',
	weldingDocuments: 'Documentos da Soldadura',
	weldingNotes: 'Notas da Soldadura',
	procedure: 'Procedimento',
	weldingProcedures: 'Procedimentos de Soldadura',
	weldingTypes: 'Tipos de Soldadura',
	weldingStandards: 'Normas de Soldadura',
	standards: 'Normas',
	procedures: 'Procedimentos',
	licensed: 'Licenciado',
	consumables: 'Consumíveis',
	weldNumber: 'Número de Soldadura',
	handleFluids: 'Envolve Fluídos (Classe 1, 2 ou 3)',
	riskAssessmentDocuments: 'Documentos de Avaliação de Risco',
	riskAssessmentNotes: 'Notas de Avaliação de Risco',
	billOfMaterials: 'Lista de Materiais',
	repairDeleted: 'Reparação Apagada',
	repairDeleteError: 'Erro ao tentar Apagar Reparação da base de dados. Tente novamente mais tarde. ({error})',
	repairsProgressDashTitle: 'Progesso das Reparações',
	repairsProgressDashSubTitle: 'Apresenta o número de reparações em cada um dos seus estados possíveis.',
	criticalityGraphTitle: 'Criticidade das Reparações',
	criticalityGraphSubTitle: 'Representa o número de reparações para cada nível de criticidade existente.',
	waitingApproval: 'A Aguardar Aprovação',
	repairExecution: 'Execução',
	block: 'Bloquear',
	blockedJustification: 'Justificação de Bloqueio',
	periodicInspections: 'Inspeções Periódicas',
	recurrentInspections: 'Inspeções Recorrentes',
	periodic: 'Periódico',
	periodicity: 'Periodicidade',
	periodicityRepetition: 'Repetição de Periodicidade',
	alertTime: 'Tempo prévio de alerta',
	requiresRecurrentInspections: 'Requer Inspeções Recorrentes',
	temporary: 'Temporária',
	definitive: 'Definitiva',
	nextInspection: 'Próxima Inspeção',
	assetName: 'Nome do ativo',
	assetTag: 'Tag do ativo',
	assetQR: 'QR do ativo',
	assetNFC: 'NFC do ativo',
	assetDescription: 'Descrição do ativo',
	repairRejected: 'Reparação Reprovada',
	jobPhotos: 'Fotos do trabalho',
	proposalCompany: 'Empresa a Propôr',
	repairUuid: 'UUID da Reparação',
	proposalNotes: 'Notas da Proposta',
	proposalApprovalNotes: 'Notas de Aprovação da Proposta',
	jobPictures: 'Imagens do trabalho de reparação',
	jobDocuments: 'Documentos do trabalho de reparação',
	jobNotes: 'Notas do trabalho de reparação',
	blockedDocuments: 'Documentos de Bloqueio',
	blockedNotes: 'Notas de Bloqueio',
	rejectionMessage: 'Mensagem de Rejeição',
	errorImportRepairs: 'Ocorreu um erro durante a importação de dados das reparações. O erro poderá ter sido causado por inconsistências nos dados. Por favor, verifique o seu ficheiro.',
	importedRepairsXlsxSuccessfully: 'Os dados foram importados com sucesso para a aplicação. Foram criados {imported} novas reparações, actualizadas {updated} reparações e não foi possivel importar {failed} reparações.',
	exportedReportsBulk: 'Foram exportados {success} relatórios com sucesso, falhou a exportação de {failed} relatórios.',
	removed: 'Removido',
	firstPeriodicInspectionError: 'A primeira inspeção aconteceria após a data de expiração da reparação.',
	nonDestructiveTest: 'Teste Não Destrutivo',
	nonDestructiveTests: 'Testes Não Destrutivos',
	repairType: 'Tipo de Reparação',
	fluids: 'Fluidos',
	fluidFamily: 'Família de fluidos',
	fluidType: 'Tipo de fluido',
	fluidTypes: 'Tipos de fluidos',

	// Repair Inspections
	nonCompliantRepair: 'Reparação Reprovada',
	repairInspectorAppreciation: 'Avaliação do Inspetor',
	finalAppreciation: 'Avaliação Final',
	repairInspection: 'Inspeção de Reparação',
	repairInspectionPhotos: 'Fotos da Inspeção de Reparação',
	repairInspectionReport: 'Relatório de Inspeção de Reparação',
	repairInspectionCreated: 'Inspeção de Reparação Criada',
	repairInspectionSubmitted: 'Inspeção de Reparação Submetida',
	repairInspectionUpdated: 'Inspeção de Reparação Atualizada',
	repairInspectionDeleted: 'Inspeção de Reparação Apagada',
	repairInspectionDeleteError: 'Erro ao tentar apagar inspeção de reparação da base de dados. Tente novamente mais tarde. ({error})',
	noInspectionsToShow: 'Nenhuma inspeção disponível na aplicação.',
	createNewInspection: 'Criar Inspeção',
	dueDate: 'Data Limite',
	executionDate: 'Data de Execução',

	// User
	editUser: 'Editar Utilizador',
	userName: 'Nome Utilizador',
	changePassword: 'Alterar Palavra-Passe',
	createUser: 'Criar Utilizador',
	userCreated: 'Criada nova conta de utilizador com sucesso. Informação enviada para o email indicado.',
	profile: 'Perfil',

	// Teams
	team: 'Equipa',
	teamName: 'Nome da Equipa',
	teamMembers: 'Membros da Equipa',
	teamCreated: 'Equipa criada com sucesso.',
	teamUpdate: 'Dados da equipa foram atualizados com sucesso.',
	parentTeam: 'Equipa Superior',

	// Companies
	parentCompany: 'Empresa Mãe',
	companyCreated: 'Empresa adicionada com sucesso',
	companyUpdate: 'Empresa atualizada com sucesso',
	defaultCompany: 'Empresa por defeito',

	// Sensors
	undefined: 'Indefinido',
	flow: 'Fluxo',
	flows: 'Fluxos',
	velocity: 'Velocidade',
	distance: 'Distância',
	impedance: 'Impedância',
	force: 'Força',
	mass: 'Massa',
	acceleration: 'Aceleração',
	energy: 'Energia',
	capacity: 'Capacidade',
	efficiency: 'Eficiência',
	temperature: 'Temperatura',
	pressure: 'Pressão',

	// Settings
	locale: 'Idioma',
	language: 'Idioma',
	showListPictures: 'Mostrar fotos em listagens',
	lazyLoading: 'Carregamento Progressivo',
	pictureMaxSize: 'Dimensão das fotos (px)',
	pictureFormat: 'Formato das fotos',
	mapStyle: 'Estilo do Mapa',
	vector: 'Vetorial',
	satellite: 'Satélite',
	mixed: 'Misto',
	dark: 'Escuro',
	light: 'Claro',
	keepExternalWindow: 'Reutilizar Tabs',
	keepFilters: 'Manter Filtros de Pesquisa',
	appearance: 'Aparência',
	preferences: 'Preferêcias',
	title: 'Título',
	icon: 'Icone',
	loginBackgroundImage: 'Imagem de fundo Login',
	logo: 'Logótipo',
	smallLogo: 'Logótipo Pequeno',
	topLogo: 'Logótipo Topo',
	bottomLogo: 'Logótipo Inferior',
	accentColor: 'Cor primária',
	settingsUpdated: 'Definições Atualizadas',

	// Menu
	assetPlanning: 'Planeamento do Ativo',
	privacyPolicy: 'Política de Privacidade',
	dashboard: 'Dashboard',
	functionalAnalysis: 'Análise Funcional',
	project: 'Projeto',
	companies: 'Empresas',
	users: 'Utilizadores',
	usersEmail: 'Utilizadores (Email)',
	usersSms: 'Utilizadores (SMS)',
	tasks: 'Tarefas',
	teams: 'Equipas',
	assets: 'Ativos',
	assetPortfolio: 'Portfólio de Ativos',
	actionPlan: 'Plano de Ação',
	atexActionPlan: 'Plano de Ação ATEX',
	repairs: 'Reparações',
	repairInspections: 'Inspeções de Reparações',
	inspections: 'Inspeções',
	atexInspections: 'Inspeções ATEX',
	settings: 'Definições',
	masterSettings: 'Definições Principais',
	importExport: 'Importar/Exportar',
	logs: 'Logs',
	intelligentDocuments: 'Documentos Inteligentes',
	digitalTwin: 'Digital Twin',

	// Success messages
	createdActionPlanTag: 'Criado plano de ação para o tag {tag} com sucesso.',
	addedSuccessfully: 'Dados adicionados com sucesso.',
	createdSuccessfully: 'Novo item foi adicionado com sucesso.',
	updatedSuccessfully: 'Os dados foram atualizados com sucesso.',
	updatedSuccessfullyName: 'Os dados de {name} foram actualizados com sucesso.',
	importSuccessful: 'Os dados foram importados com sucesso.',
	importedSuccessfullyCounters: 'Os dados foram importados com sucesso para a aplicação. Foram criados {imported} novos ativos, atualizados {updated} ativos e não foi possivel importar {failed} entradas.',
	deleteSuccessfully: 'Informação foi eliminada do servidor com sucesso.',
	deleteSuccessfullyName: '{name} eliminado da base de dados com sucesso.',
	deleteSuccessfullyCount: 'Removidas {count} entradas da base de dados com sucesso.',
	reportGeneratedSuccessfully: 'Relatório foi criado com sucesso. Procure nas suas transferências.',
	cameraSelected: 'Camara "{name}" selecionada.',
	restoreSuccessful: 'Os dados foram restaurados com sucesso.',

	// Confirm Messages
	confirmDelete: 'Tem a certeza que quer eliminar estes dados? Dados eliminados não podem ser recuperados.',
	updatedVersionFound: 'Uma nova versão da aplicação esta disponível. A aplicação irá ser atualizada para a nova versão.',
	confirmDeletePipeline: 'Ao desenhar uma nova tubagem, a tubagem existente será apagada. Pretende Continuar?',
	confirmDeleteEvent: 'Tem a certeza que quer eliminar este evento? Eventos eliminados não podem ser recuperados.',
	confirmInspectionValidation: 'Tem a certeza que pretende validar todas as inspeções selecionadas?',

	// Warning messages
	deviceOffline: 'Ligação à internet perdida. O dispositivo está offline.',
	deviceOnline: 'Ligação à internet restaurada. O dispositivo está online! ',
	loadingAssetsData: 'A carregar ativos',
	uploadingData: 'A enviar dados',
	processingData: 'A processar dados',
	importingData: 'Importados {imported} de {total}. {failed} falharam.',
	importingDataLines: 'Importados dados de {importedLines} linhas, de um total de {totalLines}.',
	deletingData: 'A eliminar dados',
	loadingData: 'A carregar dados.',
	loadingMoreData: 'A carregar mais dados...',
	nothingWasDeleted: 'Nada foi apagado.',

	// Error messages
	nothingToExport: 'Nada para exportar.',
	nothingUpdatedFFPNotFound: 'Não foi possível encontrar FFP para os parâmetros introduzidos. Verifique os dados e ficheiros selecionados.',
	requiredFieldsError: 'Campos obrigatórios têm de ser preenchidos.',
	assetRequiredFieldsError: 'Campos de ativo obrigatórios têm de ser preenchidos.',
	warningNoSensorSelected: 'Nenhum sensor selecionado. É necessário selecionar, pelo menos, um sensor para continuar.',
	errorAssetNotFound: 'Não foi possível encontrar o ativo na base de dados.',
	errorFileColumn: 'Ficheiro não contém nenhuma coluna com o nome "{attribute}". Verifique o ficheiro.',
	errorNoFile: 'Não foi selecionado nenhum ficheiro.',
	errorDeletingSomeFailed: 'Não foi possivel apagar {failed} entradas da base de dados.',
	errorProcessingSomeFailed: 'Não foi possivel processar todos os registos. Verique os erros no ficheiro exportado.',
	errorExportingQR: 'Erro durante exportação de código QR para ficheiro.',
	errorImport: 'Ocorreu um erro ao importar o ficheiro para a aplicação. Verifique se existem erros no ficheiro.',
	errorProcessingData: 'Ocurreu um erro ao processar os dados pedidos.',
	errorImportDetails: 'Ocorreu um erro ao importar o ficheiro para a aplicação. Verifique se existem erros no ficheiro. ({details})',
	errorImportAsset: 'Ocorreu erro durante a importação de dados dos ativos. O erro poderá ter sido causado por Tag replicado ou associações entre ativos inválida. Verifique o ficheiro.',
	errorExport: 'Ocorreu um erro durante a exportação do ficheiro.',
	errorExportPDFConfirmTryDOCX: 'Erro ao exportar dados para ficheiro PDF. Quer tentar em DOCX?',
	errorGeneratingQR: 'Erro durante a criação de código QR.',
	errorGeneratingReportDetails: 'Geração de relatório falhou. Um problema foi encontrado no modelo de dados. Detalhes acerca do erro ({details}).',
	errorConvertingReport: 'A conversão do relatório falhou. ({details})',
	errorEncodingData: 'Erro durante codificação de dados.',
	dataMalformed: 'Dados recebidos do servidor com formato inválido. Poderá ser causado por diferenças de versão do software.',
	dataUpdateError: 'Os dados não foram alterados com sucesso.',
	noPermission: 'O utilizador não tem permissão para executar esta ação.',
	noPermissionDetails: 'O utilizador não tem permissão para executar esta ação. ({details})',
	internalServerError: 'Erro interno do servidor. ({details})',
	badRequest: 'Erro nos dados enviados para o servidor. ({details})',
	cannotReachServer: 'Não é possivel contactar o servidor. Verifique a sua ligação à internet e tente novamente.',
	userSessionExpired: 'Sessão de utilizador expirou. Será necessário entrar na plataforma novamente.',
	noDataOnServer: 'Não há dados disponíveis no servidor!',
	errorGettingProjectFormHeaders: 'Erro ao pedir cabeçalhos do formulário para o project {projectName} ({projectUuid}) no passo {stepName} ({stepUuid})',
	errorGettingInspectionsForProject: 'Erro ao obter as inspeções para o projeto {projectName} ({projectUuid})',
	errorExportingInspectionForProject: 'Erro ao exportar inspeção {inspectionName} ({inspectionUuid}) do projeto {projectName} ({projectUuid})',
	errorsEncounteredDuringExport: 'Os seguintes erros foram encontrados durante a exportação: \n{errors}',
	errorDefaultWorkflowStepMissing: 'Um passo inicial deve ser selecionado para o fluxo de trabalho.',
	errorMultipleDefaultWorkflowSteps: 'Multiplos passos iniciais selecionados. Por favor, selecione apenas um.',
	errorMissingPipeline: 'Não existem dados para atualizar. Pontos devem ser adicionados à tubagem!',
	errorSnapTolerance: 'O elemento desenhado não está próximo de nenhuma tubagem!',
	errorImportAssetsInvalidType: 'O tipo de ativo fornecido é inválido.',
	errorImportAssetsMissingDefaultType: 'Um tipo de ativo por defeito ou um nome de tipo de ativo válido/UUID deve ser fornecido.',
	errorImportAssetsInvalidSubType: 'Sub-tipo de ativo fornecido é inválido.',
	errorImportAssetsInvalidSubtypeType: 'Sub-tipo de ativo fornecido não pertence ao tipo de ativo selecionado.',
	errorImportAssetsCreationUpdateFailed: 'A importação deste ativo falhou no servidor.',
	errorImportAssetsAtexCreationFailed: 'Falha na criação da inspeção Atex.',
	errorComputingInspectionAverageDetails: 'Erro ao calcular a média da inspeção. ({details})',
	errorImportAssetCreateFailed: 'Falha ao criar novo ativo.',
	errorImportAssetUpdateFailed: 'Falha ao atualizar os dados do ativo.',
	errorMultipleDefaultReports: 'Vários modelos de relatórios por defeito selecionados. Por favor, selecione apenas um.',

	// MOT
	mot: 'MOT',
	sensorManagement: 'Gestão de sensores',
	pipePlant: 'Planta das tubagens',
	frequency: 'Frequência',
	repetition: 'Repetição',
	waveChannels: 'Canais de onda',
	channels: 'Canais',
	propagationSpeed: 'Velocidade de propagação',
	locationPoint: 'Localização',
	segment: 'Segmento',
	cmp: 'CMP',
	lds: 'LDS',
	leakDetection: 'Deteção de Fugas',
	pipelines: 'Tubagens',
	acquisition: 'Aquisição',
	experiment: 'Ensaio',
	experiments: 'Ensaios',
	nanoFileImportedSuccessfully: 'O ficheiro .nano foi importado com sucesso.',
	feature: 'Feature',
	chooseElementModal: 'Escolha o elemento que pretende adicionar.',
	weld: 'Solda',
	support: 'Suporte',
	flange: 'Flange',
	teeBranch: 'Bifurcação',
	elbow: 'Cotovelo',
	brace: 'Braçadeira',
	defect: 'Defeito',
	indication: 'Indicação',
	valves: 'Válvulas',
	featureGeneric: 'Feature genérica',
	compare: 'Comparar',
	acquisitions: 'Aquisições',
	pickTwoAcquisitions: 'Selecione duas aquisições para comparação',
	pipelineIntegrity: 'Integridade da Pipeline',
	alarmDeleted: 'Este alarme foi eliminado',

	// DL50
	dl50: 'DL50',
	dl50Inspection: 'Inspeção DL50',
	validate: 'Validar',
	report: 'Relatório',
	representative: 'Representante',
	operator: 'Operador',
	authorizedOperator: 'Operador Autorizado',
	equipmentOperator: 'Operador do equipamento',
	owner: 'Proprietário',
	serviceHours: 'Horas de serviço',
	equipmentServiceHours: 'Horas de serviço do equipamento',
	certification: 'Certificação',
	certificationLocation: 'Localização da certificação',
	tests: 'Testes',
	hasElectricalTests: 'Tem testes elétricos',
	electricalTests: 'Testes elétricos',
	hasLoadTests: 'Tem testes de carga',
	loadTests: 'Testes de carga',
	finalNotes: 'Notas Finais',
	conclusion: 'Conclusão',
	safetyRequirements: 'Requisitos de segurança',
	evaluation: 'Avaliação',
	maximumProvisions: 'Disposições máximas',
	load: 'Carga',
	range: 'Alcance',
	spearLength: 'Comprimento de lança',
	height: 'Altura',
	testData: 'Dados de ensaio',
	dynamicTest: 'Ensaio dinâmico',
	loadTestOverload: 'Sobrecarga de:',
	loadTestStaticTest: 'Ensaio estático',
	loadTestFunctional: 'Ensaio Funcional',
	loadTestNoLoad: 'Sobrecarga de 0% / Sem Carga',
	loadTestMomentLimiter: 'Ensaio do limitador de momento',
	loadTestTenPercentOverloadAssessment: 'Sobrecarga máxima10% ',
	loadTestMaxLoadLimiter: 'Ensaio do limitador de carga máxima',
	groundCircuit: 'Circuito de terra',
	groundCircuitContinuity: 'Continuidade do circuito de terra de proteção',
	electricCurrent: 'Corrente intensidade elétrica de ensaio',
	differentialSwitches: 'Interruptores Diferenciais',
	differentialSwitchesOpenCircuitTimeHalf: 'Tempo de disparo para ½ x IΔn',
	differentialSwitchesOpenCircuitTimeOne: 'Tempo de disparo para 1 x IΔn',
	differentialSwitchesOpenCircuitTimeTwo: 'Tempo de disparo para 2 x IΔn',
	differentialSwitchesOpenCircuitTimeFive: 'Tempo de disparo para 5 x IΔn',
	insulationResistance: 'Resistência de isolamento',
	insulationResistanceR: 'R - PE',
	insulationResistanceS: 'S - PE',
	insulationResistanceT: 'T - PE',
	insulationResistanceN: 'N - PE',
	emission: 'Emissão',
	revision: 'Revisão',
	reviewer: 'Revisor',
	clientReview: 'Revisão do cliente',
	supervisionRejected: 'Rejeitado pela supervisão',
	errorCreatingInspection: 'Erro ao criar inspeção',
	errorUpdatingInspection: 'Error ao atualizar inspeção',
	errorUpdatingInspectionPermission: 'O utilizador não tem permissões para criar ou editar inspeções DL50.',
	gapsOk: 'Caso não existam não-conformidades',
	gapsLight: 'Caso existam não-conformidades - Leves',
	gapsSevere: 'Caso existam não-conformidades - Graves',
	gapsNok: 'Não apto',
	possibleGaps: 'Possíveis não-conformidades',
	legislationAndStandards: 'Legislação e referências normativas',
	dl50InspectionXlsx: 'Inspeção DL50 XLSX',
	dl50InspectionXlsxSub: 'Exporta um ficheiro XLSX com todas as Inspeções DL50 inseridas na platforma.',
	correctionDeadline: 'Prazo de correção',
	reportNumber: 'Número de relatórios',
	regulatoryStandard: 'Norma Regulamentar',
	emissionDate: 'Data de emissão',
	emissionLocation: 'Localização de emissão',
	counter: 'Contador',
	dl50InspectionStatusDashTitle: 'Progresso das Inspeções DL50',
	dl50InspectionStatusDashSubTitle: 'Inspeções DL50 por status.',
	dl50InspectionConclusionDashTitle: 'Resultados das inspeções DL50',
	dl50InspectionConclusionDashSubTitle: 'Inspeções DL50 por conclusão.',
	overload: 'Sobrecarga',
	confirmInspectionEvaluationInconsistency: 'As avaliações da inspeção não se enquadram com o valor da conclusão. Deseja prosseguir?',
  	confirmManyInspectionsInconsistentData: 'As avaliações das inspeções com os seguintes UUIDs não se enquadram com os valores de conclusão. Deseja prosseguir? {uuids}',
	duplicatedGapsLabels: 'Nome de Gaps duplicadas. Verifique o nome das possíveis Gaps da questão.',

	// RBAC
	rbac: 'RBAC',
	allowedRoles: 'Cargos com Acesso',
	effectiveRoles: 'Cargos Efetivos'
};
