import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {InspectionProjectService} from '../../../services/inspection-project.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'inspection-project-list-page',
	templateUrl: 'inspection-project-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		UnoContentComponent,
		NgStyle,
		UnoListComponent,
		TranslateModule,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent,
		PermissionsPipe
	]
})
export class InspectionProjectListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = InspectionProjectListPage;

	public permissions = [UserPermissions.INSPECTION_PROJECT];

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * Possible database filter to be used for ordering the inspection project list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[inspection_project].[name]',
			options: [
				{label: 'name', value: '[inspection_project].[name]'},
				{label: 'description', value: '[inspection_project].[description]'},
				{label: 'updatedAt', value: '[inspection_project].[updated_at]'},
				{label: 'createdAt', value: '[inspection_project].[created_at]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[inspection_project].[name]', '[inspection_project].[description]'],
			multiple: true,
			options: [
				{label: 'name', value: '[inspection_project].[name]'},
				{label: 'description', value: '[inspection_project].[description]'},
				{label: 'uuid', value: '[inspection_project].[id]'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: []
	}, InspectionProjectListPage.filterOptions);

	public static defaultFilters = structuredClone(InspectionProjectListPage.filters);

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 100;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: '[inspection_project].[name]'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'medium', sortBy: '[inspection_project].[description]'},
		{header: 'generatesGaps', type: UnoTableColumnType.STATUS, attribute: 'generatesGaps', visible: true, size: 'small'},
		{header: 'reports', type: UnoTableColumnType.NUMBER, attribute: 'reports', visible: true, size: 'small'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: Session.hasPermissions(UserPermissions.INSPECTION_PROJECT_EDIT),
			size: 'small',
			icons:
			[{
				src: './assets/icons/assets/edit-icon.svg',
				click: (row): void => {
					App.navigator.navigate('/menu/workflow/project/edit', {uuid: row.uuid});
				}
			},
			{
				src: './assets/icons/assets/expand-icon.svg',
				click: (row): void => {
					App.openInTab('/menu/workflow/project/edit', {uuid: row.uuid});
				}
			}]
		}
	];

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params = {
			from: count,
			count: pageSize,
			search: InspectionProjectListPage.filters.search,
			sortDirection: InspectionProjectListPage.filters.sortDirection,
			sortField: InspectionProjectListPage.filters.sortField,
			searchFields: InspectionProjectListPage.filters.searchFields
		};

		const list = await InspectionProjectService.list(params);
		const projects: any[] = list.projects;

		for (const project of projects) {
			project.reports = project.reports.length;
			project.generatesGaps = project.generatesGaps ? 'var(--success-normal)' : 'var(--gray-9)';
			project.actions = [];
		}

		return {
			elements: projects,
			hasMore: list.hasMore
		};
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('projects');

		this.tableTotalItemsCount = await InspectionProjectService.count(InspectionProjectListPage.filters);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		if (event.target === undefined) {
			InspectionProjectListPage.filters.search = event;
		}
		if (this.table) {
			await this.table.reset();
		}
		this.tableTotalItemsCount = await InspectionProjectService.count(InspectionProjectListPage.filters);
	}

	/**
	 * Update the filters and search term used.
	 *
	 * @param filters - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		InspectionProjectListPage.filters = filters;

		this.table.sortDirection = InspectionProjectListPage.filters.sortDirection;
		this.table.sortField = InspectionProjectListPage.filters.sortField;

		if (this.table) {
			await this.table.reset();
		}
		this.tableTotalItemsCount = await InspectionProjectService.count(InspectionProjectListPage.filters);
	}

	/**
	 * Navigate to the inspection form edit page.
	 *
	 * @param uuid - The UUID of the gap to open in new page.
	 */
	public async navigate(uuid): Promise<void> {
		await App.navigator.navigate('/menu/workflow/list/steps', {project: uuid});
	}

	/**
	 * When the table emits a value to change which column to sort by.
	 * 
	 * @param attribute - The attribute to sort by.
	 */
	public async sortChanged(attribute: string): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (attribute === InspectionProjectListPage.filters.sortField) {
			InspectionProjectListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			InspectionProjectListPage.filters.sortField = attribute;
			InspectionProjectListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}
}
