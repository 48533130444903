import {UUIDIdentification} from 'src/app/models/uuid';
import {DashboardWidget} from './widget';

/**
 * Declares the model of a dashboard.
 */
export class Dashboard extends UUIDIdentification {
	/**
	 * Name of the dashboard.
	 */
	public name: string;

	/**
	 * Description of the dashboard
	 */
	public description: string;

	/**
	 * Index of the dashboard in tabs
	 */
	public index: number;

	/**
	 * Flag that indicates if the dashboard is visible/pinned
	 */
	public pinned: boolean;

	/**
	 * The widgets that are a part of this dashboard
	 */
	public widgets: DashboardWidget[];

	/**
	 * Parse dashboard data from object.
	 *
	 * @param data - Data to be parsed into object.
	 */
	public static parse(data: any): Dashboard {
		const dashboard = new Dashboard();

		dashboard.uuid = data.uuid;
		dashboard.createdAt = new Date(data.createdAt);
		dashboard.updatedAt = new Date(data.updatedAt);

		dashboard.name = data.name;
		dashboard.description = data.description;
		dashboard.index = data.index;
		dashboard.pinned = data.pinned;
		dashboard.widgets = data.widgets;

		return dashboard;
	}
}

