<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Create button -->
		<uno-button icon="assets/icons/uno/add.svg" (click)="app.navigator.navigate('/menu/digital-twin/scene/edit', {createMode: true})">
			@if (app.device.isDesktop()) {
				{{'create' | translate}}
			}
		</uno-button>
		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onFilterChange($event)"></uno-searchbar>
	</div>

	<!-- Filters -->
	<div style="width: 100%; height: 80px; overflow-x: auto; overflow-y: hidden; white-space: nowrap;">
		<!-- Sort direction -->
		<div style="min-width: 100px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'direction' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="selfStatic.filters.sortDirection" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
				@for (value of selfStatic.sortDirectionValues; track value) {
					<ion-select-option [value]="value">{{value | translate}}</ion-select-option>
				}
			</ion-select>
		</div>

		<!-- Sort field -->
		<div style="min-width: 150px; display: inline-block; margin-right: 5px;">
			<ion-text style="font-size: 12px;">{{'sortField' | translate}}</ion-text>
			<ion-select (ionChange)="onFilterChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="selfStatic.filters.sortField" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
				@for (value of selfStatic.sortFieldValues; track value) {
					<ion-select-option [value]="value.attribute">{{value.label | translate}}</ion-select-option>
				}
			</ion-select>
		</div>
	</div>
</div>

<ng-template #itemTemplate let-scene="item">
	<uno-list-item (click)="app.navigator.navigate('/menu/digital-twin/editor', {uuid: scene.uuid});" style="height: 100px;">
		<uno-list-item-icon>
			<img style="height: 90px; width: 150px; object-fit: cover; border-radius: 10px;" src="{{settings.showListPictures ? ResourceUtils.getURL(scene.picture, './assets/digital-twin/placeholder.jpg') : './assets/digital-twin/placeholder.jpg'}}" />
		</uno-list-item-icon>

		<uno-list-item-label>
			<h2>{{scene.name}}</h2>
			@if (scene.description) {
				<h3>{{scene.description}}</h3>
			}
		</uno-list-item-label>

		<ion-icon (click)="app.navigator.navigate('/menu/digital-twin/scene/edit', {uuid: scene.uuid});" name="create-outline" slot="end"></ion-icon>
	</uno-list-item>
</ng-template>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-list [handler]="this.handler" [itemSize]="100" [itemTemplate]="itemTemplate"></uno-list>
</uno-content>
