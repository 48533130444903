import {cloneDeep} from 'lodash-es';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {AtexInspection} from 'src/app/models/atex-inspections/inspections/atex-inspection';
import {AtexInspectionStatus} from 'src/app/models/atex-inspections/inspections/atex-inspection-status';
import {AtexInspectionForm} from 'src/app/models/atex-inspections/inspections/atex-inspection-form';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {ActionPlanFormLayout} from '../action-plan-form-layout';
import {ActionPlan} from '../../../../../models/atex-inspections/action-plan/action-plan';
import {ActionPlanState} from '../../../../../models/atex-inspections/action-plan/action-plan-state';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {UUID} from '../../../../../models/uuid';
import {FFP} from '../../../../../models/atex-inspections/ffp/ffp';
import {AssetBaseLayout} from '../../../../asset-portfolio/screens/asset/asset-layout';
import {ObjectKeysPipe} from '../../../../../pipes/object-keys.pipe';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {AtexInspectionFieldsService} from '../../../inspections/services/atex-inspection-fields.service';
import {AtexInspectionService} from '../../../inspections/services/atex-inspection.service';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {FFPLayout} from '../../../ffp/screens/ffp-layout';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';
import {AtexInspectionLayouts} from '../../../inspections/screens/atex-inspection-layouts';
import {AtexInspectionChecklistService} from '../../../inspections/services/atex-inspection-checklist.service';
import {AtexInspectionUtils} from '../../../inspections/data/atex-inspection-utils';

type InspectionFormData = {
	/**
	 * The inspection object to be used on the forms.
	 */
	inspection?: AtexInspection;

	/**
	 * The form layout used for this inspection for the inspector.
	 */
	inspectorFormLayout?: UnoFormField[];

	/**
	 * The forms used to present this inspection' fields that originated the selected FFPs.
	 */
	inspectionFieldsFormLayout?: {[attr: UUID]: any[]};
}

/**
 * Page used to edit and create action plan entries, should receive the UUID of the action plan to edit or a createMode value set to true.
 *
 * When on creation mode can also receive a list of ffp (as array of UUID) to be included in the action plan on creation.
 */
@Component({
	selector: 'action-plan-edit-page',
	templateUrl: './action-plan-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoFormModule, UnoButtonComponent, UnoTitleComponent, IonicModule, TranslateModule, FormatDatePipe, ObjectKeysPipe, PermissionsPipe]
})
export class ActionPlanEditPage extends ScreenComponent implements OnInit {
	@ViewChild('mapContainer', {static: true})
	public mapContainer: ElementRef = null;

	@ViewChild('actionPlanForm', {static: false})
	public actionPlanForm: UnoFormComponent = null;

	@ViewChild('assetsTitleForm', {static: false})
	public assetsTitleForm: UnoFormComponent = null;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public app: any = App;

	public actionPlanLayout: any = ActionPlanFormLayout;

	public state: any = ActionPlanState;

	public assetsLayout: any = AssetBaseLayout;

	public inspectionStatus: any = AtexInspectionStatus;

	public atexInspectionLayouts: any = AtexInspectionLayouts;

	
	public ffpLayout: UnoFormField[] = [];
	
	public permissions = [UserPermissions.ACTION_PLAN];
	
	/** 
	 * Cache atex inspections checklist to prevent multiple requests of the same data.
	*/
	public atexChecklist: any = {};

	/**
	 * The atex fields data.
	 */
	public atexFields: {[attr: string]: any};

	/**
	 * Action plan being edited in this screen.
	 */
	public actionPlan: ActionPlan = null;

	/**
	 * Flag to indicate if the page is in create mode.
	 */
	public createMode: boolean = false;

	/**
	 * List of equipments that compose the action plan.
	 */
	public assets: {[attr: UUID]: APAsset} = {};

	/**
	 * Object of the FFP's that compose this action plan, used for reference and for the reinspection step.
	 */
	public ffps: {[attr: UUID]: FFP} = {};

	/**
	 * Inspections data and forms regarding the selected FFPs for this action plan organized by the inspection UUID as attribute.
	 */
	public inspectionFormDatas: {[attr: UUID]: InspectionFormData} = {};

	/**
	 * Inspection atex forms selection form.
	 */
	public inspectionSelectionFormLayout: UnoFormField[] = [];

	/**
	 * Edit history of the action plan.
	 */
	public history: any[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.assets = {};
		this.ffps = {};
		this.inspectionFormDatas = {};
		this.createMode = false;
		this.actionPlan = null;
		this.history = [];
		this.atexChecklist = null;

		App.navigator.setTitle('actionPlan');

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		[this.atexChecklist, this.atexFields] = await Promise.all([AtexInspectionChecklistService.get(), AtexInspectionFieldsService.get()]);
		
		this.buildInspectionSelectionForm();

		if (data.createMode === true) {
			this.createMode = true;
			this.actionPlan = new ActionPlan();
			if (data.ffpUuids !== undefined) {
				this.actionPlan.ffpUuids = data.ffpUuids;
			}
		} else {
			await this.loadData(data.uuid);
		}
	}

	/**
	 * Generate inspection forms selection form layout.
	 */
	public buildInspectionSelectionForm(): void {
		this.inspectionSelectionFormLayout = [{
			label: 'inspections',
			type: UnoFormFieldTypes.TITLE
		}];

		// Fill inspection selection
		for (const attr in this.atexChecklist) {
			this.inspectionSelectionFormLayout.push({
				label: Locale.get('inspection') + ' ' + this.atexChecklist[attr].name,
				attribute: 'data.inspections.' + attr,
				type: UnoFormFieldTypes.CHECKBOX
			});
		}
	}

	/**
	 * Get action plan data from the API.
	 *
	 * @param uuid - UUID of the action plan
	 */
	public async loadData(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.get, null, null, {uuid: uuid}, Session.session);
		this.actionPlan = ActionPlan.parse(request.response.actionPlan);
		await this.getDataFromFFPs();

		this.loadHistory(uuid);
	}

	/**
	 * Load the edit history from database.
	 */
	public async loadHistory(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.historyListUsers, null, null, {uuid: uuid}, Session.session);
		this.history = request.response.history;
	}

	/**
	 * Loads all the objects referenced by the selected FFPs.
	 * 
	 * Loads the inspections that originated the selected FFPs and the assets referenced by those inspections.
	 * 
	 * Builds the inspection and reinspection forms as well as the FFP form layouts.
	 */
	public async getDataFromFFPs(): Promise<void> {
		if (!this.actionPlan) {
			return;
		}

		// Get all FFP of the action plan
		let request = await Service.fetch(ServiceList.atex.ffp.getBatch, null, null, {uuid: this.actionPlan.ffpUuids}, Session.session);
		const ffps: FFP[] = request.response.ffp.map((data: any) => { return FFP.parse(data); });

		const assetUuids: UUID[] = [];
		for (const ffp of ffps) {
			const inspection: AtexInspection = await AtexInspectionService.get(ffp.inspection);
			assetUuids.push(inspection.assetUuid);

			this.inspectionFormDatas[inspection.uuid] = {
				inspectionFieldsFormLayout: {},
				inspection: inspection
			};
						
			this.ffps[ffp.uuid] = ffp;
		}

		request = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assetUuids}, Session.session);
		const assets = request.response.assets;
		for (let i = 0; i < assets.length; i++) {
			const asset = APAsset.parse(assets[i]);
			this.assets[asset.uuid] = asset;
		}

		for (const ffpUuid in this.ffps) {
			// @ts-ignore
			this.ffps[ffpUuid].asset = this.assets[
				this.inspectionFormDatas[this.ffps[ffpUuid].inspection].inspection.assetUuid
			];
		}

		this.ffpLayout = [{
			type: UnoFormFieldTypes.SUB_FORM,
			label: (object, row) => {return object.field + (object.asset?.tag?.length > 0 ? ' - ' + object.asset.tag : '');},
			expanded: false,
			fields: FFPLayout
		}];

		// Build the atex inspection reinspection field forms related to this Action Plan
		for (const k in this.ffps) {
			const ffp = this.ffps[k];
			
			const inspectionUuid = ffp.inspection;

			if (this.actionPlan.state === ActionPlanState.WAITING_REINSPECTION) {
				if (!this.inspectionFormDatas[inspectionUuid].inspectionFieldsFormLayout[ffp.form]) {
					this.inspectionFormDatas[inspectionUuid].inspectionFieldsFormLayout[ffp.form] = [];
				}

				const field: UnoFormField = structuredClone(this.atexFields[ffp.field]);
				field.type = UnoFormFieldTypes.ATEX_INSPECTION_FIELD;

				this.inspectionFormDatas[inspectionUuid].inspectionFieldsFormLayout[ffp.form].push(field);
			}
		}

		// Build the inspection forms related to this Action Plan
		for (const inspectionUuid in this.inspectionFormDatas) {
			this.inspectionFormDatas[inspectionUuid].inspectorFormLayout = [
				{
					type: UnoFormFieldTypes.SUB_FORM,
					attribute: 'inspection',
					label: (object, row) => { return object.uuid; },
					editable: false,
					fields: [
						...this.inspectionSelectionFormLayout,
						{
							type: UnoFormFieldTypes.SUB_FORM,
							label: 'inspectorForm',
							attribute: 'data.responses.inspector',
							fields: await AtexInspectionUtils.buildForm(this.inspectionFormDatas[inspectionUuid].inspection, AtexInspectionForm.INSPECTOR, this.assets[this.inspectionFormDatas[inspectionUuid].inspection.assetUuid]),
							editable: false
						},
						{
							type: UnoFormFieldTypes.SUB_FORM,
							label: 'backofficeForm',
							attribute: 'data.responses.backoffice',
							fields: await AtexInspectionUtils.buildForm(this.inspectionFormDatas[inspectionUuid].inspection, AtexInspectionForm.BACKOFFICE, this.assets[this.inspectionFormDatas[inspectionUuid].inspection.assetUuid]),
							editable: false
						},
						{
							type: UnoFormFieldTypes.SUB_FORM,
							label: 'result',
							fields: cloneDeep(AtexInspectionLayouts.inspection),
							editable: false
						}
					]
				}
			];
		}
	}

	/**
	 * Update the action plan in the API.
	 */
	public async update(state?: number, stayOnPage: boolean = false): Promise<void> {
		if (!this.actionPlanForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const actionPlan = structuredClone(this.actionPlan);
		if (state !== undefined) {
			actionPlan.state = state;
		}

		if (this.actionPlan.state === ActionPlanState.WAITING_REINSPECTION && Session.hasPermissions([UserPermissions.ATEX_INSPECTION])) {
			for (const data in this.inspectionFormDatas) {
				await Service.fetch(ServiceList.atex.inspection.update, null, null, structuredClone(this.inspectionFormDatas[data].inspection), Session.session);
			}
		}

		await Service.fetch(ServiceList.atex.actionPlan.update, null, null, actionPlan, Session.session);
		if (!stayOnPage) {
			App.navigator.pop();
		}
		Modal.toast(Locale.get('updatedSuccessfully'));

		// Every time an update occurs, there is an history change.
		this.loadHistory(this.actionPlan.uuid);
	}

	/**
	 * Delete the action plan from the API.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.atex.actionPlan.delete, null, null, {uuid: this.actionPlan.uuid}, Session.session);

			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Create a new action plan in the API.
	 */
	public async create(): Promise<void> {
		if (!this.actionPlanForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(ServiceList.atex.actionPlan.create, null, null, this.actionPlan, Session.session);

		Modal.toast(Locale.get('updatedSuccessfully'));
		App.navigator.pop();
	}

	/**
	 * Copy documents attached to the action plan into the equipment data.
	 *
	 * @param uuid - UUID of the equipment
	 */
	public async copyDocuments(uuid: UUID): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
			throw new Error('Use does not have permissions to copy documents.');
		}

		const asset = structuredClone(this.assets[uuid]);
		asset.documents = asset.documents.concat(this.actionPlan.data.documents);

		await Service.fetch(ServiceList.assetPortfolio.asset.update, null, null, asset, Session.session);
		Modal.toast(Locale.get('updatedSuccessfully'));
	}
}
