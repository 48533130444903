import {Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Locale} from 'src/app/locale/locale';
import {CssNgStyle} from 'src/app/utils/css-ng-style';
import {FormsModule} from '@angular/forms';
import {NgStyle, NgClass} from '@angular/common';
import {UnoIconComponent} from '../uno-icon/uno-icon.component';
import {UnoInputComponent} from '../../uno-input/uno-input/uno-input-component';

/**
 * UNO searchbar is a stylized text input element used to input search filter.
 */
@Component({
	selector: 'uno-searchbar',
	templateUrl: './uno-searchbar.component.html',
	styleUrls: ['./uno-searchbar.component.css'],
	host: {'(document:click)': 'this.focused ? this.toggleFocused($event) : null'},
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, NgClass, UnoInputComponent, FormsModule, UnoIconComponent]
})
export class UnoSearchbarComponent {
	/**
	 * Debounce time of the search element.
	 */
	@Input()
	public debounce: number = 250;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Value stored in the input component.
	 */
	@Input()
	public value: any = null;

	/**
	 * Event emitted when the value of the searchbar changes.
	 *
	 * Sends the new value of the search.
	 */
	@Output() 
	public valueChange: EventEmitter<string> = new EventEmitter<string>();

	/**
	 * Placeholder text to display when no text is visible.
	 */
	public placeholder: string = Locale.get('search');

	/**
	 * Wheter or not the component is focused
	 */
	public focused: boolean = false;

	/**
	 * Style of the text element.
	 */
	public style: CssNgStyle = {
		width: '100%',
		height: '34px',
		border: '0',
		'border-radius': '4px'
	};

	public constructor(private ref: ElementRef) { }

	/**
	 * Method called when the input changes, will retrieve options and fill the options list.
	 * 
	 * @param text - Text of the searchbar.
	 */
	public inputChange(text: string): void {
		this.value = text;
		this.valueChange.emit(text);
		this.focused = text !== '';
	}

	/**
	 * Method called to toggle the focused variable.
	 * 
	 * @param event - The event used to change the focused variable
	 */
	public toggleFocused(event): void {
		this.focused = this.ref.nativeElement.contains(event.target);
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
