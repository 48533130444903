<div class="uno-image-selector-container">
	<!-- Active -->
	@if (!this.disabled) {
		@if (this.multiple) {
			@if ($any(this.value)?.length > 0) {
				<div class="uno-image-selector-image-container">
					<div class="uno-image-selector-image-container-header">
						@if(!this.expandedImages()) {
							<div class="uno-image-selector-container-more-images-button" (click)="this.displayAllImages()">
								+{{this.numberOfExtraImgs()}} {{'images' | translate }}
						<uno-icon class="uno-image-selector-container-more-images-button-icon" src="./assets/components/options/up-specialarrow-icon.svg" color="primary" [width]="24" [height]="24"></uno-icon>
							</div>
						}
					</div>
					<div #imageContainer class="uno-image-selector-images-container">
						@for (resource of $any(this.displayedValue); track index; let index = $index) {
							<div class="uno-image-selector-image-div">
								<img (click)="this.viewImage(resource)" src="{{this.urls[index]}}" class="uno-image-selector-image" />
								<div class="uno-image-selector-image-delete" (click)="this.removeImage(resource)">
									<uno-icon [width]="25" [height]="25" [src]="'assets/icons/uno/close.svg'" [color]="'var(--gray-12)'"></uno-icon>
								</div>
							</div>
						}
					</div>
				</div>
			}
			}
			@else if(this.value) {
				<div class="uno-image-selector-image-container">
					<div class="uno-image-selector-image-container-header">
						<div class="uno-image-selector-image-div">
							<img (click)="this.viewImage($any(this.value))" src="{{this.urls[0]}}" class="uno-image-selector-image" />
							<div class="uno-image-selector-image-delete" (click)="this.removeImage($any(this.value))">
								<uno-icon [width]="25" [height]="25" [src]="'assets/icons/uno/close.svg'" [color]="'var(--gray-12)'"></uno-icon>
							</div>
						</div>
					</div>
				</div>
			}

			<!-- Add images -->
			@if (this.multiple || this.value === null) {
				<div #upload class="uno-image-selector-select-container" [ngClass]="{'uno-image-selector-container-drag': this.isDragging}" (click)="this.selectFiles()">
					@if(isDragging) {
						<div class="uno-image-selector-select-container-text" style="margin-top: 10px; margin-bottom: 11px;">
							<uno-icon src="assets/icons/uno/add.svg" color="primary" width="32" height="32"></uno-icon>
							<div>
								<span>{{'dropFile' | translate }}</span>
							</div>
						</div>
					} @else {
						<div class="uno-image-selector-select-container-text">
							<uno-icon src="assets/components/image-selector/upload-image-icon.svg" color="primary" width="32" height="32"></uno-icon>
							@if (app.device.isMobile()) {
								<div>
									<span class="uno-image-selector-choose-file">{{'chooseFile' | translate }}</span>
									<span>{{'or' | translate }}</span>
									<span class="uno-image-selector-choose-file">{{'openCamera' | translate }}</span>
									<span>{{'toUpload' | translate }}</span>
								</div>
							} @else {
								<div>
									<span>{{'dragAndDrop' | translate }}</span>
									<span>{{'or' | translate }}</span>
									<span class="uno-image-selector-choose-file">{{'chooseFile' | translate }}</span>
									<span>{{'toUpload' | translate }}</span>
								</div>
							}

						</div>
						<span class="uno-image-selector-container-sublabel">{{'imageFiles' | translate }}</span>
					}
				</div>
			}
			}
			<!-- Disabled -->
			@else {
				@if (this.multiple && this.value && $any(this.value)?.length > 0) {
					<div class="uno-image-selector-image-container">
						<div class="uno-image-selector-image-container-header">
							@if(!this.expandedImages()) {
								<div class="uno-image-selector-container-more-images-button" (click)="this.displayAllImages()">
									+{{this.numberOfExtraImgs()}} {{'images' | translate }}
							<uno-icon class="uno-image-selector-container-more-images-button-icon" src="./assets/components/options/up-specialarrow-icon.svg" color="primary" [width]="24" [height]="24"></uno-icon>
								</div>
							}
						</div>
						<div #imageContainer class="uno-image-selector-images-container">
							@for (resource of $any(this.displayedValue); track index; let index = $index) {
								<div class="uno-image-selector-image-div">
									<img (click)="this.viewImage(resource)" src="{{this.urls[index]}}" class="uno-image-selector-image" />
								</div>
							}
						</div>
					</div>
					} @else if(!this.multiple && this.value) {
						<div class="uno-image-selector-image-container">
							<div class="uno-image-selector-image-container-header">
								@if(!this.expandedImages()) {
									<div class="uno-image-selector-container-more-images-button" (click)="this.displayAllImages()">
										+{{this.numberOfExtraImgs()}} {{'images' | translate }}
								<uno-icon class="uno-image-selector-container-more-images-button-icon" src="./assets/components/options/up-specialarrow-icon.svg" color="primary" [width]="24" [height]="24"></uno-icon>
									</div>
									}
							</div>
							<div class="uno-image-selector-image-disabled">
								<img (click)="this.viewImage($any(this.value))" src="{{this.urls[0]}}" class="uno-image-selector-image-disabled" />
							</div>
						</div>
						} @else {
							<div class="uno-file-selector-select-container">
								<div class="uno-file-selector-select-container-text" style="cursor: not-allowed;">
									<uno-icon src="assets/components/image-selector/upload-image-icon.svg" color="primary" width="32" height="32"></uno-icon>
									<div>
										<span>{{'noDataOnServer' | translate }}</span>
									</div>
								</div>
							</div>
						}

						}
</div>
