<div style="height: fit-content; margin-top: 10px;">
	<!-- Search Bar -->
	@if (this.searchFilter !== null) {
		<uno-searchbar style="width: 100%;" [value]="this.filters[this.searchFilter]" (valueChange)="this.onSearchChange($event)"></uno-searchbar>
	}

	<!-- Filters -->
	<div style="display: flex; flex-direction: row; align-items: flex-start; width: 100%; height: 70px; gap: 5px; overflow-x: auto; overflow-y: hidden;">
		@for (f of this.options; track f) {
			@if (!f.enabled || f.enabled(this.filters)) {
				@if (f.type === filterType.OPTIONS) {
					<div style="display: flex; flex-direction: column; min-width: 80px; max-width: 200px;">
						<uno-text size="small">{{f.label | translate}}</uno-text>
						<ion-select [multiple]="f.multiple === true" (ngModelChange)="this.valueChange($event, f.attribute);" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.filters[f.attribute]" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" fill="outline">
							@for (o of f.options; track o) {
								<ion-select-option [value]="o.value">{{f.translate !== false ? (o.label | translate) : o.label}}</ion-select-option>
							}
						</ion-select>
					</div>
				}
				@else if (f.type === filterType.OPTIONS_LAZY) {
					<div style="display: flex; flex-direction: column; min-width: 80px; max-width: 200px;">
						<uno-text size="small">{{f.label | translate}}</uno-text>
						<uno-options-lazy (ngModelChange)="this.valueChange($event, f.attribute);" [translate]="f.translate !== false" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.filters[f.attribute]" [fetchOptionsBatch]="f.fetchOptionsBatch" [fetchOptionsLazy]="f.fetchOptionsLazy" [getOptionText]="f.getOptionText" [identifierAttribute]="f.identifierAttribute" [multiple]="f.multiple" [showClearButton]="true" [placeholder]="'all' | translate"></uno-options-lazy>
					</div>
				}
				@else if (f.type === filterType.BUTTON) {
					<div style="display: flex; min-width: 80px; max-width: 200px; height: 100%; flex-direction: column; justify-content: center;">
						<uno-button>{{f.label | translate}}</uno-button>
					</div>
				}
				@else if (f.type === filterType.CHECKBOX) {
					<div style="display: flex; flex-direction: column; min-width: 80px; max-width: 200px;">
						<uno-text size="small">{{f.label | translate}}</uno-text>
						<ion-checkbox (ngModelChange)="this.valueChange($event, f.attribute);" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.filters[f.attribute]" style="margin: 5px; vertical-align: middle;"></ion-checkbox>
					</div>
				}
			}
		}
		<ng-content></ng-content>
	</div>
</div>
