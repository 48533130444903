<ion-card>
	<ion-card-header>
		<ion-card-title>{{'dashInspectionPerformed' | translate}}</ion-card-title>
		<ion-card-subtitle>{{'dashInspectionPerformedSub' | translate}}</ion-card-subtitle>
		<div style="display: flex; justify-content:flex-end; width: 100%;">
			<uno-date-time (ngModelChange)="this.loadData();" [(ngModel)]="this.range" [isRange]="true" [parentSelectedDates]="this.startingDates" [ngModelOptions]="{standalone: true}"></uno-date-time>
		</div>
	</ion-card-header>
	<bar-chart [data]="this.data" [ngStyle]="{height: '300px'}" class="ion-padding"></bar-chart>
</ion-card>
