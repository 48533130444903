import {
	Component,
	ElementRef,
	forwardRef,
	Input,
	OnChanges, OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgClass, NgStyle} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CssNgStyle} from '../../../utils/css-ng-style';
import {ValidationUtils} from '../../../utils/validation-utils';
import {UnoTextComponent} from '../../uno/uno-text/uno-text.component';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {InputDebouncer} from './input-debouncer';
import {InputValidator} from './input-validator';

/**
 * Type of input element available.
 */
export const InputType = {
	TEXT: 'text',
	EMAIL: 'email',
	PASSWORD: 'password',
	NUMBER: 'number',
	URL: 'url'
};

/**
 * UNO input is a stylized wrapper around the native input element.
 */
@Component({
	selector: 'uno-input',
	templateUrl: './uno-input.component.html',
	styleUrls: ['./uno-input.component.css'],
	encapsulation: ViewEncapsulation.ShadowDom,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoInputComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [NgStyle, NgClass, UnoIconComponent, UnoTextComponent, TranslateModule]
})
export class UnoInputComponent implements OnChanges, OnInit, ControlValueAccessor {
	@ViewChild('input', {static: true})
	public input: ElementRef = null;

	/**
	 * Debouncing time in ms for change event to be triggered.
	 */
	@Input()
	public debounce: number = 100;

	/**
	 * Style to apply to the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Class to apply to the input element.
	 */
	@Input()
	public ngClass: NgClass = null;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Placeholder text to display when no text is visible.
	 */
	@Input()
	public placeholder: string = '';

	/**
	 * Type of the input element. Type of data select will change based on this value;
	 */
	@Input()
	public type: string = InputType.TEXT;

	/**
	 * Specifies whether the input element should have autocomplete enabled.
	 */
	@Input()
	public autocomplete: boolean = false;

	/**
	 * Validator regex expression.
	 *
	 * Used to highlight the input based on the value inserted.
	 */
	@Input()
	public validator: InputValidator = null;

	/**
	 * Input debouncer to prevent excess of input events.
	 */
	public debouncer: InputDebouncer;

	/**
	 * Value stored in the input component.
	 */
	public value: any = '';

	/**
	 * Flag to indicate if the password should be visible.
	 *
	 * Only used when type is set to password.
	 */
	public displayPassword: boolean = false;

	/**
	 * Style of the external container division.
	 */
	public containerStyle: CssNgStyle = {
		width: '320px',
		height: '40px',
		position: 'relative'
	};

	/**
	 * Style applied to the element.
	 */
	public inputStyle: CssNgStyle = {
		outline: 'none',
		borderStyle: 'none',
		boxSizing: 'border-box',
		textIndent: '16px',
		borderRadius: '4px',
		boxShadow: 'none',
		MozAppearance: 'textfield',
		webkitAppearance: 'caret',
		appearance: 'textfield',
		width: '100%',
		height: '100%',
		'border-radius': '4px',
		border: 'solid 1px var(--gray-8)',
		'font-family': 'Open Sans',
		'font-size': '14px',
		'font-weight': 'normal',
		'font-stretch': 'normal',
		'font-style': 'normal',
		'line-height': 'normal',
		'letter-spacing': 'normal',
		color: 'var(--light-gray-8)',
		'background-color': 'var(--light-gray-12)'
	};

	public constructor() {
		this.debouncer = new InputDebouncer(this.debounce, (value: any) => {
			this.updateValue(value);
		});
	}

	/**
	 * Toggle visibility of the input box content.
	 */
	public toggleVisibility(): void {
		if (this.type !== 'password') {
			throw new Error('Cannot toggle visibility if type is not "password".');
		}

		this.displayPassword = !this.displayPassword;
	}

	public ngOnInit(): void {
		this.input.nativeElement.oninput = (event: Event) => {
			this.debouncer.update(this.input.nativeElement.value);
		};
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.disabled) {
			if (this.disabled) {
				Object.assign(this.inputStyle, {
					'background-color': 'var(--light-gray-12)',
					color: 'var(--light-gray-8)',
					border: 'solid 1px var(--gray-8)'
				});
			}
		}

		if (changes.type) {
			if (this.type === InputType.EMAIL) {
				this.validator = new InputValidator(ValidationUtils.isEmail, 'invalidEmail');
			} else if (this.type === InputType.PASSWORD) {
				this.validator = new InputValidator(ValidationUtils.validPassword, 'passwordSizeError');
			}
		}

		if (changes.debounce) {
			this.debouncer.time = this.debounce;
		}

		if (changes.ngStyle) {
			Object.assign(this.containerStyle, this.ngStyle);
		}
	}

	/**
	 * Check if the content is valid.
	 */
	public valid(): boolean {
		if (this.validator) {
			return this.validator.validator(this.value);
		}

		return true;
	}

	public onChange: (value: any)=> void = function() {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void {}

	/**
	 * Validate the input value.
	 */
	public validate(): void {
		if (this.validator) {
			const test = this.validator.validator(this.value);
			Object.assign(this.inputStyle, {border: test ? 'solid 1px var(--success-normal)' : 'solid 1px var(--error-normal)'});
		}
	}

	/**
	 * Update the value of the input.
	 * 
	 * @param value - The value to be updated.
	 */
	public updateValue(value: any): void {
		this.writeValue(value);
		this.validate();
		this.onChange(this.value);
	}

	public writeValue(value: any): void {
		this.value = value;
		this.input.nativeElement.value = this.value;
		this.validate();
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
