import {NgClass} from '@angular/common';
import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DateFrequency, DateFrequencyPrecision} from 'src/app/models/date-frequency';


/**
 * The component either shows the minutes toggle or doesn't, set to hours by default
 */
export enum UnoDateFrequencyType {
	MINUTES = 'minutes',
	HOURS = 'hours',
	DAYS = 'days',
	MONTHS = 'months',
	YEARS = 'years'
}

@Component({
	selector: 'uno-date-frequency',
	templateUrl: './uno-date-frequency.component.html',
	styleUrls: ['./uno-date-frequency.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoDateFrequency; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FormsModule, TranslateModule, NgClass]
})

export class UnoDateFrequency implements ControlValueAccessor {
	public dateFrequencyPrecision: any = DateFrequencyPrecision;
	
	public dateFrequencyType: any = UnoDateFrequencyType;

	/**
	 * Controls if minutes button is shown
	 */
	@Input()
	public type: DateFrequencyPrecision;

	/**
	 * Value to update on the form
	 */
	public value: DateFrequency = null;

	/**
	 * Value for the frequency text input
	 */
	public frequencyInput: string = '';
	
	/**
	 * Selected frequency type
	 */
	public frequencyType: UnoDateFrequencyType = UnoDateFrequencyType.HOURS;

	/**
	 * Frequency value
	 */
	public frequency: number = 0;

	public onChange: (value: any)=> void = function() {};

	/**
	 * Select the frequency type
	 * 
	 * @param type - The selected type
	 */
	public selectFrequencyType(type: UnoDateFrequencyType): void {
		this.frequencyType = type;
		this.frequency = Number(this.frequencyInput);
		this.value = new DateFrequency();
		this.value[this.frequencyType] = this.frequency;
		this.writeValue(this.value, true);
	}

	/**
	 * When user changes the frequency value
	 * 
	 * @param frequency - Inputed frequency number
	 */
	public onFrequencyChange(frequency: string): void {
		this.frequency = Number(frequency);
		this.value[this.frequencyType] = this.frequency;
		this.writeValue(this.value, true);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void {}

	public writeValue(value: DateFrequency, edit: boolean = false): void {
		if (edit && value !== null) {
			this.onChange(value);
		} else if (value !== null) {
			this.value = value;
			// Verifies which value isn't 0 to change the input value and frequency type
			Object.entries(this.value)
				.filter(([, frequency]) => {return frequency !== 0;})
				.every(([key, frequency]) => {
					this.frequencyType = key as UnoDateFrequencyType;
					this.frequencyInput = frequency.toString();
					// Returns false on the first find, this is because it's the highest value
					return false; 
				});
			
			this.onChange(this.value);
		}
	}
}
