import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {AtexInspectionField} from '../../../models/atex-inspections/inspections/atex-inspection-field';
import {Locale} from '../../../locale/locale';
import {UnoFormField} from '../../uno-forms/uno-form/uno-form-field';
import {
	AtexInspectionFieldResult,
	AtexInspectionFieldResultLabel
} from '../../../models/atex-inspections/inspections/atex-inspection-field-result';
import {UnoImageSelectorComponent} from '../uno-image-selector/uno-image-selector.component';

@Component({
	selector: 'uno-atex-inspection-field',
	templateUrl: './uno-atex-inspection-field.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoAtexInspectionFieldComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [IonicModule, FormsModule, UnoImageSelectorComponent, TranslateModule]
})
export class UnoAtexInspectionFieldComponent implements ControlValueAccessor {
	public inspectionFieldLabel: any = AtexInspectionFieldResultLabel;

	public locale: any = Locale;

	public self: any = UnoAtexInspectionFieldComponent;

	/**
	 * Possible results values for the ATEX inspection field.
	 */
	public static resultValues: {label: string, value: number}[] = [
		{
			label: 'ok',
			value: AtexInspectionFieldResult.OK
		},
		{
			label: 'okRevised',
			value: AtexInspectionFieldResult.OK_REVISED
		},
		{
			label: 'nok',
			value: AtexInspectionFieldResult.NOK
		}
	];

	/**
	 * Row of the inspection form being edited in this field, used to fetch additional details.
	 *
	 * Name of the attribute used to be shown to the user. (A1, B1, B2, etc).
	 */
	@Input()
	public row: UnoFormField = null;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Value stored in this input component.
	 *
	 * Should be an Atex InspectionField value.
	 */
	public value: AtexInspectionField = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function() {};

	/**
	 * Set an attribute of the inspection field.
	 *
	 * @param attribute - Attribute name to be set.
	 * @param value - New value of the attribute.
	 */
	public setAttribute(attribute: string, value: any): any {
		this.value[attribute] = value;
		this.onChange(this.value);
	}

	/**
	 * Check if inspection value is empty.
	 */
	public isEmpty(): boolean {
		return !this.value.notApplicable && (this.value.result === AtexInspectionFieldResult.NONE || !this.value.result);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		this.onChange(this.value);
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
