<uno-tab>
	<uno-tab-section title="color" [active]="true">
		@for (section of this.colors; track section) {
			<div class="uno-h4">{{section.title}}</div>
			<div class="uno-color-palette-section">
				@for (color of section.colors; track color) {
					<div uno-tooltip uno-tooltip-text="{{color}}" class="uno-color-palette-box" [ngStyle]="{backgroundColor: 'var(' + color + ')'}"></div>
				}
			</div>
		}
	</uno-tab-section>
</uno-tab>
