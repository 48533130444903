import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoDateTimeComponent} from 'src/app/components/uno-input/uno-date-time/uno-date-time.component';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {DateRange} from '../../../../../../models/date-range';
import {BarChartData, BarChartComponent} from '../../../../../../components/charts/bar-chart/bar-chart.component';
@Component({
	selector: 'graph-atex-inspection-stats-updated',
	templateUrl: './atex-inspection-daily-updated.component.html',
	standalone: true,
	imports: [IonicModule, UnoDateTimeComponent, FormsModule, BarChartComponent, NgStyle, TranslateModule]
})
export class AtexInspectionDailyUpdatedComponent implements OnInit {
	/**
	 * Bar chart component.
	 */
	public data: BarChartData = null;

	/**
	 * Data range for data presented.
	 */
	public range: DateRange = new DateRange();

	/**
	 * Initial range of dates for the daily inspection
	 */
	public startingDates: Date[];

	public ngOnInit(): void {
		const from: any = new Date();
		from.setDate(from.getDate() - 7);

		this.range.set(from, new Date());

		this.startingDates = [from, new Date()];
		this.loadData();
	}

	public async loadData(): Promise<void> {
		const data = {
			from: this.range.from,
			to: this.range.to
		};

		const request = await Service.fetch(ServiceList.atex.inspection.countUpdated, null, null, data, Session.session, true);
		this.data = new BarChartData(request.response.values);
	}
}
