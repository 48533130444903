import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {Plant} from '../../../../../../models/pipeline-integrity/pipeline/plant';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {PlantListResponse, PlantsService} from '../../../../services/plant.service';

@Component({
	selector: 'plant-list-page',
	templateUrl: 'plant-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		TranslateModule,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent
	]
})

export class PlantListPage extends ScreenComponent implements OnInit {
	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public session: any = Session;

	public selfStatic: any = PlantListPage;

	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;
	
	/**
	 * List of plants to be displayed retrieved by the API.
	 */
	public plants: Plant[] = [];
	
	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('plants');

		await this.countTableItems();
	}

	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: 'name', tag: ListDisplayStyle.TITLE},
		{header: 'tag', type: UnoTableColumnType.TEXT, attribute: 'tag', visible: true, size: 'small', sortBy: 'tag', tag: ListDisplayStyle.TEXT},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small', sortBy: 'description', tag: ListDisplayStyle.TEXT},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons: [
				{
					src: './assets/icons/uno/list.svg',
					click: (row): void => {
						App.navigator.navigate('/menu/pipeline-integrity/pipeline/list', {plantUuid: row.uuid});
					}
				},
				{
					src: './assets/icons/assets/edit-icon.svg',
					click: (row): void => {
						App.navigator.navigate('/menu/pipeline-integrity/plant/edit', {uuid: row.uuid});
					}
				}
			]
		}

	];

	public loadMore = async(count: number, pageSize: number): Promise<any> => {
		const list: PlantListResponse = await PlantsService.list({
			sortField: PlantListPage.filters.sortField,
			sortDirection: PlantListPage.filters.sortDirection,
			search: PlantListPage.filters.search,
			searchFields: PlantListPage.filters.searchFields,
			offset: count,
			count: pageSize
		});

		return {
			elements: list.plants,
			hasMore: list.hasMore
		};
	};

	/**
	 * Possible database filter to be used for ordering the Plant list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'updated_at',
			options: [
				{label: 'updatedAt', value: 'updated_at'},
				{label: 'createdAt', value: 'created_at'},
				{label: 'name', value: 'name'},
				{label: 'tag', value: 'tag'},
				{label: 'description', value: 'description'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['name', 'description'],
			multiple: true,
			options: [
				{label: 'uuid', value: 'uuid'},
				{label: 'name', value: 'name'},
				{label: 'description', value: 'description'},
				{label: 'tag', value: 'tag'},
				{label: 'assetUuid', value: 'asset_uuid'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		 sortDirection: '',

		 /**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter cmps by their name, description or UUID.
		 */
		search: '',

		/**
		 * Search fields to be considered(name should match database column)
		 */
		 searchFields: []
	}, PlantListPage.filterOptions);

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		PlantListPage.filters = filters;

		this.table.sortDirection = PlantListPage.filters.sortDirection;
		this.table.sortField = PlantListPage.filters.sortField;

		if (this.table) {
			await this.countTableItems();
			await this.table.reset();
		}
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		PlantListPage.filters.search = event;

		if (this.table) {
			await this.countTableItems();
			await this.table.reset();
		}
	}

	/**
	 * Change the page's sorting.
	 * 
	 * @param sortBy - The attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === PlantListPage.filters.sortField) {
			PlantListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			PlantListPage.filters.sortField = sortBy;
			PlantListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Counts the table items and updates the table component.
	 */
	public async countTableItems(): Promise<void> {
		const params = {
			filters: {
				search: PlantListPage.filters.search,
				searchFields: PlantListPage.filters.searchFields
			}
		};

		this.totalItems = await PlantsService.count(params);
	}
}
