import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {App} from 'src/app/app';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {Session} from 'src/app/session';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {SortDirection} from 'src/app/utils/sort-direction';
import {DashboardPinnedModes} from 'src/app/models/dashboards/dashboard-pinned-modes';
import {UUID} from 'src/app/models/uuid';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {Dashboard} from 'src/app/models/dashboards/dashboard';
import {UnoFilterBarComponent} from '../../../../components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {DashboardListParams, DashboardService} from '../../services/dashboard.service';

@Component({
	selector: 'dashboard-list-page',
	templateUrl: 'dashboard-list.page.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		UnoFilterBarComponent,
		UnoSearchbarComponent,
		UnoResponsiveTableListComponent
	]
})
export class DashboardListPage extends ScreenComponent implements OnInit {
	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app = App;

	public selfStatic = DashboardListPage;

	public session = Session;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 30;

	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 30;

	/**
	 * The rows that are checked in the table
	 */
	public checkedTableRows: number[] = [];

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', tag: ListDisplayStyle.TITLE, sortBy: 'dashboard.name'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small', tag: ListDisplayStyle.LABEL, sortBy: 'dashboard.description'},
		{
			header: 'pinned',
			type: UnoTableColumnType.ICONS,
			attribute: 'pinned',
			visible: true,
			size: 'small',
			sortBy: 'dashboard.pinned',
			iconNumber: 2,
			icons: [{
				src: './assets/dashboards/pin-icon.svg',
				iconColor: (row): string => {
					return row.pinned ? 'var(--brand-primary)' : 'var(--gray-10)';
				},
				click: async(row): Promise<void> => {
					await this.pinDashboard(row);
				}
			}]
		},
		{header: 'createdAt', type: UnoTableColumnType.DATE, attribute: 'createdAt', visible: true, size: 'small', tag: ListDisplayStyle.TEXT, sortBy: 'dashboard.created_at'},
		{header: 'updatedAt', type: UnoTableColumnType.DATE, attribute: 'updatedAt', visible: true, size: 'small', tag: ListDisplayStyle.TEXT, sortBy: 'dashboard.updated_at'}
	];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('dashboard');

		await this.countTable();

		const actions = [];
		if (Session.hasPermissions(UserPermissions.DASHBOARDS_EDIT)) {
			actions.push({
				src: './assets/icons/assets/edit-icon.svg',
				click: (row): void => {
					App.navigator.navigate('/menu/dash-boards/edit', {uuid: row.uuid});
				}
			});
		}

		if (Session.hasPermissions(UserPermissions.DASHBOARDS_DELETE)) {
			actions.push({
				src: './assets/icons/uno/bin.svg',
				click: (row): void => {
					this.deleteDashboard(row.uuid);
				}
			});
		}

		if (actions.length > 0) {
			this.tableLayout.push({
				header: 'actions',
				type: UnoTableColumnType.ICONS,
				attribute: 'actions',
				visible: true,
				size: 'small',
				icons: actions
			});
		}
	}

	/**
	 * Possible database filter to be used for ordering the inspection workflow list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'dashboard.updated_at',
			options: [
				{label: 'name', value: 'dashboard.name'},
				{label: 'createdAt', value: 'dashboard.created_at'},
				{label: 'updatedAt', value: 'dashboard.updated_at'},
				{label: 'description', value: 'dashboard.description'},
				{label: 'pinned', value: 'dashboard.pinned'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'pinned',
			label: 'pinned',
			default: DashboardPinnedModes.DASHBOARDS_ALL,
			options: [
				{label: 'all', value: DashboardPinnedModes.DASHBOARDS_ALL},
				{label: 'pinned', value: DashboardPinnedModes.DASHBOARDS_PINNED},
				{label: 'notPinned', value: DashboardPinnedModes.DASHBOARDS_NOT_PINNED}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter items by.
		 */
		search: '',

		/**
		 * Attribute used to sort the items on the list.
		 */
		sortField: '',

		/**
		 * Sort direction applied to the list.
		 */
		sortDirection: SortDirection.ASC,

		/**
		 * Search only for pinned dashboards
		 */
		pinned: DashboardPinnedModes.DASHBOARDS_ALL
	}, DashboardListPage.filterOptions);

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: DashboardListParams = {
			from: count,
			count: pageSize,
			search: DashboardListPage.filters.search,
			searchFields: DashboardListPage.filters.searchFields,
			sortField: DashboardListPage.filters.sortField,
			sortDirection: DashboardListPage.filters.sortDirection,
			pinned: DashboardListPage.filters.pinned
		};

		const list = await DashboardService.list(params);
		const dashboards: any[] = list.dashboards;

		for (const dashboard of dashboards) {
			dashboard.pinnedState = dashboard.pinned ? 'var(--success-normal)' : 'var(--gray-10)';
		}

		return {
			elements: dashboards,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value.
	 */
	public async onSearchChange(search: string): Promise<void> {
		DashboardListPage.filters.search = search;
		await this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		DashboardListPage.filters = filters;
		await this.reset();
	}

	/**
	 * When the user changes the sort using the table.
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === DashboardListPage.filters.sortField) {
			DashboardListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			DashboardListPage.filters.sortField = sortBy;
			DashboardListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	public async countTable(): Promise<void> {
		const params: DashboardListParams = {
			search: DashboardListPage.filters.search,
			searchFields: DashboardListPage.filters.searchFields,
			pinned: DashboardListPage.filters.pinned
		};

		this.tableTotalItemsCount = await DashboardService.count(params);

	}

	/**
	 * Reset the table.
	 */
	public async reset(): Promise<void> {
		this.table.sortDirection = DashboardListPage.filters.sortDirection;
		this.table.sortField = DashboardListPage.filters.sortField;


		if (this.table) {
			await this.countTable();
			await this.table.reset();
		}
	}

	/**
	 * Delete a dashboard after confirmation.
	 * 
	 * @param dashboardUuid - UUID of the dashboard to delete
	 */
	public async deleteDashboard(dashboardUuid: UUID): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('delete'), Locale.get('confirmDelete'), ['yes', 'no']);
		if (confirm) {
			await DashboardService.delete(dashboardUuid);
			Modal.toast(Locale.get('deleteSuccessfully'));

			await this.reset();
		}
	}

	/**
	 * Pin a dashboard.
	 * 
	 * @param dashboard - The dashboard to pin.
	 */
	public async pinDashboard(dashboard: Dashboard): Promise<void> {
		dashboard.pinned = !dashboard.pinned;

		const dash = await DashboardService.get(dashboard.uuid);
		dash.pinned = dashboard.pinned;
		await DashboardService.update(dash);
	}
}
