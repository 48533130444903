import {Component, Input, ViewEncapsulation, forwardRef, EventEmitter, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgClass} from '@angular/common';


import {UnoOptionsList} from 'src/app/components/uno/uno-options-list/uno-options-list.component';
import {
	AtexAmbient,
	AtexAmbientKeys,
	AtexAmbientLabels,
	AtexCategory,
	AtexCategoryKeys,
	AtexCategoryLabels,
	AtexEpl,
	AtexEplKeys,
	AtexEplLabels,
	AtexExplosionGroups,
	AtexExplosionGroupsKeys,
	AtexExplosionGroupsLabels,
	AtexGroup,
	AtexGroupKeys,
	AtexGroupLabels,
	AtexProtections,
	AtexProtectionsKeys,
	AtexProtectionsLabels,
	AtexTemperature,
	AtexTemperatureKeys,
	IPRatingDust,
	IPRatingDustKeys,
	IPRatingDustLabels,
	IPRatingWater,
	IPRatingWaterKeys,
	IPRatingWaterLabels
} from '../../../../models/atex/atex-enums';
import {App} from '../../../../app';
import {AtexTag} from '../../../../models/atex/atex';
import {UnoIconComponent} from '../../../uno/uno-icon/uno-icon.component';

@Component({
	selector: 'uno-atex-tag',
	templateUrl: './uno-atex-tag.component.html',
	styleUrls: ['./uno-atex-tag.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoAtexTagComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, FormsModule, TranslateModule, UnoIconComponent, UnoOptionsList, NgClass]
})
export class UnoAtexTagComponent implements ControlValueAccessor {
	public atexGroup: any = AtexGroup;

	public atexGroupKeys: string[] = AtexGroupKeys;

	public atexGroupLabels: Map<any, any> = AtexGroupLabels;

	public atexCategory: any = AtexCategory;

	public atexCategoryKeys: string[] = AtexCategoryKeys;

	public atexCategoryLabels: Map<any, any> = AtexCategoryLabels;

	public atexEpl: any = AtexEpl;

	public atexEplKeys: string[] = AtexEplKeys;

	public atexEplLabels: Map<any, any> = AtexEplLabels;

	public atexProtections: any = AtexProtections;

	public get atexProtectionsKeysNotDust(): string[] { return AtexProtectionsKeys.filter((key: string) => {return !key.endsWith('D');}); }

	public get atexProtectionsKeysDust(): string[] { return AtexProtectionsKeys.filter((key: string) => {return key.endsWith('D');}); }

	public atexProtectionsLabels: Map<any, any> = AtexProtectionsLabels;

	public atexExplosionGroups: any = AtexExplosionGroups;

	public atexExplosionGroupsKeys: string[] = AtexExplosionGroupsKeys;

	public atexExplosionGroupsLabels: Map<any, any> = AtexExplosionGroupsLabels;

	public atexTemperature: any = AtexTemperature;

	public atexTemperatureKeys: string[] = AtexTemperatureKeys;

	public atexAmbient: any = AtexAmbient;

	public atexAmbientKeys: string[] = AtexAmbientKeys;

	public atexAmbientLabels: Map<any, any> = AtexAmbientLabels;

	public ipRatingDust: any = IPRatingDust;

	public ipRatingDustKeys: string[] = IPRatingDustKeys;

	public ipRatingDustLabels: Map<any, any> = IPRatingDustLabels;

	public ipRatingWater: any = IPRatingWater;

	public ipRatingWaterKeys: string[] = IPRatingWaterKeys;

	public ipRatingWaterLabels: Map<any, any> = IPRatingWaterLabels;

	public app: any = App;

	public atexGroupOptions = this.arrangeOptions(this.atexGroupKeys, this.atexGroup, this.atexGroupLabels);

	public atexCategoryOptions = this.arrangeOptions(this.atexCategoryKeys, this.atexCategory, this.atexCategoryLabels);
	
	public atexAmbientOptions = this.arrangeOptions(this.atexAmbientKeys, this.atexAmbient, this.atexAmbientLabels);

	public atexExplosionOptions = this.arrangeOptions(this.atexExplosionGroupsKeys, this.atexExplosionGroups, this.atexExplosionGroupsLabels);
	
	public atexEplOptions = this.arrangeOptions(this.atexEplKeys, this.atexEpl, this.atexEplLabels);

	public atexTemperatureOptions = this.arrangeOptions(this.atexTemperatureKeys, this.atexTemperature);

	public ipxDustOptions = this.arrangeOptions(this.ipRatingDustKeys, this.ipRatingDust, this.ipRatingDustLabels);

	public ipxWaterOptions = this.arrangeOptions(this.ipRatingWaterKeys, this.ipRatingWater, this.ipRatingWaterLabels);

	/**
	 * Event to request the AtexTagGroup to delete the entry.
	 */
	@Output()
	public onDelete: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * Event to request the AtexTagGroup to update its value
	 */
	@Output()
	public onUpdateGroup: EventEmitter<AtexTag> = new EventEmitter<AtexTag>();

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Atex stored in this input component.
	 */
	public value: AtexTag = new AtexTag();

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function() {};

	/**
	 * Check if the value is a valid Atex tag.
	 */
	public isValid(): boolean {
		return this.value && !(this.value.group === AtexGroup.UNKNOWN && this.value.category === AtexCategory.UNKNOWN && this.value.ambient === AtexAmbient.UNKNOWN);
	}

	/**
	 * Arranges the options so they can be displayed
	 * 
	 * @param keys - Option keys
	 * @param value - Option value
	 * @param labels - Option labels
	 * @returns the options in a way uno-options-list can interpret 
	 */
	public arrangeOptions(keys: string[], value: number[], labels?: Map<any, any>): {value: number, label: string}[] {
		const options = [];
		for (const key of keys) {
			options.push({value: value[key], label: labels ? labels.get(value[key]) : key});
		}

		return options;
	}

	/**
	 * Set a attribute of the atex object by its name.
	 *
	 * @param attribute - Attribute to be changed.
	 * @param value - New value.
	 */
	public setAttribute(attribute: string, value: any): any {
		if (!this.value) {
			throw new Error('Value is not of the type AtexTag.');
		}

		this.value[attribute] = value;
		this.onChange(this.value);
		this.onUpdateGroup.emit();
	}

	/**
	 * Remove this item from the list of tags.
	 */
	public removeItem(): any {
		this.onDelete.emit();
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: AtexTag): void {
		this.value = value;
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
