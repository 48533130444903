import {Component, ViewEncapsulation, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, FormsModule, ControlValueAccessor} from '@angular/forms';
import {NgClass} from '@angular/common';


@Component({
	selector: 'uno-text-mutiline',
	templateUrl: './uno-text-multiline.component.html',
	styleUrls: ['./uno-text-multiline.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoTextMultilineComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [FormsModule, NgClass]
})
export class UnoTextMultilineComponent implements ControlValueAccessor {
	/**
	 * Saved text value
	 */
	public value: string = null;

	/**
	 * If the textarea is or isn't disabled
	 */
	public disabled: boolean = false;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function() {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	/**
	 * Update the value, setting the new value and calling the onChange callback.
	 * 
	 * @param event - New Value of the component.
	 */
	public updateValue(event: Event): void {
		this.value = (event.target as HTMLTextAreaElement).value;
		this.onChange((event.target as HTMLTextAreaElement).value);
	}

	public writeValue(value: any): void {
		this.value = value;
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
    
}
