<uno-content [ngStyle]="{'z-index': 1}" [borders]="true">
	<div class="asset-info-topbar">
		<span style="font-size: 18px; font-weight: 600; line-height: 24px">{{'assetInfo' | translate}}</span>
		<uno-icon [width]="32" [height]="32" color="primary" src="assets/icons/uno/close.svg" (click)="this.closeModal(true);"></uno-icon>
	</div>
	<div class="asset-info-data-container">
		<img loading="lazy" class="asset-info-image" [src]="this.getImage()" />

		<div class="asset-info-text-container">
			<span style="color:var(--gray-8)">{{'assetTag' | translate}}</span>
			<span>{{this.asset.tag}}</span>
		</div>

		<div class="asset-info-text-container">
			<span style="color:var(--gray-8)">{{'assetName' | translate}}</span>
			<span>{{this.asset.name}}</span>
		</div>
	</div>

	<div class="asset-info-button-container">
		@if([permissions.REPAIR_CREATE] | hasPermissions) {
			<uno-button style="width: 250px" [expand]="true" [type]="this.type.BORDER" (click)="this.closeModal();app.navigator.navigate('/menu/repairs/works/edit', {createMode: true, asset: this.asset.uuid})">{{'createRepair' | translate}}</uno-button>
		}
		@if([permissions.INSPECTION_CREATE] | hasPermissions) {
			<uno-button style="width: 250px" [expand]="true" [type]="this.type.BORDER" (click)="this.closeModal();this.createInspection()">{{'createWorkflow' | translate}}</uno-button>
		}
		@if([permissions.ATEX_INSPECTION_CREATE] | hasPermissions) {
			<uno-button style="width: 250px" [expand]="true" [type]="this.type.BORDER" (click)="this.closeModal();this.createAtexInspection()">{{'createAtexInspection' | translate}}</uno-button>
		}
		@if([permissions.CALENDAR_EVENT] | hasPermissions) {
			<uno-button style="width: 250px" [expand]="true" [type]="this.type.BORDER" (click)="this.openCalendarModal()">{{'assetPlanning' | translate}}</uno-button>
		}
		@if([permissions.ASSET_PORTFOLIO] | hasPermissions) {
			<uno-button style="width: 250px" [expand]="true" [type]="this.type.FILL" (click)="this.closeModal();this.asset ? app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.asset.uuid}) : null" [icon]="'./assets/icons/assets/expand-icon.svg'">{{'viewAsset' | translate}}</uno-button>
		}
	</div>
</uno-content>
