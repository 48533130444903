import {Pipe, PipeTransform} from '@angular/core';
import {FileExtensionUtils} from 'src/app/utils/file-extension-utils';

/**
 * Pipe to get the icon of a file extension.
 */
@Pipe({
	standalone: true,
	name: 'extensionIcon'
})
export class ExtensionIconPipe implements PipeTransform {
	public transform(format: string): string {
		if (FileExtensionUtils.isOffice(format) || FileExtensionUtils.isImage(format) || FileExtensionUtils.isPDF(format)) {
			return `assets/icons/file-formats/${format}-file-icon.svg`;
		} 
    
		return 'assets/icons/file-formats/document-icon.svg';
	}
}
