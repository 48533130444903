import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardListPage} from './screens/list/dashboard-list.page';
import {DashboardEditPage} from './screens/edit/dashboard-edit.page';

const routes: Routes = [
	{
		path: '',
		component: DashboardListPage
	},
	{
		path: 'edit',
		component: DashboardEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardsRouterModule { }
