import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf} from '@angular/cdk/scrolling';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {App} from '../../../../../app';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Locale} from '../../../../../locale/locale';
import {Modal} from '../../../../../modal';
import {FFPRecommendation} from '../../../../../models/atex-inspections/ffp/ffp-recommendation';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {FFPListMode, FFPListModeLabel, FFPState, FFPStateLabel} from '../../../../../models/atex-inspections/ffp/ffp-state';
import {FFP} from '../../../../../models/atex-inspections/ffp/ffp';
import {ObjectKeysPipe} from '../../../../../pipes/object-keys.pipe';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {AtexInspectionFieldsService} from '../../../inspections/services/atex-inspection-fields.service';
import {AtexFfpService, AtexFfpListParams, AtexFfpCountParams} from '../../services/atex-ffp.service';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

/**
 * List of possible options to filter the FFP by its association to action plans.
 */
export const FFPActionPlanFilter: any = {
	ALL: 0,
	HAS_ACTION_PLAN: 1,
	NO_ACTION_PLAN: 2
};

/**
 * Possible values for the filter ffp based on recommendations.
 */
export enum FFPRecommendationFilter {
	// Do not filter FFPs by recommendations
	ALL = 0,
	// Filters FPPs with recommendations
	HAS_RECOMMENDATION = 1,
	// Filters FPPs without recommendations
	NO_RECOMMENDATION = 2
};

@Component({
	selector: 'ffp-list-page',
	templateUrl: 'ffp-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoButtonComponent,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		UnoListItemComponent,
		UnoListItemLabelComponent,
		TranslateModule,
		ObjectKeysPipe,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent,
		PermissionsPipe
	]
})
export class FFPListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public settings = Session.settings;

	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public selfStatic: any = FFPListPage;

	public permissions = [UserPermissions.FFP];

	/**
	 * Possible sort direction values.
	 */
	public static sortDirectionValues: any = [SortDirection.ASC, SortDirection.DESC];

	/**
	 * Possible table values to show to the user on mobile.
	 */
	public tableFieldsColumns: {
		[key: string]: {
			label: string,
			get: (item: any)=> string,
			style: {[key: string]: any},
		} 
	} = {
			assetName: {
				label: 'asset',
				get: (opt) => {
					return opt.asset.name + ' (' + (opt.asset.tag || Locale.get('nd')) + ')';
				},
				style: {}
			},
			field: {
				label: 'field',
				get: (opt) => {return opt.field; },
				style: {'max-width': '70px'}
			},
			form: {
				label: 'form',
				get: (opt) => {return Locale.get(opt.form); },
				style: {}
			},
			cost: {
				label: 'cost',
				get: (opt) => {return opt.cost + '€'; },
				style: {'max-width': '100px'}
			},
			actionPlansCount: {
				label: 'actionPlans',
				get: (opt) => {return opt.actionPlansCount; },
				style: {'max-width': '100px'}
			},
			parentAssetName: {
				label: 'parentAsset',
				get: (opt) => {
					if (opt.parentAsset) {
						return opt.parentAsset.name + ' (' + (opt.parentAsset.tag || Locale.get('nd')) + ')';
					} else {
						return Locale.get('nd');
					}
				},
				style: {}
			},
			fieldDetails: {
				label: 'field',
				get: (opt) => {return this.inspectionFields[opt.field].label; },
				style: {}
			}
		};

	/**
	 * List of FFP's selected from the list.
	 */
	public selection: any[] = [];

	/**
	 * List of inspection fields retrieved from master data.
	 */
	public inspectionFields = null;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 0;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * The rows that are checked in the table
	 */
	public checkedTableRows: number[] = [];

	/**
	 * Possible database filter to be used for ordering the ffp list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[atex_inspection].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[atex_inspection].[updated_at]'},
				{label: 'createdAt', value: '[atex_inspection].[created_at]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[ap_asset].[name]', '[ap_asset].[tag]', '[ap_asset].[description]', '[parent_asset].[name]', '[atex_inspection].[id]', '[atex_ffp].[field]'],
			options: [
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'description', value: '[ap_asset].[description]'},
				{label: 'parentUuid', value: '[parent_asset].[id]'},
				{label: 'parentName', value: '[parent_asset].[name]'},
				{label: 'inspectionUuid', value: '[atex_inspection].[id]'},
				{label: 'field', value: '[atex_ffp].[field]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'filterRecommendations',
			label: 'recommendations',
			default: FFPRecommendationFilter.ALL,
			options: [
				{label: 'all', value: FFPRecommendationFilter.ALL},
				{label: 'hasRecommendation', value: FFPRecommendationFilter.HAS_RECOMMENDATION},
				{label: 'noRecommendation', value: FFPRecommendationFilter.NO_RECOMMENDATION}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'actionPlan',
			label: 'actionPlan',
			default: FFPActionPlanFilter.ALL,
			options: [
				{label: 'all', value: FFPActionPlanFilter.ALL},
				{label: 'withActionPlan', value: FFPActionPlanFilter.HAS_ACTION_PLAN},
				{label: 'withoutActionPlan', value: FFPActionPlanFilter.NO_ACTION_PLAN}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'tableFields',
			label: 'tableFields',
			default: ['assetName', 'assetTag', 'field', 'actionPlansCount', 'recommendationsCount', 'actions'],
			options: [
				{label: 'assetName', value: 'assetName'},
				{label: 'assetTag', value: 'assetTag'},
				{label: 'cost', value: 'costString'},
				{label: 'field', value: 'field'},
				{label: 'actionPlans', value: 'actionPlansCount'},
				{label: 'recomendations', value: 'recommendationsCount'},
				{label: 'parentAsset', value: 'parentAssetName'},
				{label: 'actions', value: 'actions'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'stateFilter',
			label: 'status',
			default: FFPActionPlanFilter.ALL,
			options: [
				{label: FFPStateLabel.get(FFPState.ALL), value: FFPState.ALL},
				{label: FFPStateLabel.get(FFPState.ACTIVE), value: FFPState.ACTIVE},
				{label: FFPStateLabel.get(FFPState.ARCHIVED), value: FFPState.ARCHIVED}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'listMode',
			label: 'listMode',
			default: FFPListMode.ALL,
			options: [
				{label: FFPListModeLabel.get(FFPListMode.ALL), value: FFPListMode.ALL},
				{label: FFPListModeLabel.get(FFPListMode.LastFFPSBYASSET), value: FFPListMode.LastFFPSBYASSET}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * List mode.
		 */
		listMode: FFPListMode.ALL,

		/**
		 * Text used to filter assets by their name, tag name or serial number.
		 */
		search: '',

		/**
		 * Filter FFP in the list by their state.
		 */
		stateFilter: FFPState.ACTIVE,

		/**
		 * Filter to show if the FFP has action plan or not.
		 */
		filterActionPlan: FFPActionPlanFilter.ALL,

		/**
		 * Filter if the FFP already has recommendations or not.
		 */
		filterRecommendations: FFPRecommendationFilter.NO_RECOMMENDATION,

		/**
		 * Search fields to be considered.
		 */
		searchFields: [],

		/**
		 * Table fields to be shown.
		 */
		tableFields: []
	}, FFPListPage.filterOptions);

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: FFPListPage.filters.tableFields.indexOf('assetName') !== -1, size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: FFPListPage.filters.tableFields.indexOf('assetTag') !== -1, size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'cost', type: UnoTableColumnType.TEXT, attribute: 'costString', visible: FFPListPage.filters.tableFields.indexOf('costString') !== -1, size: 'small'},
		{header: 'field', type: UnoTableColumnType.TEXT, attribute: 'field', visible: FFPListPage.filters.tableFields.indexOf('field') !== -1, size: 'small'},
		{header: 'actionPlans', type: UnoTableColumnType.NUMBER, attribute: 'actionPlansCount', visible: FFPListPage.filters.tableFields.indexOf('actionPlansCount') !== -1, size: 'small'},
		{header: 'recommendations', type: UnoTableColumnType.NUMBER, attribute: 'recommendationsCount', visible: FFPListPage.filters.tableFields.indexOf('recommendationsCount') !== -1, size: 'small'},
		{header: 'parentAsset', type: UnoTableColumnType.TEXT, attribute: 'parentAssetName', visible: FFPListPage.filters.tableFields.indexOf('parentAssetName') !== -1, size: 'small'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons:
			[
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/atex/ffp/edit', {ffp: row.uuid, asset: row.assetUuid, inspection: row.inspection.uuid});
					}
				}
			]
		}
	];

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: AtexFfpListParams = {
			sortField: FFPListPage.filters.sortField,
			sortDirection: FFPListPage.filters.sortDirection,
			state: FFPListPage.filters.stateFilter,
			from: count,
			count: pageSize,
			filterActionPlan: FFPListPage.filters.filterActionPlan,
			searchFields: FFPListPage.filters.searchFields,
			search: FFPListPage.filters.search,
			filterRecommendations: FFPListPage.filters.filterRecommendations,
			listMode: FFPListPage.filters.listMode
		};

		const list = await AtexFfpService.list(params);
		const ffps: any[] = list.ffp;

		for (const ffp of ffps) {
			ffp.assetName = ffp.asset.name;
			ffp.assetTag = ffp.asset.tag;
			ffp.assetUuid = ffp.asset.uuid;
			ffp.costString = ffp.cost + '€';
			ffp.parentAssetName = ffp.parentAsset.name;
			ffp.parentAssetTag = ffp.parentAsset.tag;
			ffp.recommendationsCount = ffp.recommendations ? ffp.recommendations.length : 0;
		};

		return {
			elements: ffps,
			hasMore: list.hasMore
		};
	};

	public constructor(private cdref: ChangeDetectorRef) {
		super();
	}

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('ffp');

		this.selection = [];

		this.inspectionFields = await AtexInspectionFieldsService.get();
		this.countTableItems();
	}

	public static defaultFilters = structuredClone(FFPActionPlanFilter.filters);

	public resetFilters(): void {
		Object.assign(FFPActionPlanFilter.filters, FFPActionPlanFilter.defaultFilters);
	}

	/**
	 * Set action in bulk to all selected FFP in the list.
	 *
	 * These recommendations are added to all the selected FFP's.
	 */
	public async appendRecommendations(): Promise<void> {
		const data: FFPRecommendation[] = await AtexFfpService.listRecommendations();

		const inputs = [];
		for (let i = 0; i < data.length; i++) {
			inputs.push({
				type: 'checkbox',
				label: data[i].label,
				value: i,
				checked: false
			});
		}

		const result = await Modal.prompt(Locale.get('recommendations'), inputs);

		if (result.confirm) {
			const recommendations = result.data;
			const uuids = this.selection.slice();

			// List of FFP to update
			const toUpdate = [];

			// Get all FFP and change their values
			while (uuids.length > 0) {
				const request = await Service.fetch(ServiceList.atex.ffp.getBatch, null, null, {uuid: uuids.splice(0, 1000)}, Session.session);
				for (let i = 0; i < request.response.ffp.length; i++) {
					const ffp = FFP.parse(request.response.ffp[i]);
					let changed = false;

					for (let j = 0; j < recommendations.length; j++) {
						const index = ffp.recommendations.indexOf(recommendations[j]);
						if (index === -1) {
							ffp.recommendations.push(recommendations[j]);
							changed = true;
						}
					}

					if (changed) {
						toUpdate.push(ffp);
					}
				}
			}

			while (toUpdate.length > 0) {
				await Service.fetch(ServiceList.atex.ffp.updateBatch, null, null, {ffp: toUpdate.splice(0, 1000)}, Session.session);
			}

			Modal.alert(Locale.get('success'), Locale.get('updatedSuccessfully'));
		}
	}

	/**
	 * Create action plan from the list of FFP currently selected.
	 */
	public createActionPlan(): void {
		const uuids = this.selection;
		App.navigator.navigate('/menu/atex/action-plan/edit', {createMode: true, ffpUuids: uuids});
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public async onSearch(search: string): Promise<void> {
		FFPListPage.filters.search = search;
		await this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		FFPListPage.filters = filters;
		await this.reset();
	}

	/**
	 * Reset the table and handler.
	 */
	public async reset(): Promise<void> {

		this.table.sortDirection = FFPListPage.filters.sortDirection;
		this.table.sortField = FFPListPage.filters.sortField;

		this.tableLayout.forEach((column) => {
			column.visible = FFPListPage.filters.tableFields.indexOf(column.attribute) !== -1;
		});

		if (this.table) {
			this.countTableItems();
			await this.table.reset();
		}
		this.cdref.detectChanges();
	}

	/**
	 * Updates when a table row is checked
	 * 
	 * @param output - The checked rows
	 */
	public async checkedRow(output: any): Promise<void> {
		if (typeof output.rows === 'boolean') {
			if (!output.rows) {
				this.resetSelection();
			} else {
				const uuids = [];
	
				const data = {
					sortField: FFPListPage.filters.sortField,
					sortDirection: FFPListPage.filters.sortDirection,
					from: 0,
					count: 2000,
					filterActionPlan: FFPListPage.filters.filterActionPlan,
					searchFields: FFPListPage.filters.searchFields,
					search: FFPListPage.filters.search,
					filterRecommendations: FFPListPage.filters.filterRecommendations,
					state: FFPListPage.filters.stateFilter,
					listMode: FFPListPage.filters.listMode
				};

				while (true) {
					const request = await AtexFfpService.list(data);
		
					for (let i = 0; i < request.ffp.length; i++) {
						uuids.push(request.ffp[i].uuid);
					}

					data.from += request.ffp.length;
					if (!request.hasMore) {
						break;
					}
				}

				this.selection = uuids;
			}
		} else {
			this.checkedTableRows = output.rows;
			this.selection = output.items.map((item) => {return item.uuid;});
		}
	}

	/**
	 * Reset selection of FFP page.
	 */
	public async resetSelection(): Promise<void> {
		this.selection = [];
		this.checkedTableRows = [];
		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Delete selected FFP from the list.
	 */
	public async deleteSelection(): Promise<void> {
		const uuids = this.selection;
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.atex.ffp.deleteBatch, null, null, {uuid: uuids}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			this.resetSelection();
		}
	}

	/**
	 * When the user changes the sort using the table
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === FFPListPage.filters.sortField) {
			FFPListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			FFPListPage.filters.sortField = sortBy;
			FFPListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Check if a table header is active
	 * 
	 * @param attribute - The attribute to check if is active.
	 * @returns True if the header is active
	 */
	public headerActive(attribute: string): boolean {
		return FFPListPage.filters.tableFields.indexOf(attribute) !== -1;
	}

	/**
	 * Counts the table items and updates the table component.
	 */
	public async countTableItems(): Promise<void> {
		const params: AtexFfpCountParams = {
			status: FFPListPage.filters.stateFilter,
			filterActionPlan: FFPListPage.filters.filterActionPlan,
			searchFields: FFPListPage.filters.searchFields,
			search: FFPListPage.filters.search,
			filterRecommendations: FFPListPage.filters.filterRecommendations,
			listMode: FFPListPage.filters.listMode
		};

		this.tableTotalItemsCount = await AtexFfpService.count(params);
	}
}
