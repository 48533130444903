import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UUID} from 'src/app/models/uuid';
import {NgClass} from '@angular/common';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {Dashboard} from 'src/app/models/dashboards/dashboard';
import {cloneDeep} from 'lodash-es';
import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UnoNoDataComponent} from 'src/app/components/uno/uno-no-data/uno-no-data.component';
import {DashboardService} from '../../../services/dashboard.service';
import {UnoWidgetComponent} from '../widget/widget.component';

/**
 * The Dashboard component to display a number of widgets
 */
@Component({
	selector: 'uno-dashboard',
	templateUrl: 'dashboard.component.html',
	styleUrls: ['dashboard.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoDashboardComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [NgClass, UnoWidgetComponent, UnoNoDataComponent]
})

export class UnoDashboardComponent implements OnInit {
	/**
	 * Indicates if the dashboard is editable.
	 */
	@Input()
	public editable: boolean = true;

	/**
	 * The dashboard that is a part of this component.
	 */
	@Input()
	public dashboard: Dashboard;

	public ngOnInit(): void {
		this.dashboard.widgets.sort((a: any, b: any) => { return a.index > b.index ? 1 : -1;});
	}

	/**
	 * Callback to delete a dashboard.
	 */
	@Output()
	public delete = new EventEmitter<UUID>();

	/**
	 * Deletes a certain widget and updates the api.
	 * 
	 * @param widget - The widget to delete.
	 */
	public async deleteWidget(widget: DashboardWidget): Promise<void> {
		if (!this.editable) {
			throw new Error('Dashboard is not editable.');
		}

		const dashboard: Dashboard = cloneDeep(this.dashboard);
		dashboard.widgets = dashboard.widgets.filter((wgt: DashboardWidget) => {
			return wgt.uuid !== widget.uuid;
		});

		if (widget.type === WidgetTypes.DASHBOARD_WIDGET_TYPE_DEMO_WIDGET) {
			this.dashboard = dashboard;
			return;
		}

		await DashboardService.update(dashboard);
		this.dashboard = dashboard;
	}

	/**
	 * Updates a certain widget and updates the api.
	 * 
	 * @param widget - The widget to update.
	 */
	public async updateWidget(widget: DashboardWidget): Promise<void> {
		if (!this.editable) {
			throw new Error('Dashboard is not editable.');
		}

		await DashboardService.update(this.dashboard);
	}
}
