import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';

/**
 * UNO switch component is an input element for boolean values.
 *
 * Works similarly to checkbox elements but has the appearance of a toggle switch instead.
 */
@Component({
	selector: 'uno-switch',
	templateUrl: './uno-switch.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['uno-switch.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoSwitchComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [NgStyle, FormsModule]
})
export class UnoSwitchComponent implements ControlValueAccessor {
	/**
	 * Style of the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Value stored in the switch component.
	 */
	@Input()
	public value: boolean = false;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	public toggle(): void {
		if (this.disabled) {
			return;
		}

		this.value = !this.value;
		this.onChange(this.value);
	}

	/**
	 * Get style of the base div element.
	 */
	public getStyle(): CssNgStyle {
		Object.assign(this.ngStyle, {
			cursor: this.disabled ? null : 'pointer',
			width: '52px',
			height: '30px',
			'border-radius': '28px',
			'background-color': this.value ? 'var(--brand-primary)' : 'var(--gray-10)'
		});

		return this.ngStyle;
	}

	/**
	 * Get the style of the switch dial element.
	 */
	public getDialStyle(): CssNgStyle {
		return {
			position: 'relative',
			top: '4px',
			'animation-name': this.value ? 'uno-switch-toggle-on' : 'uno-switch-toggle-off',
			left: this.value ? '26px' : '4px',
			'animation-duration': '0.3s',
			width: '22px',
			height: '22px',
			'border-radius': '24px',
			'background-color': this.value ? 'var(--brand-contrast)' : 'var(--gray-8)'
		};
	}

	public onChange: (value: any)=> void = function() {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void {}

	public writeValue(value: boolean): void {
		this.value = value;
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

}
