import {Component, ElementRef, Input, ViewChild, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {EventManager} from 'src/app/utils/event-manager';
import {App} from 'src/app/app';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {FileUtils} from '../../../utils/file-utils';
import {Resource, ResourceType} from '../../../models/resource';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';

@Component({
	selector: 'uno-video-selector',
	templateUrl: './uno-video-selector.component.html',
	styleUrls: ['uno-video-selector.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoVideoSelectorComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, TranslateModule, UnoIconComponent]
})
export class UnoVideoSelectorComponent implements ControlValueAccessor {
	public app: any = App;
	
	@ViewChild('upload', {static: false})
	public set upload(element: ElementRef) {
		if (element) {
			if (!this.disabled) {
				['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
					this.events.add(element.nativeElement, eventName, (event) => {
						event.preventDefault();
						event.stopPropagation();
					});
				});
				this.events.add(element.nativeElement, 'drop', (event) => {
					this.dropVideo(event);
				});
	
				this.events.create();
			}
		}
	}
	
	public static FORMATS: string = '.mp4, .mkv, .mpeg, .avi';

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Resource representing the video stored.
	 */
	public value: Resource = null;

	/**
	 * Event manager used for drag and drop
	 */
	public events: EventManager = new EventManager();

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function() {};

	/**
	 * Get the video URL to be displayed on this component.
	 */
	public get videoUrl(): any {
		return Service.getURL(ServiceList.resources.file.get, {
			uuid: this.value.uuid,
			format: this.value.format
		});
	}

	/**
	 * Select video file from the user.
	 */
	public async selectFile(): Promise<void> {
		const files: File[] = await FileUtils.chooseFile(UnoVideoSelectorComponent.FORMATS, false);
		if (files.length > 0) {
			await this.uploadFile(files[0]);
		}
	}

	/**
	 * Method called when a new file is inserted.
	 *
	 * @param file - File to be uploaded.
	 */
	public async uploadFile(file): Promise<void> {
		const format = FileUtils.getFileExtension(file);

		const form = new FormData();
		form.append('file', file, 'video');
		form.append('format', format);

		const request = await Service.fetch(ServiceList.resources.file.upload, null, null, form, null);

		const resource = new Resource(request.response.uuid, format);
		resource.type = ResourceType.FILE;
		this.updateValue(resource);
	}

	/**
	 * Saves the video via drag and drop
	 */
	public async dropVideo(event: any): Promise<void> {
		const files = event?.dataTransfer?.files;
		if (files) {
			await this.uploadFile(files[0]);
		}

		event.preventDefault();
	}

	/**
	 * Updates the value of the component.
	 * 
	 * @param value - The value to set on component.
	 */
	public updateValue(value: Resource): void {
		this.value = value;
		this.onChange(value);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: Resource): void {
		this.value = value;
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public ngOnDestroy(): void {		
		this.events.destroy();
	}
}
