<ng-template #buttons>
	<!-- Adjacency's buttons -->
	@if (this.todoAdjacencies.length !== 0) {
		<p style="text-align: end; padding-right: 16px">{{'todo' | translate}}</p>
		<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-right: 16px;flex-wrap: wrap">
			@for (button of this.todoAdjacencies; track button) {
				<uno-button (click)="this.update(this.project.flowMode !== inspectionProjectFlowMode.SINGLE_STEP, button.destination, true);">{{button.label}}</uno-button>
			}
		</div>
	}

	@if (this.inProgressAdjacencies.length !== 0) {
		<p style="text-align: end; padding-right: 16px">{{'inProgress' | translate}}</p>
		<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-right: 16px;flex-wrap: wrap">
			@for (button of this.inProgressAdjacencies; track button) {
				<uno-button (click)="this.update(this.project.flowMode !== inspectionProjectFlowMode.SINGLE_STEP, button.destination, true);">{{button.label}}</uno-button>
			}
		</div>
	}

	@if (this.doneAdjacencies.length !== 0) {
		<p style="text-align: end; padding-right: 16px">{{'done' | translate}}</p>
		<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-right: 16px;flex-wrap: wrap">
			@for (button of this.doneAdjacencies; track button) {
				<uno-button (click)="this.update(this.project.flowMode !== inspectionProjectFlowMode.SINGLE_STEP, button.destination, true);">{{button.label}}</uno-button>
			}
		</div>
	}

	<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-top: 50px;" class="ion-padding">
		<!-- Create -->
		@if (this.createMode && ([userPermissions.INSPECTION_CREATE] | hasPermissions)) {
			<uno-button (click)="this.update();">{{'create' | translate}}</uno-button>
		}
		<!-- Save/Update -->
		@if (!this.createMode) {
			@if ([userPermissions.INSPECTION_EDIT] | hasPermissions) {
				<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
			}
			@if ([userPermissions.INSPECTION_EDIT] | hasPermissions) {
				<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
			}
			@if ([userPermissions.INSPECTION_DELETE] | hasPermissions) {
				<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
			}
		}
	</div>

	<!-- Reports -->
	@if (this.project) {
		@for (report of this.project.reports; track $index) {
			@if (report?.formats?.length > 0) {
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					@if (report.formats.indexOf(inspectionReportTemplateFormat.DOCX) !== -1) {
						<uno-button (click)="selfStatic.exportReportDOCX(report, this.inspection, this.workflow);">{{report.name}} ({{'DOCX' | translate}})</uno-button>
					}
					@if (report.formats.indexOf(inspectionReportTemplateFormat.PDF) !== -1) {
						<uno-button (click)="selfStatic.exportReportPDF(report, this.inspection, this.workflow);">{{report.name}} ({{'PDF' | translate}})</uno-button>
						}
				</div>
				}
				}
				}
</ng-template>

<div style="width:100%; height:100%;">
	<uno-tab>
		@if (this.inspection && this.project) {
			<uno-tab-section [active]="!this.stepFormLayout.has(this.inspection.stepUuid) || this.project?.displayMode === inspectionProjectDisplayMode.SECTIONS" title="{{'overall' | translate}}">
				<!-- Asset required fields -->
				@if (this.asset && ([this.userPermissions.ASSET_PORTFOLIO] | hasPermissions) && this.assetRequiredFieldsLayout.length > 0) {
					<!-- Asset data -->
					<uno-title>{{'requiredAssetFields' | translate}}</uno-title>
					<uno-form [showRequiredUneditable]="true" [editable]="([this.userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions)" [onChange]="this.setAssetEdited" [layout]="this.assetRequiredFieldsLayout" [object]="this.asset" #assetRequiredFieldsForm></uno-form>
				}

				<uno-title>{{this.steps.has(this.inspection.stepUuid) ? this.steps.get(this.inspection.stepUuid).name : '' | translate}}</uno-title>
				<uno-form [showRequiredUneditable]="true" [layout]="this.layout" [object]="this.inspection" #form></uno-form>

				<!-- Inspection step forms -->
				@if (this.project.displayMode === inspectionProjectDisplayMode.SECTIONS) {
					@for (stepData of this.inspection.data; track stepData) {
						@if (stepData && this.steps.get(stepData.stepUuid)) {
							<uno-title>{{this.steps.get(stepData.stepUuid).name}}</uno-title>
							<uno-form [showRequiredUneditable]="true" [layout]="this.stepFormLayout.get(stepData.stepUuid)" [object]="stepData.data" #inspectionForm></uno-form>
						}
					}
				}

				<ng-container [ngTemplateOutlet]="buttons"></ng-container>
			</uno-tab-section>

			<!-- Present steps in tabs -->
			@if (this.project.displayMode === inspectionProjectDisplayMode.TABS) {
				@for (stepData of this.inspection.data; track stepData) {
					@if (stepData && this.steps.get(stepData.stepUuid) && this.stepFormLayout.has(stepData.stepUuid)) {
						<uno-tab-section title="{{this.steps.get(stepData.stepUuid).name}}" [active]="this.inspection.stepUuid === stepData.stepUuid">
							<uno-form [showRequiredUneditable]="true" [layout]="this.stepFormLayout.get(stepData.stepUuid)" [object]="stepData.data" #inspectionForm></uno-form>
							<ng-container [ngTemplateOutlet]="buttons"></ng-container>
						</uno-tab-section>
					}
				}
			}
		}

		@if (([userPermissions.ASSET_PORTFOLIO] | hasPermissions) && this.asset) {
			<uno-tab-section title="{{'asset' | translate}}">
				<!-- Asset data -->
				<uno-form [showRequiredUneditable]="true" [editable]="[userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions" [onChange]="this.setAssetEdited" [layout]="this.assetBaseLayout" [object]="this.asset"></uno-form>
				<!-- Model Identification -->
				<uno-title>{{'modelIdentification' | translate}}</uno-title>
				<uno-form [showRequiredUneditable]="true" [editable]="[userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions" [onChange]="this.setAssetEdited" [layout]="this.modelLayout" [object]="this.asset"></uno-form>
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<!-- Save/Update -->
					@if (!this.createMode) {
						@if ([userPermissions.INSPECTION_EDIT] | hasPermissions) {
							<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
						}
						@if ([userPermissions.INSPECTION_EDIT] | hasPermissions) {
							<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
						}
						@if ([userPermissions.INSPECTION_DELETE] | hasPermissions) {
							<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
						}
					}
					<!-- Open Asset -->
					<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid})">{{'asset' | translate}}</uno-button>
				</div>
			</uno-tab-section>
		}
		@if (([userPermissions.INSPECTION_PROJECT] | hasPermissions) && this.project) {
			<uno-tab-section title="{{'project' | translate}}">
				<!-- Project data -->
				<uno-form [showRequiredUneditable]="true" [editable]="[userPermissions.INSPECTION_PROJECT_EDIT] | hasPermissions" [onChange]="this.setProjectEdited" [layout]="this.projectLayout" [object]="this.project"></uno-form>

				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="app.navigator.navigate('/menu/workflow/project/edit', {uuid: project.uuid})">{{'project' | translate}}</uno-button>
					<uno-button (click)="app.navigator.navigate('/menu/workflow/list/steps', {project: project.uuid})">{{'steps' | translate}}</uno-button>
				</div>
			</uno-tab-section>
		}
	</uno-tab>
</div>
