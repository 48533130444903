
export type DistanceUnit = {
	value: string,
	label: string
}

/**
 * List of distance units that can be used.
 */
export const DistanceUnits: DistanceUnit[] = [
	{
		value: 'm',
		label: 'm'
	},
	{
		value: 'i',
		label: 'inch'
	}
];

