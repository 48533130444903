<div class="uno-asset-selector" style="display: flex; align-items: center; gap: 16px;">
	<div class="uno-asset-selector-selectable">
		<div style="display: flex; padding-left: 14px;">
			@if(this.value) {
				@if(this.options.length <= 3) {
					@for(option of this.options; track option) {
						<span class="uno-asset-selector-selectable-text uno-p2" [ngStyle]="{ 'padding-right': this.value && !this.disabled && this.showClear ? '4px' : '16px' }">
							{{option.name}}
							<!-- Clear -->
							@if (this.value && !this.disabled && this.showClear) {
								<uno-icon src="assets/components/searchbar/close-icon.svg" color="primary" width="20" height="20" (click)="this.removeAsset(option);"></uno-icon>
							}
						</span>
					}
				} @else {
					@for(option of this.options.slice(0,3); track option) {
						<span class="uno-asset-selector-selectable-text uno-p2" [ngStyle]="{ 'padding-right': this.value && !this.disabled && this.showClear ? '4px' : '16px' }">
							{{option.name}}
							<!-- Clear -->
							@if (this.value && !this.disabled && this.showClear) {
								<uno-icon src="assets/components/searchbar/close-icon.svg" color="primary" width="20" height="20" (click)="this.removeAsset(option);"></uno-icon>
							}
						</span>
					}
					<span class="uno-asset-selector-selectable-text uno-p2" style="padding: 2px 10px 2px 10px; ">
						+{{this.options.length-3}}
					</span>
					}

					} @else {
						<span class="uno-asset-selector-selectable-empty">{{'empty' | translate}}</span>
					}
		</div>
		<div class="uno-asset-selector-select-icon">
			<uno-icon src="assets/icons/assets/expand-icon.svg" color="primary" width="32" height="32" (click)="this.openAssetModal();"></uno-icon>
		</div>
	</div>

	<div style="display: flex; gap: 8px;">
		<!-- Qr -->
		@if (app.device.hasCamera && ([permissions.ASSET_PORTFOLIO_QR] | hasPermissions) && !this.disabled) {
			<uno-icon src="assets/components/menu/qrcode.svg" color="primary" width="32" height="32" (click)="this.selectAssetQR();"></uno-icon>
		}
		<!-- Nfc -->
		@if (app.device.hasNFC() && ([permissions.ASSET_PORTFOLIO_NFC] | hasPermissions) && !this.disabled) {
			<uno-icon src="assets/components/menu/nfc.svg" color="primary" width="32" height="32" (click)="this.selectAssetNFC();"></uno-icon>
		}
		<!-- Create -->
		@if (([permissions.ASSET_PORTFOLIO_ASSET_CREATE] | hasPermissions) && !this.value && !this.disabled) {
			<uno-icon src="assets/icons/uno/add.svg" color="primary" width="32" height="32" (click)="this.createAssetModal();"></uno-icon>
		}
	</div>
</div>
