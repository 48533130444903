import {Dashboard} from 'src/app/models/dashboards/dashboard';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {UUID} from '../../../models/uuid';

export type DashboardListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
	// Pinned filter
	pinned?: boolean
};

export type DashboardCountParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// Pinned filter
	pinned?: boolean
};

export type DashbboardListResponse = {
	// Array of dashboards
	dashboards: Dashboard[],
	// If there are more dashboards to fetch
	hasMore: boolean,
	// Id of the request
	id: number
}

export class DashboardService {
	/**
	 * Get a dashboard from its UUID.
	 *
	 * @param uuid - The UUID of the dashboard to get.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The dashboard object.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<Dashboard> {
		const request = await Service.fetch(ServiceList.dashboards.dashboard.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return Dashboard.parse(request.response.dashboard);
	}

	/**
	 * Count all dashboards.
	 *
	 * @param params - Parameters to filter the count.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Number of dashboards.
	 */
	public static async count(params: DashboardCountParams, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.dashboards.dashboard.count, null, null, params, Session.session, hideLoading, displayError);

		return request.response.count;
	}

	/**
	 * List all dashboards.
	 *
	 * @param params - Parameters to filter the list.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Array of dashboards matching the params.
	 */
	public static async list(params: DashboardListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<DashbboardListResponse> {
		const request = await Service.fetch(ServiceList.dashboards.dashboard.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			dashboards: request.response.dashboards,
			hasMore: request.response.hasMore,
			id: request.id
		};
	}

	/**
	 * Create a new dashboard.
	 *
	 * @param dashboard - The data of the dashboard to create.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The UUID of the created dashboard.
	 */
	public static async create(dashboard: Dashboard, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.dashboards.dashboard.create, null, null, dashboard, Session.session, hideLoading, displayError);
	}

	/**
	 * Delete a dashboard.
	 *
	 * @param dashboardUuid - The UUID of the dashboard to delete.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async delete(dashboardUuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.dashboards.dashboard.delete, null, null, {uuid: dashboardUuid}, Session.session, hideLoading, displayError);
	}

	/**
	 * Update a dashboard.
	 *
	 * @param dashboard - The dashboard to update.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async update(dashboard: Dashboard, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.dashboards.dashboard.update, null, null, dashboard, Session.session, hideLoading, displayError);
	}
}
