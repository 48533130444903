/**
 * List of user permissions, used to control access to the application features.
 */
export const UserPermissions = {
	ASSET_PORTFOLIO: 1000,
	ASSET_PORTFOLIO_ASSET_EDIT: 1001,
	ASSET_PORTFOLIO_ASSET_CREATE: 1002,
	ASSET_PORTFOLIO_ASSET_DELETE: 1003,
	ASSET_PORTFOLIO_MAP: 1004,
	ASSET_PORTFOLIO_QR: 1005,
	ASSET_PORTFOLIO_NFC: 1006,
	ASSET_PORTFOLIO_TREE_VIEW: 1007,
	ASSET_PORTFOLIO_MODEL_LIST: 1008,
	ASSET_PORTFOLIO_IMPORT_ASSETS_XLSX: 1009,
	ASSET_PORTFOLIO_DELETE_ASSETS_DATE: 1011,
	ASSET_PORTFOLIO_EXPORT_JSON: 1012,
	ASSET_PORTFOLIO_EXPORT_XLSX: 1013,
	ASSET_PORTFOLIO_ASSET_DELETE_ASSETS_XLSX_FILE: 1014,
	ASSET_PORTFOLIO_SETTINGS_EDIT: 1015,
	ASSET_PORTFOLIO_FORM_BLOCK: 1016,
	ASSET_PORTFOLIO_FORM_BLOCK_CREATE: 1017,
	ASSET_PORTFOLIO_FORM_BLOCK_EDIT: 1018,
	ASSET_PORTFOLIO_FORM_BLOCK_DELETE: 1019,
	ASSET_PORTFOLIO_ASSET_TYPE: 1020,
	ASSET_PORTFOLIO_ASSET_TYPE_CREATE: 1021,
	ASSET_PORTFOLIO_ASSET_TYPE_EDIT: 1022,
	ASSET_PORTFOLIO_ASSET_TYPE_DELETE: 1023,
	ASSET_PORTFOLIO_ASSET_SUBTYPE: 1024,
	ASSET_PORTFOLIO_ASSET_SUBTYPE_CREATE: 1025,
	ASSET_PORTFOLIO_ASSET_SUBTYPE_EDIT: 1026,
	ASSET_PORTFOLIO_ASSET_SUBTYPE_DELETE: 1027,
	ASSET_PORTFOLIO_FORM_STRUCTURE_PATERNITY: 1028,
	ASSET_PORTFOLIO_STRUCTURE: 1029,
	ASSET_PORTFOLIO_STRUCTURE_EXPORT: 1030,
	ASSET_PORTFOLIO_FORM_BLOCK_IMPORT: 1031,
	ASSET_PORTFOLIO_FORM_BLOCK_EXPORT: 1032,
	ASSET_PORTFOLIO_FLUID_EDIT: 1033,

	USER: 2000,
	USER_EDIT: 2002,
	USER_EMAIL_EDIT: 2003,
	USER_EXPORT_XLSX: 2004,

	TEAM: 3000,
	TEAM_CREATE: 3001,
	TEAM_EDIT: 3002,
	TEAM_DELETE: 3003,

	COMPANY: 4000,
	COMPANY_CREATE: 4001,
	COMPANY_EDIT: 4002,
	COMPANY_DELETE: 4003,

	MASTER_DATA: 5000,

	TOOLS: 6000,
	TOOLS_QR_GENERATE: 6012,
	TOOLS_QR_GENERATE_LOGO: 6013,
	TOOLS_QR_GENERATE_LOGO_ZIP: 6014,

	DASHBOARD: 7000,

	ATEX_INSPECTION: 8000,
	ATEX_INSPECTION_EDIT: 8001,
	ATEX_INSPECTION_INSPECT: 8002,
	ATEX_INSPECTION_INSPECT_SUPERVISOR: 8003,
	ATEX_INSPECTION_REVIEW: 8004,
	ATEX_INSPECTION_REVERT_STATE: 8005,
	ATEX_INSPECTION_REVIEW_SUPERVISOR: 8006,
	ATEX_INSPECTION_EXPORT_JSON: 8007,
	ATEX_INSPECTION_EXPORT_XLSX: 8008,
	ATEX_INSPECTION_FIELDS_EDIT: 8009,
	ATEX_INSPECTION_CHECKLIST_EDIT: 8010,
	ATEX_INSPECTION_CREATE: 8011,
	ATEX_INSPECTION_DASHBOARD_PROGRESS: 8012,
	ATEX_INSPECTION_DASHBOARD_STATES: 8013,
	ATEX_INSPECTION_DASHBOARD_STATISTICS_DONE: 8015,
	ATEX_INSPECTION_DASHBOARD_STATISTICS_REVIEW: 8016,
	ATEX_INSPECTION_DASHBOARD_STATISTICS_UPDATED: 8017,
	ATEX_INSPECTION_DELETE: 8018,
	ATEX_INSPECTION_EXPORT_REPORTS_BULK: 8019,
	ATEX_INSPECTION_ACCESS_ALL: 8020,
	ATEX_INSPECTION_ACCESS_TEAM: 8021,
	ATEX_INSPECTION_FIELDS_EDIT_BULK: 8022,
	ATEX_INSPECTION_CHECKLIST_CHECK: 8023,
	ATEX_INSPECTION_SETTINGS_EDIT: 8024,

	EPI: 9000,
	EPI_CREATE: 9001,
	EPI_INSPECT: 9002,
	EPI_REVIEW: 9003,
	EPI_EDIT: 9004,
	EPI_DELETE: 9005,
	EPI_REVERT_STATE: 9006,
	EPI_IMPORT_XLSX: 9007,
	EPI_EXPORT_JSON: 9008,
	EPI_EXPORT_XLSX: 9009,

	FFP: 10000,
	FFP_DELETE: 10001,
	FFP_EDIT: 10002,
	FFP_ARCHIVE: 10003,
	FFP_EXPORT_JSON: 10004,
	FFP_EXPORT_XLSX: 10005,
	FFP_RECOMMENDATIONS_EDIT: 10006,
	FFP_DELETE_BULK_TOOL: 10007,

	ACTION_PLAN: 11000,
	ACTION_PLAN_CREATE: 11001,
	ACTION_PLAN_DELETE: 11002,
	ACTION_PLAN_OVERRIDE_STATE: 11003,
	ACTION_PLAN_SUBMIT: 11004,
	ACTION_PLAN_CLIENT: 11005,
	ACTION_PLAN_COMPANY: 11006,
	ACTION_PLAN_REINSPECT: 11007,
	ACTION_PLAN_EDIT: 11008,
	ACTION_PLAN_UNBLOCK: 11009,
	ACTION_PLAN_ARCHIVE: 11010,
	ACTION_PLAN_EXPORT_XLSX: 11011,
	ACTION_PLAN_ACTIONS_EDIT: 11012,
	ACTION_PLAN_DASHBOARD_STATES: 11013,
	ACTION_PLAN_ACCESS_ALL: 11014,
	ACTION_PLAN_ACCESS_COMPANY: 11015,
	ACTION_PLAN_ACCESS_TEAM: 11016,
	ACTION_PLAN_CREATE_BULK: 11017,

	LOGS: 13000,
	LOGS_ERROR: 13001,
	LOGS_ACCESS: 13002,
	LOGS_TASKS: 13003,
	LOGS_ERROR_CLIENT: 13004,

	OBSERVATIONS: 14000,
	OBSERVATIONS_CREATE: 14001,
	OBSERVATIONS_EDIT: 14002,
	OBSERVATIONS_DELETE: 14003,

	REPAIR: 15000,
	REPAIR_CREATE: 15001,
	REPAIR_LIST_ALL: 15002,
	REPAIR_LIST_PROPOSAL: 15003,
	REPAIR_LIST_ON_GOING: 15004,
	REPAIR_LIST_WAITING_APPROVAL: 15005,
	REPAIR_EDIT: 15006,
	REPAIR_DELETE: 15007,
	REPAIR_APPROVE_PROPOSAL: 15008,
	REPAIR_APPROVE_REPAIR: 15009,
	REPAIR_FINISH: 15010,
	REPAIR_LIST_WAITING_DONE: 15011,
	REPAIR_ADD_PROPOSAL: 15012,
	REPAIR_STATUS_GRAPH: 15013,
	REPAIR_CRITICALITY_GRAPH: 15014,
	REPAIR_LIST_BLOCKED: 15015,
	REPAIR_BLOCK: 15016,
	REPAIR_UNLOCK: 15017,
	REPAIR_EXPORT_XLSX: 15018,
	REPAIR_IMPORT_XLSX: 15019,
	REPAIR_DECOMISSION: 15020,
	REPAIR_ARCHIVE: 15021,
	REPAIR_LIST_ARCHIVED: 15023,
	REPAIR_LIST_IMPLEMENTED: 15024,
	REPAIR_QR: 15025,
	REPAIR_CONSUMABLES_EDIT: 15026,
	REPAIR_NDT_EDIT: 15027,
	REPAIR_WELDING_PROCEDURES_EDIT: 15028,
	REPAIR_WELDING_TYPES_EDIT: 15029,
	REPAIR_WELDING_STANDARDS_EDIT: 15030,
	REPAIR_ACCESS_ALL: 15031,
	REPAIR_ACCESS_COMPANY: 15032,
	REPAIR_ACCESS_TEAM: 15033,
	REPAIR_SETTINGS_EDIT: 15034,
	REPAIR_UNARCHIVE: 15035,
	REPAIR_TEMPORARY_TYPES_EDIT: 15036,

	INTELLIGENT_DOCUMENTS: 16000,

	INSPECTION: 17000,
	INSPECTION_FORM: 17001,
	INSPECTION_FORM_CREATE: 17002,
	INSPECTION_FORM_EDIT: 17003,
	INSPECTION_FORM_DELETE: 17004,
	INSPECTION_WORKFLOW: 17005,
	INSPECTION_WORKFLOW_CREATE: 17006,
	INSPECTION_WORKFLOW_EDIT: 17007,
	INSPECTION_WORKFLOW_DELETE: 17008,
	INSPECTION_PROJECT: 17009,
	INSPECTION_PROJECT_CREATE: 17010,
	INSPECTION_PROJECT_EDIT: 17011,
	INSPECTION_PROJECT_DELETE: 17012,
	INSPECTION_CREATE: 17013,
	INSPECTION_EDIT: 17014,
	INSPECTION_DELETE: 17015,
	INSPECTION_PROJECT_DASHBOARD_STEPS: 17016,
	INSPECTION_PROJECT_ACCESS_ALL: 17017,
	INSPECTION_PROJECT_ACCESS_TEAM: 17018,
	INSPECTION_ACCESS_ALL: 17019,
	INSPECTION_ACCESS_TEAM: 17020,
	INSPECTION_QR: 17021,
	INSPECTION_EXPORT_XLSX: 17022,
	INSPECTION_EXPORT_REPORTS_BULK: 17023,
	INSPECTION_EXPORT_JSON: 17024,
	INSPECTION_FORM_CLONE: 17025,
	INSPECTION_WORKFLOW_CLONE: 17026,
	INSPECTION_WORKFLOW_IMPORT: 17027,

	REPAIR_INSPECTIONS: 19000,
	REPAIR_INSPECTIONS_CREATE: 19001,
	REPAIR_INSPECTIONS_EDIT: 19002,
	REPAIR_INSPECTIONS_DELETE: 19003,
	REPAIR_INSPECTIONS_ACCESS_ALL: 19004,
	REPAIR_INSPECTIONS_ACCESS_TEAM: 19005,
	REPAIR_INSPECTIONS_EXPORT_REPORTS_BULK: 19006,

	DIGITAL_TWIN: 20000,

	ROLES: 21000,

	MASTER_SETTINGS: 22000,
	MASTER_SETTINGS_EDIT: 22001,

	PIPELINE_INTEGRITY: 24000,
	PIPELINE_INTEGRITY_PLANT_CREATE: 24001,
	PIPELINE_INTEGRITY_PLANT_EDIT: 24002,
	PIPELINE_INTEGRITY_PLANT_DELETE: 24003,
	PIPELINE_INTEGRITY_PIPELINE_CREATE: 24004,
	PIPELINE_INTEGRITY_PIPELINE_EDIT: 24005,
	PIPELINE_INTEGRITY_PIPELINE_DELETE: 24006,
	PIPELINE_INTEGRITY_MOT_CMP_CREATE: 24007,
	PIPELINE_INTEGRITY_MOT_CMP_EDIT: 24008,
	PIPELINE_INTEGRITY_MOT_CMP_DELETE: 24009,
	PIPELINE_INTEGRITY_MOT_ACQUISITION_CREATE: 24010,
	PIPELINE_INTEGRITY_MOT_ACQUISITION_EDIT: 24011,
	PIPELINE_INTEGRITY_MOT_ACQUISITION_DELETE: 24012,
	PIPELINE_INTEGRITY_MOT_EXPERIMENT_CREATE: 24013,
	PIPELINE_INTEGRITY_MOT_EXPERIMENT_EDIT: 24014,
	PIPELINE_INTEGRITY_MOT_EXPERIMENT_DELETE: 24015,

	FUNCTIONAL_ANALYSIS: 25000,

	PRIVACY_POLICY: 26000,
	PRIVACY_POLICY_EDIT: 26001,

	CALENDAR_EVENT: 27000,
	CALENDAR_EVENT_CREATE: 27001,
	CALENDAR_EVENT_EDIT: 27002,
	CALENDAR_EVENT_DELETE: 27003,
	CALENDAR_EVENT_ACCESS_ALL: 27004,
	CALENDAR_EVENT_ACCESS_USER: 27005,
	CALENDAR_EVENT_ACCESS_TEAM: 27006,
	CALENDAR_EVENT_IMPORT_XLSX: 27007,

	GA: 28000,

	GA_GAP: 28100,
	GA_GAP_EDIT: 28101,
	GA_GAP_DELETE: 28102,
	GA_GAP_EXPORT: 28103,

	GA_ACTION_PLAN: 28200,
	GA_ACTION_PLAN_CREATE: 28201,
	GA_ACTION_PLAN_EDIT: 28202,
	GA_ACTION_PLAN_DELETE: 28203,

	DL50: 29000,

	DL50_MASTER_DATA_QUESTIONS_CREATE: 29001,
	DL50_MASTER_DATA_QUESTIONS_EDIT: 29002,
	DL50_MASTER_DATA_QUESTIONS_DELETE: 29003,

	DL50_MASTER_DATA_REGULATORY_STANDARDS_CREATE: 29004,
	DL50_MASTER_DATA_REGULATORY_STANDARDS_EDIT: 29005,
	DL50_MASTER_DATA_REGULATORY_STANDARDS_DELETE: 29006,

	DL50_INSPECTIONS_CREATE: 29007,
	DL50_INSPECTIONS_EDIT: 29008,
	DL50_INSPECTIONS_DELETE: 29009,

	DL50_MASTER_DATA_SETTINGS: 29010,

	DL50_INSPECTOR: 29011,
	DL50_CLIENT: 29012,
	DL50_SUPERVISOR: 29013,
  
	DL50_EXPORT: 29014,

	DL50_DASHBOARD_INSPECTIONS_STATUS: 29015,
	DL50_DASHBOARD_INSPECTIONS_CONCLUSION: 29016,

	DASHBOARDS: 30000,
	DASHBOARDS_CREATE: 30001,
	DASHBOARDS_EDIT: 30002,
	DASHBOARDS_DELETE: 30003
};
