<div class="uno-widget-container">
	<div class="uno-widget-text-container">
		<div class="uno-widget-title-container">
			<h3 class="uno-h7-bold uno-widget-title">{{this.widget.name}}</h3>
			@if (this.widget.data.demo || this.editable) {
				<div class="uno-widget-icons-container">
					<uno-icon [src]="'assets/icons/assets/edit-icon.svg'" [color]="'var(--brand-primary)'" [width]="24" [height]="24" (click)="this.openEditModal()" title="{{'edit' | translate}}"></uno-icon>
					<uno-icon [src]="'assets/icons/uno/bin.svg'" [color]="'var(--brand-primary)'" [width]="24" [height]="24" (click)="this.deleteWidget()" title="{{'delete' | translate}}"></uno-icon>
				</div>
			}

		</div>
		<h3 class="uno-p2 uno-widget-subtitle">{{this.widget.description}}</h3>
	</div>
	<div class="uno-widget-data-container">
		@if (this.widget.data.chart === 'bar') {
			<dashboard-bar-chart [data]="this.chartData"></dashboard-bar-chart>
		} @else if (this.widget.data.chart === 'line') {
			<dashboard-line-chart [data]="this.chartData"></dashboard-line-chart>
		} @else if (this.widget.data.chart === 'doughnut') {
			<dashboard-doughnut-chart [data]="this.chartData"></dashboard-doughnut-chart>
		} @else if (this.widget.data.chart === 'map') {
			<dashboard-map></dashboard-map>
		}
	</div>
</div>
