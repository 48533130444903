<div class="uno-date-frequency-container">
	<input class="uno-date-frequency-input" type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="this.frequencyInput" (change)="this.onFrequencyChange($event.target.value)" />
	<div class="uno-date-frequency-button-group">
		@if (this.type === dateFrequencyPrecision.MINUTE) {
			<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyType.MINUTES}" (click)="this.selectFrequencyType(dateFrequencyType.MINUTES)">{{'minute' | translate}}</button>
		}
		@if (this.type === dateFrequencyPrecision.HOUR || this.type === dateFrequencyPrecision.MINUTE) {
			<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyType.HOURS}" (click)="this.selectFrequencyType(dateFrequencyType.HOURS)">{{'hours' | translate}}</button>
		}
		<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyType.DAYS}" (click)="this.selectFrequencyType(dateFrequencyType.DAYS)">{{'days' | translate}}</button>
		<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyType.MONTHS}" (click)="this.selectFrequencyType(dateFrequencyType.MONTHS)">{{'months' | translate}}</button>
		<button class="uno-date-frequency-button" [ngClass]="{'uno-date-frequency-button-selected ': this.frequencyType === dateFrequencyType.YEARS}" (click)="this.selectFrequencyType(dateFrequencyType.YEARS)">{{'years' | translate}}</button>
	</div>
</div>
