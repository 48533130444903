import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {ObjectUtils} from 'src/app/utils/object-utils';
import {Loading} from 'src/app/loading';
import {InspectionAverageExportADN} from 'src/client-custom/adn/inspection-export-adn';
import {TranslateModule} from '@ngx-translate/core';
import {InspectionWorkflowStepStatus} from 'src/app/models/inspections/workflow/inspection-workflow-step-status';
import {FileConverterService} from 'src/app/services/file-converter.service';
import {InspectionProjectDisplayMode} from 'src/app/models/inspections/project/inspection-project-display-mode';
import {NgTemplateOutlet} from '@angular/common';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {AssetService} from '../../../../asset-portfolio/services/asset.service';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Session} from '../../../../../session';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {InspectionLayout} from '../inspection-layout';
import {Inspection} from '../../../../../models/inspections/inspection/inspection';
import {generateUUID, UUID} from '../../../../../models/uuid';
import {InspectionWorkflow} from '../../../../../models/inspections/workflow/inspection-workflow';
import {InspectionForm} from '../../../../../models/inspections/form/inspection-form';
import {InspectionWorkflowStep} from '../../../../../models/inspections/workflow/inspection-workflow-step';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {InspectionProject} from '../../../../../models/inspections/project/inspection-project';
import {InspectionReport} from '../../../data/inspection/inspection-report';
import {FileUtils} from '../../../../../utils/file-utils';
import {UnoFormUtils} from '../../../../../components/uno-forms/uno-form/uno-form-utils';
import {InspectionFormUtils} from '../../../data/form/inspection-form-utils';
import {InspectionReportTemplate} from '../../../../../models/inspections/project/inspection-report-template';
import {ReportTemplateFormat} from '../../../../../utils/report-template-format';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {AssetBaseLayout, AssetModelLayout} from '../../../../asset-portfolio/screens/asset/asset-layout';
import {InspectionData} from '../../../../../models/inspections/inspection/inspection-data';
import {InspectionWorkflowStepAdjacency} from '../../../../../models/inspections/workflow/inspection-workflow-step-adjacency';
import {Environment} from '../../../../../../environments/environment';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {InspectionFormService} from '../../../services/inspection-form.service';
import {InspectionProjectService} from '../../../services/inspection-project.service';
import {InspectionService} from '../../../services/inspection.service';
import {InspectionWorkflowService} from '../../../services/inspection-workflow.service';
import {InspectionProjectFlowMode} from '../../../../../models/inspections/project/inspection-project-flow-mode';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';
import {InspectionProjectLayout} from '../../project/inspection-project-layouts';
import {InspectionProjectRequiredAssetFields} from '../../project/edit/inspection-project-edit.page';

/**
 * Adjacency button to be drawn in the GUI.
 */
class InspectionAdjacencyButton {
	/**
	 * Label of the adjacency button.
	 */
	public label: string = '';

	/**
	 * UUID of the next step of the adjacency.
	 */
	public destination: UUID = null;

	/**
	 * Status of the step
	 */
	public status: InspectionWorkflowStepStatus = null;
}

@Component({
	selector: 'inspection-edit-page',
	templateUrl: './inspection-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoTitleComponent, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe, NgTemplateOutlet]
})
export class InspectionEditPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	public assetBaseLayout: any = AssetBaseLayout;

	public projectLayout: any = InspectionProjectLayout;

	public inspectionReportTemplateFormat: any = ReportTemplateFormat;

	public modelLayout: any = AssetModelLayout;

	public inspectionProjectFlowMode: any = InspectionProjectFlowMode;

	public inspectionProjectDisplayMode: any = InspectionProjectDisplayMode;

	public selfStatic: any = InspectionEditPage;

	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	@ViewChild('assetRequiredFieldsForm', {static: false})
	public assetRequiredFieldsForm: UnoFormComponent = null;

	@ViewChildren('inspectionForm')
	public inspectionForm: QueryList<UnoFormComponent>;

	public permissions = [UserPermissions.INSPECTION_EDIT, UserPermissions.INSPECTION_CREATE];

	/**
	 * A restrict view of the asset layout with the asset required fields by the project to be filled by the user.
	 */
	public assetRequiredFieldsLayout: UnoFormField[] = [];
	
	/**
	 * Create mode flag. If true the inspection still does not exist on the server.
	 */
	public createMode: boolean = false;

	/**
	 * Inspection being edited on this screen, data is fetched from the API.
	 */
	public inspection: Inspection = null;

	/**
	 * The asset attached to the inspection being edited.
	 */
	public asset: APAsset = null;

	/**
	 * Project where this inspection belongs.
	 */
	public project: InspectionProject = null;

	/**
	 * Workflow used for this inspection w/ the steps data.
	 */
	public workflow: InspectionWorkflow = null;

	/**
	 * Forms used for this workflow steps. Mapped by form UUID and then by step UUID.
	 *
	 * Contains all the forms used for the inspection steps and additional sub-forms used by individual fields.
	 */
	public stepForms: Map<UUID, Map<UUID, InspectionForm>> = null;

	/**
	 * Dynamic forms used to edit the objects of the forms.
	 *
	 * Mapped by step UUID.
	 */
	public stepFormLayout: Map<UUID, UnoFormField[]> = null;

	/**
	 * Inspection steps used for this inspection workflow.
	 */
	public steps: Map<UUID, InspectionWorkflowStep> = null;

	/**
	 * Inspection forms used for this inspection workflow.
	 * 
	 * Includes sub-forms used by individual fields.
	 */
	public forms: Map<UUID, InspectionForm> = null;

	/**
	 * Buttons to move to todo steps.
	 */
	public todoAdjacencies: InspectionAdjacencyButton[] = [];

	/**
	 * Buttons to move to in progress steps.
	 */
	public inProgressAdjacencies: InspectionAdjacencyButton[] = [];

	/**
	 * Buttons to move to done steps.
	 */
	public doneAdjacencies: InspectionAdjacencyButton[] = [];

	/**
	 * Layout to create new inspections.
	 */
	public layout: UnoFormField[] = [];

	/**
	 * Flag set true when any asset information is edited.
	 */
	public assetEdited: boolean = false;

	/**
	 * Sets asset edited flag true.
	 */
	public setAssetEdited: Function = () => { this.assetEdited = true; };

	/**
	 * Flag set true when any project information is edited.
	 */
	public projectEdited: boolean = false;

	/**
	 * Sets project edited flag true.
	 */
	public setProjectEdited: Function = () => {
		this.projectEdited = true;
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.clear();

		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		if ((!data.createMode || !data.project) && !data.uuid) {
			throw new Error('Missing required data for the screen, "createMode" and "project" or "uuid", are required.');
		}

		this.assetEdited = false;
		this.createMode = data.createMode === true;
		App.navigator.setTitle(this.createMode ? 'create' : 'edit');

		if (this.createMode) {
			this.project = await InspectionProjectService.get(data.project);

			this.inspection = new Inspection();
			this.inspection.uuid = generateUUID();
			this.inspection.projectUuid = this.project.uuid;
			this.inspection.assetUuid = data.asset;
		} else {
			this.inspection = await InspectionService.get(data.uuid);
			this.project = await InspectionProjectService.get(this.inspection.projectUuid);
		}

		if (this.project?.requiredAssetFields?.length > 0) {
						
			// Filter the asset required attributes set by the project
			this.assetRequiredFieldsLayout = this.assetBaseLayout.filter((f: UnoFormField) => { return this.project.requiredAssetFields.includes(f.attribute as (typeof InspectionProjectRequiredAssetFields)[keyof typeof InspectionProjectRequiredAssetFields]); });
			
			// Set fields as required
			this.assetRequiredFieldsLayout.forEach((f: UnoFormField) => { f.required = true; });
		}

		if (this.inspection.assetUuid) {
			this.asset = await AssetService.get(this.inspection.assetUuid);
		}

		if (this.project.name) {
			App.navigator.setTitle(this.project.name);
		}

		await this.loadData();
	}


	/**
	 * Loads all data needed by the inspection page.
	 *
	 * Loads workflow, step and form data required to build the inspection forms.
	 *
	 * Create the inspection forms required to display the inspection fields.
	 *
	 * Checks if the inspection already has data for the current inspection step.
	 *
	 * Also creates step adjacency buttons to that the user can change the state of the inspection.
	 */
	public async loadData(): Promise<void> {
		this.workflow = await InspectionWorkflowService.getFromProject(this.inspection.projectUuid);

		// Fill the map with steps of the workflow
		for (const step of this.workflow.steps) {
			this.steps.set(step.uuid, step);

			// Set the default step of the inspection
			if (!this.inspection.stepUuid && step.defaultStep) {
				this.inspection.stepUuid = step.uuid;
			}
		}

		this.forms = new Map();
		this.stepForms = await InspectionEditPage.loadWorkflowForms(this.workflow, this.forms);

		// Create adjacency buttons
		this.createAdjacencyButtons();

		for (const [stepUuid, step] of this.steps) {
			// Lock step forms for edition if workflow does not allow to edit other step forms
			const stepFormEditable: boolean = !(this.workflow.lockDataEditingOutsideStep && stepUuid !== this.inspection.stepUuid);

			// Build step form
			if (step.formUuid) {
				const dynamicForm: UnoFormField[] = InspectionFormUtils.buildDynamicForm(this.stepForms.get(stepUuid), step.formUuid, stepFormEditable);
				this.stepFormLayout.set(stepUuid, dynamicForm);
			}
		}

		this.initializeInspectionLayout();

		this.inspection.updateData(this.steps, this.forms);
	}

	/**
	 * Prepare the form layout to be used for the inspection project configuration.
	 */
	public initializeInspectionLayout(): void {
		// Clone the form to apply changes
		this.layout = cloneDeep(InspectionLayout);

		// Define if "asset" is required, based on project configuration
		const assetField = UnoFormUtils.getFormFieldByAttribute(this.layout, 'assetUuid');
		assetField.required = this.project.requireAsset;
		assetField.onChange = async(object, row) => {
			this.asset = object.assetUuid ? await AssetService.get(object.assetUuid) : null;
		};

		// Define if "name" is required, based on project "requireAsset" value. Name of inspections is not required if project already requires an asset.
		const nameField = UnoFormUtils.getFormFieldByAttribute(this.layout, 'name');
		nameField.required = (object, row) => {
			return !object.assetUuid;
		};
	}

	/**
	 * Clear screen data.
	 */
	public clear(): void {
		this.inspection = null;
		this.workflow = null;
		this.project = null;
		this.asset = null;
		this.todoAdjacencies = [];
		this.inProgressAdjacencies = [];
		this.doneAdjacencies = [];
		this.stepForms = new Map<UUID, Map<UUID, InspectionForm>>();
		this.steps = new Map<UUID, InspectionWorkflowStep>();
		this.forms = new Map<UUID, InspectionForm>();
		this.stepFormLayout = new Map<UUID, UnoFormField[]>();
	}

	/**
	 * Create buttons for the inspection adjacencies.
	 */
	public createAdjacencyButtons(): void {
		this.todoAdjacencies = [];
		this.inProgressAdjacencies = [];
		this.doneAdjacencies = [];
		for (const adjacency of this.workflow.adjacencies) {
			if (adjacency.originUuid === this.inspection.stepUuid) {
				const button: InspectionAdjacencyButton = new InspectionAdjacencyButton();
				button.label = this.steps.get(adjacency.destinationUuid).name;
				button.destination = adjacency.destinationUuid;
				const adjacentStep = this.workflow.steps.find((step) => {return step.uuid === adjacency.destinationUuid;});
				button.status = adjacentStep.status;
				switch (button.status) {
					case InspectionWorkflowStepStatus.TODO:
						this.todoAdjacencies.push(button);
						break;
					case InspectionWorkflowStepStatus.IN_PROGRESS:
						this.inProgressAdjacencies.push(button);
						break;
					case InspectionWorkflowStepStatus.DONE:
						this.doneAdjacencies.push(button);
						break;
				}
			}
		}
	}

	/**
	 * Load the forms used by the workflow, indexed by step.
	 *
	 * @param workflow - Workflow to load forms.
	 * @param forms - Map of forms to load, if not provided it will be created.
	 * @param hideLoading - Flag to hide loading screen.
	 * @returns A map of form UUIDs to the inspections forms from each workflow step form and sub-forms UUIDs.
	 */
	public static async loadWorkflowForms(workflow: InspectionWorkflow, forms: Map<UUID, InspectionForm> = new Map(), hideLoading: boolean = false): Promise<Map<UUID, Map<UUID, InspectionForm>>> {
		const stepForms: Map<UUID, Map<UUID, InspectionForm>> = new Map<UUID, Map<UUID, InspectionForm>>();

		// Load form by UUID and its sub-forms, store in the step map provided.
		const loadForms = async(formUuid: UUID, step: Map<UUID, InspectionForm>): Promise<void> => {
			if (!formUuid) {
				return;
			}
			
			let form: InspectionForm = null;
			
			if (forms.has(formUuid)) {
				form = forms.get(formUuid);
			} else {
				form = await InspectionFormService.get(formUuid, hideLoading);
				forms.set(formUuid, form);
			}
			
			if (!step.has(formUuid)) {
				step.set(formUuid, form);
			}

			// Load sub-forms
			for (let j = 0; j < form.fields.length; j++) {
				if (form.fields[j].subFormUuid) {
					await loadForms(form.fields[j].subFormUuid, step);
				}
			}
		};

		// Load forms directly attached to the workflow for each step
		for (let i = 0; i < workflow.steps.length; i++) {
			const step: InspectionWorkflowStep = workflow.steps[i];
			const steps = new Map<UUID, InspectionForm>();
			await loadForms(step.formUuid, steps);

			stepForms.set(step.uuid, steps);
		}

		return stepForms;
	}

	/**
	 * Generate and export a DOCX report for the inspection using the report template available on the project data.
	 *
	 * @param template - The inspection report template to be used on report export.
	 * @param inspection - The inspection object to export.
	 * @param workflow - The inspection workflow object to extract info for the report.
	 * @param writeToFile - If true the report is written to file.
	 * @returns The report generated as array buffer.
	 */
	public static async exportReportDOCX(template: InspectionReportTemplate, inspection: Inspection, workflow?: InspectionWorkflow, writeToFile: boolean = true): Promise<ArrayBuffer> {
		// Additional data to provide to the export
		let additionalData: any = {};

		// All the inspection forms already fetched
		const forms: Map<UUID, InspectionForm> = new Map<UUID, InspectionForm>();

		if ((!Environment.PRODUCTION || Environment.CLIENT_ID.startsWith('adn') || Environment.CLIENT_ID.startsWith('tratave') || Environment.CLIENT_ID.startsWith('simdouro') || Environment.CLIENT_ID.startsWith('lusagua')) && workflow) {
			additionalData = {attributesEmpty: false};

			// Calculate the average of the inspection data for the ADN project
			const cancel = await InspectionAverageExportADN.calcStepAverage(additionalData, inspection, workflow, forms);
			if (cancel) {
				return null;
			}
		}

		Loading.show();

		try {
			const doc: ArrayBuffer = await InspectionReport.generateDocx(inspection, template, additionalData, new Map(), new Map(), forms);
			if (writeToFile) {
				FileUtils.writeFileArrayBuffer(inspection.uuid + '.docx', doc);
			}

			return doc;
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorGeneratingReportDetails', {details: e}));
		} finally {
			Loading.hide();
		}

		return null;
	}

	/**
	 * Generate a PDF report for the inspection.
	 * 
	 * Use file conversion server to convert from DOCX to PDF.
	 * 
	 * @param template - The inspection report template to be used on report export.
	 * @param inspection - The inspection object to export.
	 * @param workflow - The inspection workflow object to extract info for the report.
	 * @param forwardError - Either to forward the error to the method that called the export method to be handled there.
	 */
	public static async exportReportPDF(template: InspectionReportTemplate, inspection: Inspection, workflow?: InspectionWorkflow, forwardError: boolean = false): Promise<void> {
		const doc: ArrayBuffer = await this.exportReportDOCX(template, inspection, workflow, false);
		if (!doc) {
			Loading.hide();
			return;
		}

		try {
			const pdf = await FileConverterService.convertDocxToPdf(doc);
			FileUtils.writeFileArrayBuffer(inspection.uuid + '.pdf', pdf);
		} catch (e) {
			if (forwardError) {
				throw e;
			}

			Modal.alert(Locale.get('error'), Locale.get('errorConvertingReport', {details: e}));
		}
	}

	/**
	 * Check inspection forms for required filled fields.
	 * 
	 * Fields check can be bypassed but will be marked as "dirty data" when inspection moved from step, and it is required an extra confirmation.
	 *
	 * @param destinationStepUuid - The step UUID that data of the inspection should transit to.
	 * @returns Object with 'missingMandatoryFields' flag indicating missing fields, and "dirtyData" flag that data check was bypassed.
	 */
	public checkRequiredFilled(destinationStepUuid: UUID = null): {missingMandatoryFields: boolean, dirtyData: boolean} {
		// Check inspection base form for required filled fields
		if (!this.form.requiredFilled()) {
			return {
				missingMandatoryFields: true,
				dirtyData: false
			};
		}

		// Identify the adjacency that triggered this update, if applicable.
		let adjacencyTrigger: InspectionWorkflowStepAdjacency = null;
		if (destinationStepUuid) {
			adjacencyTrigger = this.workflow.adjacencies.find((adj: InspectionWorkflowStepAdjacency) => { return adj.originUuid === this.inspection.stepUuid && adj.destinationUuid === destinationStepUuid; });
		}

		// Controls if inspection forms have empty data on required filled fields that can be bypassed
		let dataCheckBypassed: boolean = false;

		// Check inspection checklists forms for required filled fields
		for (const f of this.inspectionForm.toArray()) {
			const form: UnoFormComponent = f;

			// Check form for required fields
			const requiredFilled: boolean = form.requiredFilled();

			if (requiredFilled) {
				continue;
			}

			// Find the step which this form is related to, to check if update can be bypassed when required fields are missing
			let formStep: InspectionWorkflowStep = null;

			for (const [stepUuid, stepLayout] of this.stepFormLayout) {
				if (ObjectUtils.equal(stepLayout, form.layout)) {
					formStep = this.steps.get(stepUuid);
				}
			}

			if (formStep.uuid) {
				// Find inspection data regarding the step of this form
				const inspectionStepData: InspectionData = this.inspection.data.find((d: InspectionData) => { return d.stepUuid === formStep.uuid; });

				// Do not check forms (that are not of the current step) whose data is marked as "dirty" (because it was bypassed missing required fields) on adjacency transition
				if (formStep.uuid !== this.inspection.stepUuid && inspectionStepData && inspectionStepData.missingRequiredFields) {
					continue;
				}

				// Only allow to skip required validation of data of the origin step
				if (adjacencyTrigger && formStep.uuid === adjacencyTrigger.originUuid) {
					if (adjacencyTrigger.bypassRequiredCheck) {
						dataCheckBypassed = true;

						// Set data as dirty because it may be saved with bypassed missing required fields data
						if (inspectionStepData) {
							inspectionStepData.missingRequiredFields = true;
						}

						continue;
					} else {
						return {missingMandatoryFields: true, dirtyData: dataCheckBypassed};
					}
				}

				// Only allow to bypass the check of inspection data when the inspection is in the step that allows bypass
				if (formStep.uuid === this.inspection.stepUuid && formStep.bypassRequiredCheck) {
					dataCheckBypassed = true;
				} else {
					return {missingMandatoryFields: true, dirtyData: dataCheckBypassed};
				}
			}
		}

		return {missingMandatoryFields: false, dirtyData: dataCheckBypassed};
	}

	/**
	 * Update the inspection alongside with its inspection fields and reload inspection object and data.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 * @param destinationStepUuid - Update the step of the inspection.
	 * @param stepFlow - If the update is due to a step button being clicked 
	 */
	public async update(stayOnPage: boolean = false, destinationStepUuid: UUID = null, stepFlow: boolean = false): Promise<void> {
		const requiredFlags: {missingMandatoryFields: boolean, dirtyData: boolean} = this.checkRequiredFilled(destinationStepUuid);

		if (requiredFlags.missingMandatoryFields) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// Validate inspection project required attributes for the assets
		if (this.project?.requiredAssetFields?.length > 0 && this.assetRequiredFieldsForm && !this.assetRequiredFieldsForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('assetRequiredFieldsError'));
			return;
		}

		// If there's missing data on forms that allow bypass, user must reaffirm its intention to save inspection data
		if (requiredFlags.dirtyData && !await Modal.confirm(Locale.get('confirm'), Locale.get('confirmRequiredCheckBypass'))) {
			return;
		}

		// Clean inspection data
		this.inspection.updateData(this.steps, this.forms);

		// Clone inspection data and perform update changes on cloned object
		const inspection = structuredClone(this.inspection);

		if (destinationStepUuid) {
			inspection.stepUuid = destinationStepUuid;

			// Missing required fields flag on inspection data must be reset when the inspection reenters its step
			const destinationStepInspectionData: InspectionData = inspection.data.find((d: InspectionData) => {
				return d.stepUuid === destinationStepUuid;
			});

			if (destinationStepInspectionData) {
				destinationStepInspectionData.missingRequiredFields = false;
			}
		}

		if (this.assetEdited && Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
			if (!UnoFormComponent.requiredFilled(this.assetBaseLayout, this.asset) || !UnoFormComponent.requiredFilled(this.modelLayout, this.asset)) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			} else {
				await Service.fetch(ServiceList.assetPortfolio.asset.update, null, null, this.asset, Session.session);
			}
		}

		if (this.createMode) {
			await Service.fetch(ServiceList.inspection.create, null, null, inspection, Session.session);
			this.createMode = false;
		} else {
			await Service.fetch(ServiceList.inspection.update, null, null, inspection, Session.session);
		}

		if (this.projectEdited && Session.hasPermissions([UserPermissions.INSPECTION_PROJECT_EDIT])) {
			await Service.fetch(ServiceList.inspection.project.update, null, null, this.project, Session.session);
		}

		if (!stayOnPage || stepFlow && this.project.flowMode === InspectionProjectFlowMode.MODAL && !await Modal.confirm(Locale.get('confirm'), Locale.get('navigateNextStep'), ['yes', 'no'], false)) {
			Modal.toast(Locale.get('updatedSuccessfully'));
			App.navigator.pop();
			return;
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
		// Reload inspection to update the UUIDs of inspection data objects only if there is data without UUID
		const newDataEntries: InspectionData[] = this.inspection.data.filter((d: InspectionData) => { return d.uuid === null; });
		if (newDataEntries.length > 0) {
			this.inspection = await InspectionService.get(this.inspection.uuid);
		}

		this.loadData();
	}

	/**
	 * Delete the inspection workflow from the database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.inspection.delete, null, null, {uuid: this.inspection.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
