<div style="height: 110px; margin: 10px;">
	<div style="display:flex; align-items: center; gap: 5px;">
		<!-- Create button -->
		@if ([userPermissions.INSPECTION_FORM_CREATE] | hasPermissions) {
			<uno-button icon="assets/icons/uno/add.svg" (click)="app.navigator.navigate('/menu/workflow/form/edit', {createMode: true})">
				@if (app.device.isDesktop()) {
					{{'create' | translate}}
				}
			</uno-button>
		}
		<!-- Search bar -->
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="this.onSearch($event)"></uno-searchbar>
	</div>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [selectable]="false" [labelShown]="true" (rowClick)="this.navigate($event.element.uuid)" (sortChange)="this.sortChanged($event.sortBy)">
	</uno-responsive-table-list>
</uno-content>
