import {Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';

/**
 * The Lateral Selector Component, allows looping through a list of options.
 */
@Component({
	selector: 'uno-lateral-selector-switch',
	templateUrl: './uno-lateral-selector-switch.component.html',
	styleUrls: ['./uno-lateral-selector-switch.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoLateralSelectorSwitchComponent; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, TranslateModule]
})
export class UnoLateralSelectorSwitchComponent implements ControlValueAccessor {

	/**
	 * The options to be displayed on the component's dropdown.
	 */
	@Input('options')
	public options: { value: number, label: string }[];

	/**
	 * The currently active option.
	 */
	@Input('active')
	public active: number = 0;

	/**
	 * The width of the selector, default is 150
	 */
	@Input('width')
	public width: string = '90px';

	/**
	 * Emit when a value is changed
 	 */
	@Output()
	public valueChange = new EventEmitter<any>();

	/**
	 * Method to change the currently active option, with positive numbers moving forward and negative moving backwards
	 * 
	 * @param number - the number of times to move, can be negative or positive
	 */
	public changeValue(number: number): void {
		if (this.active === 0 && number < 0) {
			this.active = this.options.length + 1 + number;
		} 
		this.active = (this.active + number) % this.options.length;

		this.onChange(this.active);

		this.valueChange.emit(this.active);
	}

	/**
	 * Get label from the value selected.
	 * 
	 * @param value - The value to be used to search for the right label
	 */
	public getLabel(value: any): string {
		const opt = this.options.filter((val: any) => {
			return val.value === value;
		});
	
		return opt.length === 0 ? '' : opt[0].label;
	}

	public onChange: (value: any)=> void = function() {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void {}

	public writeValue(value: any): void {
		this.active = value;
	}

}
