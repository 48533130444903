import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {AtexInspectionField} from 'src/app/models/atex-inspections/inspections/atex-inspection-field';
import {AtexInspectionFieldResult} from 'src/app/models/atex-inspections/inspections/atex-inspection-field-result';
import {AtexInspectionForm} from 'src/app/models/atex-inspections/inspections/atex-inspection-form';
import {AtexInspectionStatus} from 'src/app/models/atex-inspections/inspections/atex-inspection-status';
import {AtexInspection} from 'src/app/models/atex-inspections/inspections/atex-inspection';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {AtexInspectionChecklist} from './master-data/atex-inspection-checklist';

/**
 * Utility class for Atex Inspection.
 * 
 * This class contains utility methods for Atex Inspection.
 */
export class AtexInspectionUtils {
	/**
	 * Build inspection form and store on the inspection data.
	 * 
	 * Check if the fields are appliable based on the asset type defined.
	 *
	 * @param inspectionForm - Inspection form to be built should use value from InspectionForm.
	 * @param asset - Asset attached to the inspection.
	 * @returns Dynamic form fields generated.
	 */
	public static async buildForm(inspection: AtexInspection, inspectionForm: AtexInspectionForm, asset: APAsset): Promise<UnoFormField[]> {
		if (asset.uuid !== inspection.assetUuid) {
			throw new Error('Asset UUID and asset data UUID do not match.');
		}

		let form: UnoFormField[] = null;
		if (inspectionForm === AtexInspectionForm.BACKOFFICE) {
			form = await AtexInspectionChecklist.buildForm(inspection, false, true);
		} else if (inspectionForm === AtexInspectionForm.INSPECTOR) {
			form = await AtexInspectionChecklist.buildForm(inspection, true, false);
		}

		if (!form) {
			throw new Error('InspectorForm must be either "backoffice" or "inspector".');
		}

		const responses = inspection.data.responses[inspectionForm];
		const subTypeUuid = asset.subTypeUuid;
		
		// Iterate all inspection fields in the form
		for (let i = 0; i < form.length; i++) {
			if (form[i].type === UnoFormFieldTypes.ATEX_INSPECTION_FIELD) {
				const attr = form[i].attribute;

				// Create inspection field if missing
				if (!responses[attr]) {
					responses[attr] = new AtexInspectionField();

					// If the form is INSPECTOR Only change if inspection status is TO DO or SUPERVISION
					if (inspectionForm === AtexInspectionForm.INSPECTOR && (inspection.status === AtexInspectionStatus.TODO || inspection.status === AtexInspectionStatus.TODO_SUPERVISION) ||
						// If the form is BACKOFFICE Only change if inspection status is REVIEW
						inspectionForm === AtexInspectionForm.BACKOFFICE && inspection.status === AtexInspectionStatus.REVIEW) {
						// If result is not NONE set the notApplicable attribute value
						if (responses[attr].result === AtexInspectionFieldResult.NONE) {
							if (form[i].onlyApplicable && form[i].onlyApplicable.length > 0) {
								responses[attr].notApplicable = form[i].onlyApplicable.indexOf(subTypeUuid) === -1;
							}
						}
					}
				}
			}
		}

		return form;
	}
}
