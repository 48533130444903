/**
 * Possible form field types used in the forms.
 */
export const InspectionFormFieldType = {
	NONE: 0,
	CHECKBOX: 1,
	TEXT: 2,
	DOCUMENTS: 3,
	IMAGES: 4,
	GEO_POSITION: 6,
	DATE: 7,
	COMPOSED_FIELD: 8,
	TEAM: 9,
	COMPANY: 10,
	MULTIPLE_FORMS: 12,
	TITLE: 14,
	USER: 15,
	SUB_FORM: 16,
	OPTIONS: 17,
	OPTIONS_MULTIPLE: 18,
	ASSET: 19,
	ASSET_MULTIPLE: 20,
	AUDIO: 21,
	VIDEO: 22
};

/**
 * Map of the form field types and their corresponding label as stored in locale.
 */
export const InspectionFormFieldTypeLabel: Map<number, string> = new Map([
	[InspectionFormFieldType.NONE, 'none'],
	[InspectionFormFieldType.CHECKBOX, 'checkbox'],
	[InspectionFormFieldType.TEXT, 'text'],
	[InspectionFormFieldType.DOCUMENTS, 'documents'],
	[InspectionFormFieldType.IMAGES, 'images'],
	[InspectionFormFieldType.GEO_POSITION, 'position'],
	[InspectionFormFieldType.DATE, 'date'],
	[InspectionFormFieldType.COMPOSED_FIELD, 'composedField'],
	[InspectionFormFieldType.TEAM, 'team'],
	[InspectionFormFieldType.COMPANY, 'company'],
	[InspectionFormFieldType.SUB_FORM, 'subForm'],
	[InspectionFormFieldType.MULTIPLE_FORMS, 'multipleForm'],
	[InspectionFormFieldType.TITLE, 'title'],
	[InspectionFormFieldType.USER, 'user'],
	[InspectionFormFieldType.OPTIONS, 'options'],
	[InspectionFormFieldType.OPTIONS_MULTIPLE, 'multipleOptions'],
	[InspectionFormFieldType.ASSET, 'asset'],
	[InspectionFormFieldType.ASSET_MULTIPLE, 'assets'],
	[InspectionFormFieldType.AUDIO, 'audio'],
	[InspectionFormFieldType.VIDEO, 'video']
]);
