import {App} from 'src/app/app';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {UUID, generateUUID} from '../../../../models/uuid';
import {Session} from '../../../../session';
import {InspectionForm} from '../../../../models/inspections/form/inspection-form';
import {FileReaderAsync} from '../../../../utils/file-reader-async';
import {InspectionWorkflow} from '../../../../models/inspections/workflow/inspection-workflow';

export class InspectionWorkflowImport {
	/**
	 * Import a workflow from a JSON file. Uses the same format as provided by the export tool.
	 * 
	 * This tool won't update any of the provided objects such as workflow, forms, steps or adjacencies. All the imported content will generate new objects on server either preserving imported UUIDs or generating new ones.
	 */
	public static async importJSON(): Promise<void> {
		const obj: {preserveUuids: boolean, jsonFile: File} = {
			// If set, all the UUIDs on the imported file will be preserved
			preserveUuids: true,
			// The uploaded file with data to be imported
			jsonFile: null
		};

		// Form layout to present on Modal
		const layout: UnoFormField[] = [
			{
				attribute: 'preserveUuids',
				label: 'preserveUuids',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				required: true,
				attribute: 'jsonFile',
				label: 'selectFile',
				type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
				local: true,
				multiple: false,
				filter: '.json',
				isEmpty: function(object) {
					return !object.jsonFile;
				}
			}
		];

		try {
			await Modal.form(Locale.get('import'), obj, layout);
		} catch {
			return;
		}
		
		try {
			const text = await FileReaderAsync.readAsText(obj.jsonFile);
			const data = JSON.parse(text);

			// Extract workflow base info, steps and adjacencies from imported file data
			const workflow: InspectionWorkflow = InspectionWorkflow.parse(data);
			
			if (!obj.preserveUuids) {
				// Reset workflow UUID
				workflow.uuid = generateUUID();
			}

			// Extract forms from imported file data
			let forms: InspectionForm[] = [];
			if (data.forms) {
				forms = data.forms.map((f: any) => { return InspectionForm.parse(f); });
				
				// Replace every form UUID and update its dependencies
				if (!obj.preserveUuids) {
					for (let i = 0; i < forms.length; i++) {
						const formUuid: UUID = generateUUID();
						
						// Replace form dependencies on all its fields
						for (let j = 0; j < forms[i].fields.length; j++) {
							forms[i].fields[j].formUuid = formUuid;

							if (!obj.preserveUuids) {
								// Reset field UUID
								forms[i].fields[j].uuid = generateUUID();
							}
						}

						// Replace form dependencies on other forms fields
						for (let j = 0; j < forms.length; j++) {
							for (let k = 0; k < forms[j].fields.length; k++) {
								if (forms[j].fields[k]?.subFormUuid === forms[i].uuid) {
									forms[j].fields[k].subFormUuid = formUuid;
								}								
							}
						}

						// Replace form dependencies on steps
						for (let j = 0; j < workflow.steps.length; j++) {
							if (workflow.steps[j]?.formUuid === forms[i].uuid) {
								workflow.steps[j].formUuid = formUuid;
							}
						}
						
						// Replace form UUID
						forms[i].uuid = formUuid;
					}
				}
			}
			
			// Get a form and all its subforms by its UUID from the list of imported forms
			const getFormAndSubforms = function(formUuid: UUID): InspectionForm[] {
				let foundForms: InspectionForm[] = [];

				let form: InspectionForm;

				// Search of forms list by the requested form
				for (let i = 0; i < forms.length; i++) {
					if (forms[i].uuid === formUuid) {
						form = forms[i];

						if (forms[i].fields?.length > 0) {
							for (let j = 0; j < forms[i].fields.length; j++) {
								if (forms[i].fields[j].subFormUuid?.length > 0) {
									// Add subforms to the beginning of the array of forms to be created before the ones that reference them
									foundForms = getFormAndSubforms(forms[i].fields[j].subFormUuid).concat(foundForms);
								}
							}
						}
					}
				}
				
				if (!form) {
					// The referenced form was not imported to be created along with the other objects.
					throw Error(Locale.get('errorMissingFormOnImport', {formUuid: formUuid}));
				}

				// Push found form to the list
				foundForms.push(form);

				return foundForms;
			};

			let formsToCreate: InspectionForm[] = [];
			
			// Handle workflow steps
			for (let i = 0; i < workflow.steps.length; i++) {
				if (!obj.preserveUuids) {
					const stepUuid: UUID = generateUUID();

					// Update adjacencies references to the current step to the new step UUID
					for (let j = 0; j < workflow.adjacencies.length; j++) {
						if (workflow.adjacencies[j].originUuid === workflow.steps[i].uuid) {
							workflow.adjacencies[j].originUuid = stepUuid;
						} else if (workflow.adjacencies[j].destinationUuid === workflow.steps[i].uuid) {
							workflow.adjacencies[j].destinationUuid	= stepUuid;
						}
					}
	
					// Reset step UUID to create a new step entry
					workflow.steps[i].uuid = stepUuid;
	
					// Update workflow UUID to the created one
					workflow.steps[i].workflowUuid = workflow.uuid;
				}

				// Add step form and all its subforms to the creation queue if it has a form
				if (workflow.steps[i].formUuid?.length > 0) {
					// Search for the imported form and all its subforms
					const stepForms: InspectionForm[] = getFormAndSubforms(workflow.steps[i].formUuid);

					// Prevent forms creation repetition by searching them on already queued forms to create
					let index: number = stepForms.length;

					while (index--) {
						for (let k = 0; k < formsToCreate.length; k++) {
							if (stepForms[index].uuid && stepForms[index].uuid === formsToCreate[k].uuid ) {
								stepForms.splice(index, 1);
								break;
							}
						}
					}

					formsToCreate = formsToCreate.concat(stepForms);
				}
			}

			// Handle workflow adjacencies
			if (!obj.preserveUuids) {
				for (let i = 0; i < workflow.adjacencies.length; i++) {
					// Reset adjacencies UUID to create a new adjacency entry
					workflow.adjacencies[i].uuid = generateUUID();
					
					// Update workflow's adjacency reference to the new workflow UUID
					workflow.adjacencies[i].workflowUuid = workflow.uuid;
				}
			}
			
			// Remove duplicated forms
			formsToCreate = formsToCreate.filter((item, index) => { return formsToCreate.indexOf(item) === index; });
			
			// Create the forms
			for (let i = 0; i < formsToCreate.length; i++) {
				// Create form on server
				const req = await Service.fetch(ServiceList.inspection.form.create, null, null, formsToCreate[i], Session.session);

				if (!obj.preserveUuids) {
					const formUuid: UUID = req.response.uuid;

					// Update form UUID on steps that reference it
					for (let j = 0; j < workflow.steps.length; j++) {
						if (workflow.steps[j].formUuid && workflow.steps[j].formUuid === formsToCreate[i].uuid) {
							workflow.steps[j].formUuid = formUuid;
						}
					}

					// Update subFormUuid on all imported form fields that reference it
					for (let j = 0; j < formsToCreate.length; j++) {
						for (let k = 0; k < formsToCreate[j].fields?.length; k++) {
							if (formsToCreate[j].fields[k].subFormUuid && formsToCreate[j].fields[k].subFormUuid === formsToCreate[i].uuid) {
								formsToCreate[j].fields[k].subFormUuid = formUuid;
							}
						}
					}
				}
			}

			// Create the workflow
			const request = await Service.fetch(ServiceList.inspection.workflow.create, null, null, workflow, Session.session);

			Modal.toast(Locale.get('importSuccessful'), 3000, 'success');

			await App.navigator.navigate('/menu/workflow/flow/edit', {uuid: request.response.uuid});
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorImportDetails', {details: e}));
		}
	}
}
