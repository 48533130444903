import {
	Component,
	ElementRef,
	forwardRef,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {NgClass, NgStyle} from '@angular/common';
import AirDatepicker, {AirDatepickerLocale} from 'air-datepicker';
import en from 'air-datepicker/locale/en';
import es from 'air-datepicker/locale/es';
import pt from 'air-datepicker/locale/pt';

import {DateRange} from 'src/app/models/date-range';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';

/**
 * Type of date time mode can be 'date-time', 'date' or 'time' only.
 *
 * 'date-time' shows both date and time (day, month, year, hours, ...) selectors.
 *
 * 'time' displays only time (hours, minutes, ...) selectors.
 */
export type DateTimeMode = 'date-time' | 'date' | 'time' | 'month-year';

@Component({
	selector: 'uno-date-time',
	templateUrl: './uno-date-time.component.html',
	styleUrls: ['uno-date-time.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoDateTimeComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [NgStyle, NgClass, IonicModule, TranslateModule, UnoIconComponent]
})
export class UnoDateTimeComponent implements OnChanges, ControlValueAccessor {
	@ViewChild('datepicker', {static: true})
	public datepickerInput: ElementRef = null;

	public app = App;

	public locale = Locale;

	/**
	 * Maximum date to be used by default.
	 */
	public static maxDefault: Date = new Date(2100, 0, 0);

	/**
	 * Minimum date to be used by default.
	 */
	public static minDefault: Date = new Date(1900, 0, 0);

	/**
	 * Border to be displayed around the input.
	 */
	@Input()
	public border: boolean = true;

	/**
	 * Time selection mode.
	 */
	@Input()
	public mode: DateTimeMode = 'date-time';

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Placeholder text to be displayed when there is no date selected.
	 */
	@Input()
	public placeholder: string = '';

	/**
	 * The maximum date time the user can choose from.
	 */
	@Input()
	public max: Date = null;

	/**
	 * The minimum date time the user can choose from.
	 */
	@Input()
	public min: Date = null;

	/**
	 * If the datepicker is of range type 
	 */
	@Input()
	public isRange: boolean = false;

	/**
	 * Possible dates that the parent already has selected
	 */
	@Input()
	public parentSelectedDates: Date[] = null;
	
	/**
	 * Value currently selected.
	 */
	public value: Date | DateRange = null;

	/**
	 * Backdrop for the component when the popover is opened.
	 */
	public backdrop: HTMLElement = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: Date | DateRange)=> void = function() {};

	/**
	 * Datepicker element
	 */
	public datePicker: AirDatepicker = null;

	/**
	 * If the element is focused
	 */
	public focused: boolean = false;

	/**
	 * List of possible locale translations for the datepicker
	 */
	public datepickerAvailableLocales: {en: AirDatepickerLocale, es: AirDatepickerLocale, pt: AirDatepickerLocale} = {en: en, es: es, pt: pt};

	public ngOnInit(): void {	
		if (!this.disabled) {
			const datepickerLocale = this.datepickerAvailableLocales[this.locale.code];
			datepickerLocale.daysMin = datepickerLocale.daysMin.map((day) => {
				return day[0];
			});
			
			let selectedDates: Date[];
			if (this.parentSelectedDates[0]) {
				selectedDates = this.parentSelectedDates;
			}
	
			const options = {
				position: function({$datepicker, $target, $pointer}) {
					const coords = $target.getBoundingClientRect();
				
					const top = coords.y + coords.height + window.scrollY + 10;
					const left = coords.x - 14;
				
					$datepicker.style.left = `${left}px`;
					$datepicker.style.top = `${top}px`;
				
					$pointer.style.display = 'none';
	
					$datepicker.style.zIndex = 1000000;
	
				},
				range: this.isRange,
				multipleDatesSeparator: ' - ',
				locale: datepickerLocale,
				dateFormat: 'dd/MM/yyyy',
				navTitles: {days: 'MMMM yyyy'},
				selectedDates: selectedDates,
				minDate: this.minDate,
				maxDate: this.maxDate,
				isMobile: this.app.device.isMobile(),
				onShow: () => {
					this.focused = true;
				},
				onHide: () => {
					this.focused = false;
				},
				onSelect: ({date}) => {
					this.updateValue(date);
				}
			};
	
			this.datePicker = new AirDatepicker(this.datepickerInput.nativeElement, options);
		}
	}
	
	public ngOnChanges(changes: SimpleChanges): void {
		if (typeof this.value === 'string') {
			this.value = new Date(this.value);
		}

		if (typeof this.max === 'string') {
			this.max = new Date(this.max);
		}

		if (typeof this.min === 'string') {
			this.min = new Date(this.min);
		}
	}

	/**
	 * Get max date range in format YYYY-MM-DD
	 */
	public get maxDate(): string {
		if (!this.max) {
			return UnoDateTimeComponent.maxDefault.toISOString().substr(0, 10);
		}

		return this.max.toISOString().substr(0, 10);
	}

	/**
	 * Get min date range in format YYYY-MM-DD
	 */
	public get minDate(): string {
		if (!this.min) {
			return UnoDateTimeComponent.minDefault.toISOString().substr(0, 10);
		}

		return this.min.toISOString().substr(0, 10);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public updateValue(value: string | Date): void {
		if (this.isRange && value) {
			this.value = new DateRange(value[0], value[1]);
		} else {
			if (typeof value === 'string') {
				value = new Date(value);
			}
	
			this.value = value as Date;
		}

		this.onChange(this.value);
	}
	
	public writeValue(value: string | Date | Date[]): void {
		if (this.isRange && value) {
			this.value = new DateRange(value[0], value[1]);
		} else {
			if (typeof value === 'string') {
				value = new Date(value);
			}
	
			this.value = value as Date;
		}
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

}
