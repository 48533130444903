import {Component, OnInit, Signal, ViewEncapsulation, WritableSignal, computed, signal} from '@angular/core';
import {LocaleList} from 'src/app/locale/locale';
import {Session} from 'src/app/session';
import {UnoOptionIconData, UnoOptionsIcon} from '../uno-options-icon/uno-options-icon.component';

@Component({
	selector: 'uno-language',
	templateUrl: './uno-language.component.html',
	styleUrls: ['./uno-language.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoOptionsIcon]
})
export class UnoLanguage implements OnInit {
	/**
	 * Array containing possible language options
	 */
	public languageOptions: Signal<UnoOptionIconData[]> = computed(() => {
		return LocaleList.map((locale) => {
			return {value: locale.code, icon: `/assets/flags/${locale.code}.svg`, label: locale.code.toLocaleUpperCase()};
		});
	});

	/**
	 * Currently Selected language option  
	 */
	public selectedLanguage: WritableSignal<UnoOptionIconData> = signal({value: '', icon: '', label: ''});

	/** 
	 * On init, get selected language
	 */
	public ngOnInit(): void {
		const locale = Session?.settings?.locale || 'en';

		if (locale === 'en') {
			this.selectedLanguage.set({value: locale, icon: '/assets/flags/gb.svg', label: locale.toLocaleUpperCase()});
		} else {
			this.selectedLanguage.set({value: locale, icon: `/assets/flags/${locale}.svg`, label: locale.toLocaleUpperCase()});
		}
	}
	
	/**
	 * Update the language in the settings
	 * 
	 * @param option - the selected language option
	 */
	public updateLanguage(option?: UnoOptionIconData): void {
		const settings = structuredClone(Session.settings);
		settings.locale = option.value;
		Session.updateSettings(settings);
	}
}
