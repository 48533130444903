import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {RepairInspectionResult, RepairInspectionResultLabel} from '../../../../models/repairs/inspections/repair-inspection-result';
import {RepairInspectionStatus} from '../../../../models/repairs/inspections/repair-inspection-status';
import {RepairService} from '../../repair-work/services/repair.service';

export class RepairInspectionFormLayout {
	/**
	 * New repair report form layout.
	 */
	public static base: UnoFormField[] = [
		{
			label: 'uuid',
			attribute: 'uuid',
			type: UnoFormFieldTypes.UUID
		},
		{
			label: 'inspectionNumber',
			attribute: 'inspectionNumber',
			type: UnoFormFieldTypes.TEXT,
			editable: true,
			isActive: true
		},
		{
			label: 'executionDate',
			attribute: 'date',
			type: UnoFormFieldTypes.DATE,
			editable: true,
			isActive: true
		},
		{
			required: true,
			label: 'repair',
			attribute: 'repairUuid',
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
			editable: false,
			options: [{value: null, label: 'none'}],
			multiple: false,
			identifierAttribute: 'uuid',
			fetchOptionsLazy: async function(request): Promise<{options: any[], hasMore: boolean, id: number}> {
				const data = {
					search: request.search,
					searchFields: ['[ap_asset].[name]', '[ap_asset].[tag]', '[repair].[id]', '[repair].[repair_order]', '[repair].[description]'],
					from: request.from,
					count: request.count
				};

				const req = await RepairService.list(data);

				return {options: req.repairs, hasMore: req.hasMore, id: req.id};
			},
			fetchOptionsBatch: async function(request): Promise<{options: any[]}> {
				if (request.options.length > 0) {
					const repairReq = await RepairService.list({});

					const repair = repairReq.repairs.find((rep) => {
						return request.options[0] === rep.uuid;
					});

					return {options: [repair]};
				}

				return {options: []};
			},
			getOptionText: function(option) {
				return [option.description, option.asset?.name, option.asset?.tag].filter(Boolean).join(' - ');
			}
		},
		{
			required: true,
			attribute: 'teamUuid',
			label: 'team',
			type: UnoFormFieldTypes.TEAM_SELECTOR,
			editable: true
		},
		{
			required: true,
			attribute: 'dueDate',
			label: 'dueDate',
			type: UnoFormFieldTypes.DATE,
			editable: (object, row) => {return object.status === RepairInspectionStatus.TODO;},
			isActive: true
		}
	];

	public static result: UnoFormField[] = [
		{
			required: true,
			attribute: 'result',
			label: 'result',
			type: UnoFormFieldTypes.OPTIONS,
			sort: false,
			editable: (object, row) => {return object.status >= RepairInspectionStatus.TODO;},
			isEmpty: function(object) {
				return object.result === RepairInspectionResult.NONE;
			},
			options: Object.values(RepairInspectionResult).map(function(value) {
				return {value: value, label: RepairInspectionResultLabel.get(value)};
			})
		},
		{
			required: true,
			attribute: 'pictures',
			label: 'photos',
			type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
			editable: (object, row) => {return object.status >= RepairInspectionStatus.TODO;},
			isActive: true			
		},
		{
			attribute: 'documents',
			label: 'documents',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
			editable: (object, row) => {return object.status >= RepairInspectionStatus.TODO;},
			isActive: true
		},
		{
			attribute: 'notes',
			label: 'notes',
			type: UnoFormFieldTypes.TEXT_MULTILINE,
			editable: (object, row) => {return object.status >= RepairInspectionStatus.TODO;},
			isActive: true
		}
	];

	/**
	 * Repair inspection rejected message form layout.
	 *
	 * Shown when the repair is rejected.
	 */
	public static rejected: UnoFormField[] = [
		{
			label: 'message',
			attribute: 'rejectedMessage',
			type: UnoFormFieldTypes.TEXT_MULTILINE
		}
	];
}
