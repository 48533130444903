import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {PlantsService} from 'src/app/modules/pipeline-integrity/services/plant.service';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {Geolocation} from '../../../../../../models/geolocation';
import {Plant} from '../../../../../../models/pipeline-integrity/pipeline/plant';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {PipelineListResponse, PipelineService} from '../../../../services/pipeline.service';

@Component({
	selector: 'pipeline-list-page',
	templateUrl: 'pipeline-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		TranslateModule,
		UnoFilterBarComponent,
		UnoResponsiveTableListComponent
	]
})
export class PipelineListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public session: any = Session;

	public selfStatic: any = PipelineListPage;

	/**
	 * Current position in the map.
	 */
	public position: Geolocation = null;

	/**
	 * Plant related to this pipeline.
	 */
	public plant: Plant = null;

	/**
	 * UUID of the plant to be used on the request.
	 */
	public plantUuid: string = null;


	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;

	/**
	 * Possible database filter to be used for ordering the Pipeline list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'updated_at',
			options: [
				{label: 'updatedAt', value: 'updated_at'},
				{label: 'createdAt', value: 'created_at'},
				{label: 'name', value: 'name'},
				{label: 'tag', value: 'tag'},
				{label: 'description', value: 'description'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['name', 'description'],
			multiple: true,
			options: [
				{label: 'uuid', value: 'uuid'},
				{label: 'name', value: 'name'},
				{label: 'description', value: 'description'},
				{label: 'tag', value: 'tag'}
			]
		}
	];
	
	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data?.plantUuid) {
			App.navigator.pop();
			return;
		}

		this.plantUuid = data.plantUuid;
		this.plant = await PlantsService.get(data.plantUuid);
		App.navigator.setTitle(this.plant.name);

		await this.countTableItems();
	}

	/**
	 * Method used to load data from the API.
	 * 
	 * @param from - The starting index to load from.
	 * @param count - The number of items to load per page.
	 * @returns Elements to be displayed on the table.
	 */
	public loadMore = async(from: number, count: number): Promise<any> => {
		const list: PipelineListResponse = await PipelineService.list({
			plantUuid: this.plantUuid,
			filters: {
				sortField: PipelineListPage.filters.sortField,
				sortDirection: PipelineListPage.filters.sortDirection,
				search: PipelineListPage.filters.search,
				searchFields: PipelineListPage.filters.searchFields,
				offset: from,
				count: count
			}
		});
	
		return {
			elements: list.pipelines,
			hasMore: list.hasMore
		};
	};

	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: 'name', tag: ListDisplayStyle.TITLE},
		{header: 'tag', type: UnoTableColumnType.TEXT, attribute: 'tag', visible: true, size: 'small', sortBy: 'tag', tag: ListDisplayStyle.TEXT},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small', sortBy: 'description', tag: ListDisplayStyle.LABEL},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons: [
				{
					src: './assets/icons/assets/radio-outline.svg',
					click: (row): void => {
						App.navigator.navigate('/menu/pipeline-integrity/cmp/list', {pipelineUuid: row.uuid});
					}
				},
				{
					src: './assets/icons/assets/thermometer-outline.svg',
					click: (row): void => {
						App.navigator.navigate('/menu/pipeline-integrity/lds/list', {pipelineUuid: row.uuid});
					}
				}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		 sortDirection: '',

		 /**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter pipelines by their name, description or UUID.
		 */
		search: '',

		/**
		 * Search fields to be considered(name should match database column)
		 */
		 searchFields: []
	}, PipelineListPage.filterOptions);

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		PipelineListPage.filters = filters;

		this.table.sortDirection = PipelineListPage.filters.sortDirection;
		this.table.sortField = PipelineListPage.filters.sortField;

		if (this.table) {
			await this.countTableItems();
			await this.table.reset();
		}
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		PipelineListPage.filters.search = event;

		if (this.table) {
			await this.countTableItems();
			await this.table.reset();
		}
	}

	/**
	 * Change the page's sorting.
	 * 
	 * @param sortBy - The attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === PipelineListPage.filters.sortField) {
			PipelineListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			PipelineListPage.filters.sortField = sortBy;
			PipelineListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Counts the table items and updates the table component.
	 */
	public async countTableItems(): Promise<void> {
		const params = {
			plantUuid: this.plantUuid, 			
			filters: {
				search: PipelineListPage.filters.search,
				searchFields: PipelineListPage.filters.searchFields
			}
		};

		this.totalItems = await PipelineService.count(params);
	}
}
