import {Component, OnInit, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {UUID} from '../../../../../../models/uuid';
import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {CMP} from '../../../../../../models/pipeline-integrity/mot/cmp';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {CMPListResponse, CMPService} from '../../../../services/cmp.service';

@Component({
	selector: 'mot-cmp-list-page',
	templateUrl: 'mot-cmp-list.page.html',
	standalone: true,
	imports: [
		UnoButtonComponent,
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		TranslateModule,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent
	]
})

export class MOTCmpListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public app: any = App;

	public session: any = Session;

	public selfStatic: any = MOTCmpListPage;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY];

	/**
	 * List of CMPS to be displayed retrieved by the API.
	 */
	public cmps: CMP[] = [];

	/**
	 * UUID of the pipeline that belongs to the CMP.
	 */
	public pipelineUuid: UUID;

	/**
	 * The maximum number of items to show on table component.
	 */
	public totalItems: number = 1;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;
	
	/**
	 * The layout to use on the Uno Table component.
	 */
	public layout: UnoTableColumnLayout[] = [
		{header: 'name', type: UnoTableColumnType.TEXT, attribute: 'name', visible: true, size: 'small', sortBy: 'name', tag: ListDisplayStyle.TEXT},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'small', sortBy: 'description', tag: ListDisplayStyle.TEXT},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons: [
				{
					src: './assets/icons/assets/edit-icon.svg',
					click: (row): void => {
						App.navigator.navigate('/menu/pipeline-integrity/cmp/edit', {cmpUuid: row.uuid, pipelineUuid: this.pipelineUuid});
					}
				}]
		}
	];

	/**
	 * Possible database filter to be used for ordering the LDS list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'updated_at',
			options: [
				{label: 'updatedAt', value: 'updated_at'},
				{label: 'createdAt', value: 'created_at'},
				{label: 'name', value: 'name'},
				{label: 'description', value: 'description'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['name', 'description'],
			multiple: true,
			options: [
				{label: 'uuid', value: 'uuid'},
				{label: 'name', value: 'name'},
				{label: 'description', value: 'description'}
			]
		}
	];
		
	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		 sortDirection: '',

		 /**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter cmps by their name, description or UUID.
		 */
		search: '',

		/**
		 * Search fields to be considered(name should match database column)
		 */
		 searchFields: []
	}, MOTCmpListPage.filterOptions);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.cmps = [];

		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}
		
		App.navigator.setTitle('cmp');
		this.pipelineUuid = data.pipelineUuid;
		await this.countTableItems();
	}

	/**
	 * Load more data from the API.
	 * 
	 * @param from - The index of the first element to load.
	 * @param count - The number of elements to load.
	 * @returns Data to be displayed on the table.
	 */
	public loadMore = async(from: number, count: number): Promise<any> => {
		const list: CMPListResponse = await CMPService.list({
			pipelineUuid: this.pipelineUuid,
			filters: {
				sortField: MOTCmpListPage.filters.sortField,
				sortDirection: MOTCmpListPage.filters.sortDirection,
				search: MOTCmpListPage.filters.search,
				searchFields: MOTCmpListPage.filters.searchFields,
				offset: from,
				count: count
			}
		});

		return {
			elements: list.cmps,
			hasMore: list.hasMore
		};
	};


	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		MOTCmpListPage.filters = filters;
		
		this.table.sortDirection = MOTCmpListPage.filters.sortDirection;
		this.table.sortField = MOTCmpListPage.filters.sortField;

		this.totalItems = await CMPService.count({
			pipelineUuid: this.pipelineUuid,
			filters: {
				search: MOTCmpListPage.filters.search,
				searchFields: MOTCmpListPage.filters.searchFields
			}
		});
		if (this.table) {
			await this.table.reset();
		}
	}
	
	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		MOTCmpListPage.filters.search = event;
	
		if (this.table) {
			await this.countTableItems();
			await this.table.reset();
		}
	}

	/**
	 * Change the page's sorting.
	 * 
	 * @param sortBy - The attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === MOTCmpListPage.filters.sortField) {
			MOTCmpListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			MOTCmpListPage.filters.sortField = sortBy;
			MOTCmpListPage.filters.sortDirection = SortDirection.ASC;
		}
	
		if (this.table) {
			await this.table.reset();
		}
	}

	/**
	 * Counts the table items and updates the table component.
	 */
	public async countTableItems(): Promise<void> {
		const params = {
			pipelineUuid: this.pipelineUuid,
			filters: {
				search: MOTCmpListPage.filters.search,
				searchFields: MOTCmpListPage.filters.searchFields
			}
		};

		this.totalItems = await CMPService.count(params);
	}
}
